import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../../Panel/common/FileUploadComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import Divider from "@material-ui/core/Divider";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import * as constants from "../../../../Panel/common/Constants"
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CTextField from "../../../../Panel/common/CTextField";
import Editor from '../../../../Panel/common/Editor';
import {useTranslation} from "react-i18next";
import ListFiles from './files/ListFiles';
import NewFile from "./files/NewFile";
import UpdateFile from "./files/UpdateFile";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import {toast} from "react-toastify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageChangerForPanel from "../../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select : {
        width: '100%'
    }
}));

export default function LoadedSponsor(props)  {

    const {t, i18n} = useTranslation();
    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [sponsorLocale, setSponsorLocale] = useState([]);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [executives, setExecutives] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [showSponsorFiles, setShowSponsorFiles] = useState({state:false,id:''});
    const [sponsorFiles, setSponsorFiles] = useState([]);
    const [filename, setFilename] = useState('');
    const [formErrors, setFormErrors] = useState(null);
    const [editorDescription, setEditorDescription] = useState(null);

    const checkboxes_style = useedStyles();
    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon color="green" fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon   color="green" fontSize="small" className={checkboxes_style.root}/>;

    const notify = () => toast.success(t('Succesfully saved!'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const changeLocale = (lang) => {
        setEditorDescription(sponsorLocale.description);
        setLocaleLanguage(lang);
    }

    useEffect(() => {
        setLocaleData(sponsor);
    },[localeLanguage])

    const thid =  props;

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null && value != "draggable_executives" && value != "files" && value != "executives" && value != "socials"){
                console.log("a",value,data[value])
                localeData[value] = data[value][localeLanguage]?data[value][localeLanguage]:" ";
            }else{
                // setSponsorLocale([value]:data[value])
                console.log("b",value,data[value])
                localeData[value] = data[value]?data[value]:" ";
            }
        })
        console.log("localeData",localeData,localeLanguage,sponsor)
        setSponsorLocale(localeData);
        setEditorDescription(localeData.description);
    }

    useEffect(() => {
        const loadSponsor = async () => {
            setSponsorid(props.props);
            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + '/data/sponsor_full_data', {id: thid,type:'panel'}).then(response => {
                console.log('response full data', response);
                // After fetching data stored it in posts state.
                setSponsor(response.data);
                setLocaleData(response.data)
                setSponsorFiles(response.data.files);

                // Closed the loading page
                setLoading(false);
            });


            await axios.post(constants.LocalUrl + '/data/get_sponsor_categories', {id: thid}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setSponsorcategories(response.data);

            });

            await axios.post(constants.LocalUrl + '/data/get_all_executives', {id: thid}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setExecutives(response.data);

            });

        }

        // Call the function
        loadSponsor();
    }, []);


    const holdFilename = (filename) => {
        // console.log("filename")
        // console.log(filename)
        setFilename(filename);
    };

    const showSponsorFilesMenu = (state,id) => () => {
        // console.log(sponsorFiles,state,id)
        setShowSponsorFiles({state:state,id:id});
    };

    const addFileHandler = (file) => {
        // console.log("addFileHandler")
        // console.log(file)
        setSponsorFiles((sponsorFiles) => [...sponsorFiles,file])
        // console.log("sponsorFiles")
        // console.log(sponsorFiles)
        // setPosts((posts) => [data, ...posts]);
    };

    const updatePostsHandler = (file) => {
        console.log(file);
    };

    const showSponsorFilesMenuFunc = (state,id) => {
        // setShowSponsorFiles({state:state,id:id});
        // console.log("asdasd");
        setShowSponsorFiles({...showSponsorFiles, state: state,id:id});

    };

    const onSubmitForm = () => {
        console.log("onSubmitForm",sponsorLocale)

        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/update_sponsor',{...sponsorLocale, lang: localeLanguage}).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }else{

                notify();
                let index_id;
                const i = props.posts.map((single_post,index) =>  {
                        if(single_post.id === response.data.data.id){
                            index_id = index;
                            return true;
                        }
                    }
                );

                let items = [...props.posts];
                let item = {...items[index_id]};
                item.title = response.data.data.title;
                items[index_id] = item;

                props.triggerdataload(items);
                // props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    const handleChange = (event) => {
        if(event.target.name === "category_id"){
            setSponsorcategory(event.target.value);
        }
        event.persist();

        setSponsor((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        setSponsorLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const onInputchangeSocials = (event) => {
        event.persist();


        console.log('nope')
        setSponsor((prevState) => ({
            ...prevState,
            socials:{
                ...prevState['socials'],
                [event.target.name]:event.target.value,
            }
        }));

        setSponsorLocale((prevState) => ({
            ...prevState,
            socials:{
                ...prevState['socials'],
                [event.target.name]:event.target.value,
            }
        }));

        console.log(sponsor)
    };

    const onInputchange = (event) => {
        event.persist();

        if(typeof sponsor[event.target.name] === 'object'){
            console.log('object')

            setSponsor((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));
        }else{
            console.log('nope')
            setSponsor((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }));
        }
        setSponsorLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        console.log(sponsor)
    };

    const onExecutivesChange = (event, values) => {
        event.persist();
        setSponsor(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["addExecutives"]: values      // update the value of specific key

        }))

        setSponsorLocale(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["addExecutives"]: values      // update the value of specific key

        }))
    };

    const handleSettingsFunc = (data) => {

        setSponsorLocale((prevState) => ({
            ...prevState,
            description: data,
        }));

        // event.persist();
        setSponsor((prevState) => ({
            ...prevState,
            ['description']:{
                ...prevState['description'],
                [localeLanguage]:data,
            }

        }));
        // setLocaleData(sponsor)
        console.log("handleSettingsFunc",sponsorLocale,sponsor,data)

    }

    const saveSponsorsSpeakersOrder = (event) => {

        setSponsor(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["executivesOrder"]: event      // update the value of specific key

        }))

        setSponsorLocale(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["executivesOrder"]: event      // update the value of specific key

        }))
        console.log("saveSponsorsSpeakersOrder",sponsor)
    }

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const return_options_executives = (data) => {
        let arr = [];
        executives.map((e,index) =>{
            if(data && data.includes(e.id)){
                arr.push(executives[index]);
            }
        });
        console.log("return_options_executives",arr,executives)
        return arr;
    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }


    if(loading === true || sponsor.length == 0 || Object.keys(executives).length == 0 || Object.keys(sponsor).length == 0
        // || executives.length == 0
        // || (sponsorFiles && sponsorFiles.length == 0)
    ){
        return <Loader />
    }

    // let decodedHtml;
    // decodedHtml =  sponsor.descriptionz.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    // let video_types = [{id:'vimeo',title:'vimeo'},{id:'youtube',title:'YouTube'},{id:'local',title:'LiveOn'},{id:'facebook',title:'Facebook'}]
    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]
    let wrapper_style = {
        display:'flex',justifyContent:'center',transition:'1s all'
    }
    let file_wrapper_style = {
        width:'0px',
        transition: "all 0.5s"
    }
    if(showSponsorFiles.state === true){
        file_wrapper_style = {
            width: "100%",
            boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            background: "#fdf9ee",
            marginLeft: "0",
            marginTop: "30px",
            transition: "all 0.5s",
            borderRadius: "20px"
        }
    }
    // console.log("localeDatazzz",sponsorLocale)
    let sponsor_sizes = [{id:'Large',title:t('Large')},{id:'Big',title:t('Medium')},{id:'Medium',title:t('Small')},{id:'Small',title: t('Line')}]
    return (
        <div className="bottom_bar">
            {/*<Grid container spacing={3} style={{padding:'20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)} className={close_button.root} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Typography aligncenter variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Sponsor')}
            </Typography>

            <Typography alignLeft variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{sponsorLocale.title}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
        <div id='sponsors_wrapper' style={wrapper_style}>
        <React.Fragment key={'right'}>
            <Grid container spacing={4} style={{padding:'0 20px',maxWidth:'800px'}}>
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Sponsor Details')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.title?sponsorLocale.title:""} handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
                </Grid>

                <Grid item xs={12}>
                    <Editor lang={localeLanguage} handler={handleSettingsFunc} value={sponsorLocale.description} name="description" label={t('Description')}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                        {t('Sponsor Category')} *
                    </Typography>
                    <FormControl variant="outlined" className={classes.select}>

                        <Select
                            labelId="grouped-native-select"
                            IconComponent={ExpandMoreIcon}
                            id="grouped-native-select"
                            value={sponsorLocale.category_id?sponsorLocale.category_id:''}
                            onChange={handleChange}
                            name="category_id"
                        >
                            {/*<MenuItem value="">*/}
                            {/*    <em>None</em>*/}
                            {/*</MenuItem>*/}
                            {sponsorcategories.map((row) => (
                                <MenuItem value={row.id}>{row.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                        <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                            {t('Sponsor_Type')}
                        </Typography>
                        <FormControl variant="outlined" className={classes.select}>
                            <Select
                                labelId="grouped-native-select"
                                IconComponent={ExpandMoreIcon}
                                id="grouped-native-select"
                                value={sponsor.typos?sponsor.typos:'Small'}
                                onChange={handleChange}
                                name="typos"
                                style={{maxWidth:"350px"}}
                            >
                                {sponsor_sizes.map((row) => (
                                    <MenuItem value={row.id}>{row.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Grid>
                {/*<Grid item xs={6}>*/}
                {/*    <CTextField value={sponsor.order?sponsor.order:""} handler={onInputchange} name='order' label="Order"/>*/}
                {/*</Grid>*/}

                <Grid item xs={6} className="panel_sponsor logo_images">
                    <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                        {t('Sponsor Logo')}
                        <span className="image_text_description">{t('upload_dimensions', {text: '300px * 180px'})}</span>
                    </Typography>
                    <FileUploadComponent id="1" image={sponsorLocale.logo?sponsorLocale.logo:''} extra={{id: sponsorid,action:'/data/addSponsorLogo'}}
                                         accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={300} maxHeight={180}
                    />

                </Grid>

                <Grid item xs={6} className="panel_sponsor logo_images">
                    <fieldset disabled={ (sponsorLocale.video?sponsorLocale.video:"").trim() != '' }>
                    <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                        {t('Sponsor Banner')}
                        <span className="image_text_description">{t('upload_dimensions', {text: '720px * 392px'})}</span>
                    </Typography>
                    <FileUploadComponent id="2" image={sponsorLocale.topwidebanner ?sponsorLocale.topwidebanner :''} extra={{id: sponsorid,action:'/data/addSponsorTopwidebanner'}}
                                         accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={720} maxHeight={392}
                    />
                    </fieldset>
                </Grid>

                <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}} fullwidth>
                        <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px",padding:"0"}} gutterBottom>
                            {t('Video')}
                        </Typography>
                        <div style={{display:'flex',alignItems:'center'}}>
                            {/*<InputLabel id="demo-simple-select-outlined-label">Video Recording</InputLabel>*/}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="grouped-native-select"
                                IconComponent={ExpandMoreIcon}
                                value={sponsorLocale && sponsorLocale.type_video?sponsorLocale.type_video:'vimeo'}
                                onChange={handleChange}
                                name="type_video"
                                style={{marginRight:"15px",width:"140px"}}
                            >
                                {video_types.map((row) => (
                                    <MenuItem value={row.id}>{row.title}</MenuItem>
                                ))}
                            </Select>
                            <TextField id="outlined-basic" label="" variant="outlined" value={sponsorLocale.video?sponsorLocale.video:""} onChange={onInputchange} name='video' fullWidth/>
                        </div>
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <Editor lang={localeLanguage} handler={handleSettingsFunc} value={editorDescription??''} name="description" label={t('Description')}/>
                </Grid>
                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.website?sponsorLocale.website:""} handler={onInputchange} name='website' label={t('Website')}/>
                </Grid>
                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.email?sponsorLocale.email:""} handler={onInputchange} name='email' label={t('Email')}/>
                </Grid>


                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Executives')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                        padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                        <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 20px 17px 20px"}} gutterBottom>
                            {/*{t('Executive')}*/}
                        </Typography>
                        <div id="autocomplete_selector">
                        <Autocomplete
                            multiple
                            popupIcon={<ExpandMoreIcon />}
                            id="checkboxes-tags-demo"
                            options={executives}
                            disableCloseOnSelect
                            onChange={onExecutivesChange}
                            defaultValue={return_options_executives(sponsorLocale.executives)}
                            getOptionLabel={(option) => option.title}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="#000"
                                    />
                                    {option.title}
                                </React.Fragment>
                            )}
                            style={{ }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label=""  name="executivess" placeholder={t("Choose_an_executive")} fullwidth/>
                            )}
                        />
                        {sponsorLocale.addExecutives && Object.keys(sponsorLocale.addExecutives).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}
                        {/*<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>*/}
                        <DraggableSimplestList  data={sponsorLocale.addExecutives==='true'?sponsorLocale.draggable_executives:sponsorLocale.addExecutives} save={saveSponsorsSpeakersOrder}/>
                        </div>
                    </Paper>
                </Grid>

                {/*<Grid item xs={12}>*/}
                    <br/>
                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t("Settings Social links")}:
                        </Typography>
                        <Divider />
                        <br/>
                    </Grid>
                    {/*<Typography variant="h5" style={{textAlign:'left'}} gutterBottom>*/}
                    {/*    {t("Settings Social links")}:*/}
                    {/*</Typography>*/}
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Facebook")} value={sponsorLocale.socials.Facebook} name="Facebook" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social LinkedIn")} value={sponsorLocale.socials.LinkedIn} name="LinkedIn" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Youtube")} value={sponsorLocale.socials.Youtube} name="Youtube" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Twitter")} value={sponsorLocale.socials.Twitter} name="Twitter" />
                        </Grid>
                    </Grid>
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Files')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Button   size="medium" onClick={showSponsorFilesMenu(true)} variant="contained" height="50" color="secondary">{t('+ New File')}</Button>
                    <Grid item xs={12} style={file_wrapper_style}>
                        {showSponsorFiles.state?
                        showSponsorFiles.id?<UpdateFile sponsor_id={props.props} props={showSponsorFiles.id} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc} triggerdataload={updatePostsHandler} />:<NewFile filename={filename} holdFilename={holdFilename} triggerdataload={addFileHandler} sponsor_id={props.props} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc}/>:""}
                    </Grid>
                    <ListFiles filename={filename} holdFilename={holdFilename} triggerdataload={updatePostsHandler} sponsor_id={props.props} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc}  data={sponsorFiles} OnClick={showSponsorFilesMenu}/>

                </Grid>

                <PanelBottomBar language={changeLocale} close={props.triggerdrawer(false)} submit={onSubmitForm} />
                {/*<Grid id="panel_utilities_element" className="panel_utilities_element" item xs={12}>*/}
                {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                {/*    <div className="right_part">*/}
                {/*    <LanguageChangerForPanel onChange={setLocaleLanguage}/>*/}
                {/*    <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            </Grid>

        </React.Fragment>
        </div>
        </div>

    );

}

