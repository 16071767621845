import React, {useContext, useEffect, useState, createRef} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FileUploadComponent from "../../common/FileUploadComponent";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../common/Constants"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import NewPageLink from "./components/PageLinks/NewPageLink"
import EditPageLink from "./components/PageLinks/EditPageLink"
import Pages from './components/Pages'
import DrawerWindow from "../../common/DrawerWindow"
import CTextField from "../../common/CTextField";
import CSwitchField from "../../common/CSwitchField";
import Editor from '../../common/Editor';
import {toast} from "react-toastify";
import DraggableSimpleList from "../../../common/DraggableSimpleList";
import PageProvider from "../../../Page/PageContext";
import {useTranslation} from "react-i18next";
import {ChromePicker} from 'react-color';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import TablePredefinedEmails from "../Invitations/components/TablePredefinedEmails";
import ListItemText from "@material-ui/core/ListItemText";
import LanguageChangerForPanel from "../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../common/Loader";
import PanelBottomBar from "../../../common/PanelBottomBar";
import ToastProvider from "../../../../Providers/ToastProvider";
import HelpDrawer from "../../common/HelpDrawer";
import HelpIcon from "@material-ui/icons/Help";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import DraggableSimplestList from "../../../common/DraggableSimplestList";
import AutoCompleteField from "../../../common/AutoCompleteField";
import {InputTextHandler} from "../../common/InputTextHandler";

export default function SwitchesGroup() {

    const {notify} = useContext(ToastProvider);
    const {settings, setReloadData} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [state, setState] = useState({});
    const [links, setLinks] = useState([]);
    const [commonPages, setCommonPages] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fieldsState, setFieldsState] = useState(null);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [stateLocale, setStateLocale] = useState({});
    const helpDrawerRef = createRef();
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            open_cat: false,
            id: null,
            id_cat: null,
        }
    );
    const [drawerstatePage, setDrawerstatePage] = useState({
            open: false,
            open_cat: false,
            id: null,
            id_cat: null,
        }
    );
    const [editorAboutTextHomepage, setEditorAboutTextHomepage] = useState(null);
    const [editorFooterContactText, setEditorFooterContactText] = useState(null);
    const {
        onTextchange
        , handleDraggableListItemInState
        , handleDraggableOrder
        , handleEditor
        , handleDateTimePicker
    }
        = InputTextHandler({state: state, setState: setState, lang: localeLanguage});


    const saveSpeakersOrder = (event) => {
        handleDraggableOrder(event, 'speakers');
    }

    const onSpeakersChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'speakers');
    }

    const changeLocale = (lang) => {
        setLocaleLanguage(lang);
    }

    useEffect(() => {
        setEditorAboutTextHomepage(stateLocale.about_text_homepage);
        setEditorFooterContactText(stateLocale.footer_contact_text);
        setLocaleData(state);
    }, [localeLanguage])

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if (value == "footer_contact_text" || value == "about_text_homepage" || value == "about_text_title") {
                localeData[value] = data[value][localeLanguage] ? data[value][localeLanguage] : " ";
            }else {
                localeData[value] = data[value];
            }
        })

        setStateLocale(localeData)
        setEditorAboutTextHomepage(localeData.about_text_homepage);
        setEditorFooterContactText(localeData.footer_contact_text);
        console.log("localeData", localeData, localeLanguage)
    }

    const updatePagesStatusesHandler = (event) => {

        let value = event.target.parentElement.getAttribute("data-value");
        let id = event.target.parentElement.getAttribute("data-id");
        let name = event.target.parentElement.getAttribute("data-name");
        let item_index = event.target.parentElement.getAttribute("data-index");
        let value_name = event.target.parentElement.getAttribute("data-value_name");
        // console.log("updatePagesStatusesHandler",value, name, item_index, value_name)
        let post_object = {id:id,value:value}
        axios.post(constants.LocalUrl + '/data/updatePageStatus',post_object).then(response => {
            console.log("response",response);
            if(response.data.status == "nope"){
                notify(t("Page_status_not_updated"))
            }
            loadCommonPages();
        });


    };

    const loadCommonPages = async () => {
        await axios.post(constants.LocalUrl + '/data/common_pages_get').then(response => {
            // After fetching data stored it in posts state.
            setCommonPages(response.data.data);

            setLoading(false);
        });
    }

    useEffect(() => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult

            await axios.post(constants.LocalUrl + '/data/get_settings').then(response => {
                // After fetching data stored it in posts state.
                // console.log("////");
                console.log(response.data);

                setState(response.data);
                setLocaleData(response.data)
                setLoading(false);
            });

            axios.post(constants.LocalUrl + '/data/footer_links_get').then(response => {
                setLinks(response.data);
            });

            axios.post(constants.LocalUrl + '/data/get_all_speakers').then(response => {
                setSpeakers(response.data);
            });

        }
        loadCommonPages();
        // Call the function
        loadItems();
    }, []);

    const handleSettingsAbout = (data) => {

        setState((prevState) => ({
            ...prevState,
            ['about_text_homepage']: {
                ...prevState['about_text_homepage'],
                [localeLanguage]: data,
            }
        }));

    }


    const handleSettingsFunc = (data) => {

        setState((prevState) => ({
            ...prevState,
            ['footer_contact_text']: {
                ...prevState['footer_contact_text'],
                [localeLanguage]: data,
            }
        }));

    }

    const handleAboutTextTitle = (event) => {

        event.persist();
        setState((prevState) => ({
            ...prevState,
            ['about_text_title']: {
                ...prevState['about_text_title'],
                [localeLanguage]: event.target.value,
            }
        }));

        setStateLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        console.log("handleAboutTextTitle",state)

    }

    const handleSettings = (event) => {
        event.persist();

        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    }

    const [selectedDate, handleDateChange] = useState(new Date());

    const handleTime = (event) => {

        handleDateChange(event)
        setState((prevState) => ({
            ...prevState,
            time: selectedDate,
        }));

    }

    const handleMainColor = (data) => {
        setState((prevState) => ({
            ...prevState,
            font_color: data.hex,
        }));
    }

    const handleSecondaryColor = (data) => {
        setState((prevState) => ({
            ...prevState,
            divider_color: data.hex,
        }));
    }

    const handleChange = (event) => {

        event.persist();

        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));

        let setting_change = {
            [event.target.name]: event.target.checked
        }
    };

    const onSubmitForm = () => {
        let end_state = {...state};
        if (end_state.footer_contact_textz) {
            end_state.footer_contact_text = end_state.footer_contact_textz;
        }
        if (end_state.about_text_homepagez) {
            end_state.about_text_homepage = end_state.about_text_homepagez;
        }

        delete end_state.settings_footer_logo;
        delete end_state.lobby_bg;
        delete end_state.EVENT_DATE
        // console.log("end_state",end_state)
        axios.post(
            constants.LocalUrl + '/data/saveSettings', end_state).then(response => {
                console.log("response",response)
            if (response.data.status == "ok") {
                setReloadData(true);
                notify(t('Succesfully saved'));
            }
        });
    }

    const saveFieldsOrder = (event) => {

        const response = axios.post(
            constants.LocalUrl + "/data/saveFooterUrlsOrder", {data: event});

    }

    const savePagesOrder = (event) => {

        const response = axios.post(
            constants.LocalUrl + "/data/savePagesOrder", {data: event});

    }

    const toggleChildMenuPage = (attached, page_id) => () => {
        setDrawerstatePage({...drawerstatePage, open: attached, id: page_id});
        // console.log(drawerstate);
    };

    const toggleChildMenuPageFunc = (attached, page_id) => {
        setDrawerstatePage({...drawerstatePage, open: attached, id: page_id});
        // console.log(drawerstate);
    };

    const toggleChildMenu = (attached, sponsor_id) => () => {
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
        // console.log(drawerstate);
    };

    const toggleChildMenuFunc = (attached, sponsor_id) => {
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
    };

    const newPostsHandler = (data) => {
        // console.log("newPostsHandler",data,links);
        setLinks((links) => [data, ...links]);
    };

    const updatePostsHandler = (data) => {
        axios.post(constants.LocalUrl + '/data/footer_links_get').then(response => {
            setLinks(response.data);
        });
    };

    const updateCommonPages = (data) => {
        loadCommonPages();
    };

    // const handleSettingsFunc = (data) => {
    //
    //     setState((prevState) => ({
    //         ...prevState,
    //         ['footer_contact_text']: {
    //             ...prevState['footer_contact_text'],
    //             [localeLanguage]: data,
    //         }
    //     }));
    //
    // }

    // const onSpeakersChange = (event, values) => {
    //     // event.persist();
    //
    //     // setState(
    //     //     prevState => ({
    //     //         // object that we want to update
    //     //         ...prevState,    // keep all other key-value pairs
    //     //         speaker_data: values      // update the value of specific key
    //     //
    //     //     }))
    //
    //     setState(
    //         prevState => ({
    //             // object that we want to update
    //             ...prevState,    // keep all other key-value pairs
    //             ['speakers']: {
    //                 ...prevState['speakers'],
    //                 ['items']: values,
    //             }      // update the value of specific key
    //
    //         }))
    //
    //     setStateLocale(
    //         prevState => ({
    //             // object that we want to update
    //             ...prevState,    // keep all other key-value pairs
    //             ['speakers']: {
    //                 ...prevState['speakers'],
    //                 ['items']: values,
    //             }
    //
    //         }))
    //     // console.log(schedule, values);
    // }

    if (loading === true || Object.keys(state).length == 0 || Object.keys(commonPages).length == 0 || Object.keys(stateLocale).length == 0) {
        return <Loader/>
    }

    return (
        <SimpleBar forceVisible="y" autoHide={false} className="bottom_bar" style={{maxHeight: "658px", height: "658px", overflowX: "hidden"}}>
            <Grid container spacing={4} style={{padding: '20px', paddingTop: "0", paddingBottom: "0", maxWidth: 'unset'}}>
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left'}}
                                className="main_settings_section_title" gutterBottom>
                        {t('Main_Site_Colors')}
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t('Main_color')}
                    </Typography>
                    <ChromePicker color={state.font_color} onChange={handleMainColor} disableAlpha/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t('Secondary_color')}
                    </Typography>
                    <ChromePicker color={state.divider_color} onChange={handleSecondaryColor} disableAlpha/>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="main_settings_title" variant="h3"
                                style={{textAlign: 'left', marginTop: "20px"}} className="main_settings_section_title"
                                gutterBottom>
                        {t('Settings Homepage')}
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={6} className="logo_images">
                    <Typography alignLeft variant="h6"
                                style={{display: 'block', marginBottom: "15px", marginLeft: "20px", marginTop: 0}}
                                gutterBottom>
                        {t('Homepage intro image')}
                        <span
                            className="image_text_description">{t('upload_dimensions', {text: '1920px * 790px'})}</span>
                    </Typography>
                    <FileUploadComponent id="2" image={state && state.lobby_bg ? state.lobby_bg : ''}
                                         extra={{action: "/data/addSettingsMainBanner"}}
                                         accept={constants.acceptedUploadMimeGroups.images}
                                         maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={1920} maxHeight={790}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t('Settings Show in main banner')}:
                    </Typography>

                    <Paper style={{overflowY: 'auto', height: 'auto', padding: '20px', borderRadius: '23px'}}>
                        <List component="nav" style={{width: '100%', maxWidth: "unset", borderRadius: '23px'}}
                              fullwidth>

                            <Divider light/>
                            <ListItem button fullwidth>
                                <CSwitchField label={t("Settings Event name")} state={state.event_name_mainbanner}
                                              handler={handleChange} name="event_name_mainbanner"/>
                            </ListItem>
                            <Divider light/>
                            <ListItem button fullwidth>
                                <CSwitchField label={t("Settings Event date")} state={state.date_mainbanner}
                                              handler={handleChange} name="date_mainbanner"/>
                            </ListItem>
                            <Divider light/>
                            <ListItem button fullwidth>
                                <CSwitchField label={t("Settings Countdown")} state={state.countdown_mainbanner}
                                              handler={handleChange} name="countdown_mainbanner"/>
                            </ListItem>
                            <Divider light/>
                            <ListItem button fullwidth>
                                <CSwitchField label={t("Settings Register button")} state={state.register_mainbanner}
                                              handler={handleChange} name="register_mainbanner"/>
                            </ListItem>
                            <Divider light/>
                            <ListItem button fullwidth>
                                <CSwitchField label={t("Settings Watch")} state={state.watch_mainbanner}
                                              handler={handleChange} name="watch_mainbanner"/>
                            </ListItem>
                            <Divider light/>
                        </List>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <CTextField handler={handleAboutTextTitle} label={t("Settings About text title")}
                                value={stateLocale.about_text_title} name="about_text_title"/>
                </Grid>

                <Grid item xs={12}>
                    <Editor lang={localeLanguage} handler={handleSettingsAbout} label={t("Settings About text") + ':'}
                            value={editorAboutTextHomepage??''} name="about_text_homepage"/>
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <Typography variant="h6" style={{
                            textAlign: 'left',
                            marginBottom: "15px",
                            marginLeft: "20px",
                            marginTop: "0"
                        }} gutterBottom>
                            {t('Settings Access')}:
                        </Typography>
                        <Paper style={{
                            overflowY: 'auto',
                            height: 'auto',
                            padding: '20px',
                            borderRadius: '23px',
                            width: "100%"
                        }}>
                            <List component="nav" style={{width: '100%', maxWidth: "unset", borderRadius: '23px'}}
                                  fullwidth>

                                <Divider light/>
                                <ListItem button fullwidth>
                                    <CSwitchField label={t("Settings Access Stage")} state={state.menu_stage_visible}
                                                  handler={handleChange} name="menu_stage_visible"/>
                                </ListItem>
                                <Divider light/>
                                <ListItem button fullwidth>
                                    <CSwitchField label={t("Settings Access Agenda")} state={state.menu_agenda_visible}
                                                  handler={handleChange} name="menu_agenda_visible"/>
                                </ListItem>
                                <Divider light/>
                                <ListItem button fullwidth>
                                    <CSwitchField label={t("Settings Access Speakers")}
                                                  state={state.menu_speakers_visible} handler={handleChange}
                                                  name="menu_speakers_visible"/>
                                </ListItem>
                                <Divider light/>
                                <ListItem button fullwidth>
                                    <CSwitchField label={t("Settings Access Sponsors")}
                                                  state={state.menu_sponsors_visible} handler={handleChange}
                                                  name="menu_sponsors_visible"/>
                                </ListItem>
                                <Divider light/>
                            </List>
                        </Paper>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Speakers_Order")}
                        <HelpIcon style={{color:"rgba(0, 0, 0, 0.87)",marginLeft:"8px",cursor:'pointer'}} onClick={()=>helpDrawerRef.current.toggleDrawer(true)}/>
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteField
                        label={t('Speakers')}
                        placeholder={t("Choose a speaker...")}
                        model={speakers}
                        data={state.speakers}
                        onChange={onSpeakersChange}
                        save={saveSpeakersOrder}
                    />
                </Grid>
                <HelpDrawer id="settings-speakers-order" ref={helpDrawerRef} />

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Settings Footer")}
                    </Typography>
                    <Divider/>
                </Grid>

                <Grid item xs={12} className="logo_images">
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t("Settings Footer logo")}:
                        <span
                            className="image_text_description">{t('upload_dimensions', {text: '190px * 150px'})}</span>
                    </Typography>

                    <FileUploadComponent image={state && state.settings_footer_logo ? state.settings_footer_logo : ''}
                                         extra={{action: "/data/addSettingsFooterLogo"}}
                                         accept={constants.acceptedUploadMimeGroups.images}
                                         maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={188} maxHeight={150}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Editor header="h6" lang={localeLanguage} handler={handleSettingsFunc}
                            label={t("Settings Contact text") + ':'} value={editorFooterContactText}
                            name="footer_contact_text"/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t("Common_Pages")}:
                    </Typography>
                    <Button size="medium" onClick={toggleChildMenuPage(true)} variant="contained" height="50"
                            color="secondary">{t("Settings + New Page")}</Button>
                    <DraggableSimpleList object_type="Pages" buttonAction={updatePagesStatusesHandler} data={commonPages} save={savePagesOrder} edit={toggleChildMenuPage} no_content_message={t('no_content_settings_pages')}/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t("Settings Page links")}:
                    </Typography>

                    <Button size="medium" onClick={toggleChildMenu(true)} variant="contained" height="50"
                            color="secondary">{t("Settings + New URL")}</Button>


                    <DraggableSimpleList no_content_message={t('No footer links created')} object_type="FooterLink" data={links} edit={toggleChildMenu}
                                         save={saveFieldsOrder} triggerdataload={updatePostsHandler} no_content_message={t('no_content_settings_pages')} />
                </Grid>

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Predefined_emails")}
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <TablePredefinedEmails email_list='front_maileditor'></TablePredefinedEmails>
                </Grid>

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Settings Social links")}
                    </Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12} className="settings_social_link_wrapper">
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <CTextField handler={handleSettings} label={t("Settings Social LinkedIn")}
                                        value={state.footer_linkedin_social} name="footer_linkedin_social"/>
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={handleSettings} label={t("Settings Social Facebook")}
                                        value={state.footer_facebook_social} name="footer_facebook_social"/>
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={handleSettings} label={t("Settings Social Twitter")}
                                        value={state.footer_twitter_social} name="footer_twitter_social"/>
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={handleSettings} label={t("Settings Social Instagram")}
                                        value={state.footer_instagram_social} name="footer_instagram_social"/>
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={handleSettings} label={t("Settings Social YouTube")}
                                        value={state.footer_youtube_social} name="footer_youtube_social"/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Analytics")}
                    </Typography>
                    <Divider/>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <CTextField handler={handleSettings} label={t("analytics_google_ga4")} value={state.analytics}
                                    name="analytics"/>
                    </Grid>
                </Grid>




                <PanelBottomBar classes={"panel_utilities_element basicinfo"} style={{justifyContent: "flex-end"}}
                                submit={onSubmitForm} language={changeLocale}/>

            </Grid>

            <DrawerWindow open={drawerstate.open}>
                {drawerstate.id ? <EditPageLink link_id={drawerstate.id} posts={links} triggerdrawer={toggleChildMenu}
                                                triggerdrawerfunc={toggleChildMenuFunc}
                                                triggerdataload={updatePostsHandler}/> :
                    <NewPageLink posts={links} triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc}
                                 triggerdataload={newPostsHandler}/>}
            </DrawerWindow>
            <DrawerWindow open={drawerstatePage.open}>
                <Pages posts={commonPages} page_id={drawerstatePage.id} triggerdrawer={toggleChildMenuPage}
                       triggerdrawerfunc={toggleChildMenuPageFunc} triggerdataload={updateCommonPages}/>
            </DrawerWindow>
        </SimpleBar>

    );
}
