import React, {Component} from 'react';
import {map} from 'lodash';
import FlatButton from '@material-ui/core/Button';
import styles from './Repeatable.css';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

// const plus = <FontIcon className={`${awesome.fa} ${awesome['fa-plus']}`}/>;
// const minus = <FontIcon className={`${awesome.fa} ${awesome['fa-minus']}`}/>;

export default class Repeatable extends Component {

    static defaultProps = {
        initialCopies: 1,
        requiredCopies: 1,
        childrenRenderer: (index) => [],
        childrenSavedRenderer: (index,data) => [],
        onAdd: (index) => undefined,
        onRemove: (index) => undefined,
        savedFields: () => [],
        style: {},
        label: '---'
    }

    constructor() {
        super();
        this.counter = 0;
        this.state = {
            items: {},
            saveditems: {},
        };
    }

    componentDidMount() {
        this.createSavedItems(this.props.savedFields.length);
        this.createItems(this.props.initialCopies);
    }

    renderItems() {
        console.log(this.state.items);
        return map(
            this.state.items,
            (_, index) => (
                <>
                    <div key={`repeatable-item-${index}`} className={styles.Row} style={{display: "flex", alignItems: "center"}}>
                        <div className={styles.Item} style={{width: '90%', display: "inline-block"}}>
                            {this.props.childrenRenderer(index)}
                        </div>
                        <div
                            className={styles.ItemControls}
                            // style={{display: index < this.props.requiredCopies ? 'none' : ''}}
                            style={{width: '10%', textAlign: "right", display: "inline-block"}}
                        >
                            <FlatButton icon={<CloseIcon></CloseIcon>} label="" onClick={this.removeItem(index)}>
                                {<CloseIcon></CloseIcon>}
                            </FlatButton>
                        </div>
                    </div>
                    <br/>
                </>
            )
        );
    }

    renderSavedItems() {
        return map(
            this.props.savedFields,
            (_, index,data) => (
                <>
                    <div style={{alignItems:'center'}} key={`repeatable-item-${index}`} className={styles.Row}>
                        <div className={styles.Item} style={{width: '90%', display: "inline-block"}}>
                            {this.props.childrenSavedRenderer(index,data[index])}
                        </div>
                        <div className={styles.ItemControls}  style={{width: '10%', textAlign: "right", display: "inline-block"}}
                            // style={{display: index < this.props.requiredCopies ? 'none' : ''}}
                        >
                            {console.log("renderSavedItems",index,data)}
                            <FlatButton icon={<CloseIcon></CloseIcon>} label="" onClick={this.removeSavedItem(index)}>
                                {<CloseIcon></CloseIcon>}
                            </FlatButton>
                        </div>
                    </div>
                    <br/>
                </>
            )
        );
    }

    removeItem = (index) => () => {
        {console.log("removeItem",index)}
        let items = { ...this.state.items };
        delete items[index];
        this.setState({ items });
        this.props.onRemove(index);
    }

    removeSavedItem = (index) => () => {
        {console.log("removeSavedItem",index,this.state.saveditems)}
        let items = { ...this.state.saveditems };
        delete items[index];
        this.setState({ items });
        this.props.onRemove(index);
    }

    createItems = (num = 1) => {
        let items = { ...this.state.items };
        for (let index = 0; index < num; index++) {
            let key = this.counter++;
            items[key] = true;
        }
        this.setState({ items });
        return this.counter;
    }

    createSavedItems = (num = 1) => {
        console.log(this.props.savedFields.length);
        console.log(this.props.savedFields);
        let items = { ...this.props.savedFields.length };
        for (let index = 0; index < num; index++) {
            let key = this.counter++;
            items[key] = true;
            // console.log(items);
        }
        this.setState({ items });
        return this.counter;
    }

    addItem = () => this.props.onAdd(this.createItems(1))

    render() {

        return (
            <div style={{width: '100%'}}>
                <Grid container spacing={0} style={{display: "flex", justifyContent: "space-between"}}>
                    {this.props.label ?? null}
                    <div className={styles.Controls}>
                        <FlatButton  label={<PlaylistAddIcon></PlaylistAddIcon>} onClick={this.addItem}>
                            {<AddIcon></AddIcon>}
                        </FlatButton>
                    </div>
                </Grid>

                {this.renderSavedItems()}
                {this.renderItems()}

            </div>
        );
    }

}