import React, {useState} from 'react';
import * as constants from "../../../../../Panel/common/Constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";


export default function NewPageLink(props) {

    const {t, i18n} = useTranslation();
    const [link, setLink] = useState([]);
    const [formErrors, setFormErrors] = useState(null);

    const handleLinks = (event) => {
        event.persist();

        setLink((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    }

    const onSubmitForm = () => {

        // const loadItems = async () => {
        axios.post(
            constants.LocalUrl + '/data/footer_links_create', link).then(response => {

            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            }
            else if (response.data.status == 'ok') {
                props.triggerdataload(response.data.data);
                props.triggerdrawerfunc(false);
            }
            console.log(response)
        });
        // }

        // Call the function
        // loadItems();

    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }


    return (
        <div>
            {/*<Grid container spacing={3} style={{padding: '20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)}*/}
            {/*                   style={{position: 'absolute', right: '9px', top: '9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Create URL')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <Grid container spacing={3} style={{padding: '20px'}}>
                {/*<Grid item xs={12}>*/}

                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Typography variant="h6" style={{display: 'block'}} gutterBottom>
                        {t('Name')}:
                    </Typography>
                    <TextField
                        id="outlined-multiline-flexible"

                        placeholder=""
                        defaultValue=""
                        style={{width: '100%'}}
                        name='name'
                        onChange={handleLinks}
                        variant="outlined"
                        required error={hasError('name')} helperText={errorMessage('name')}
                    />
                    <br/><br/>
                    <Typography variant="h6" style={{display: 'block'}} gutterBottom>
                        {t('URL')}:
                    </Typography>
                    <TextField
                        id="outlined-multiline-flexible"

                        placeholder=""
                        defaultValue=""
                        style={{width: '100%'}}
                        name='data'
                        // label="Stream stopped message"
                        // value={}
                        onChange={handleLinks}
                        variant="outlined"
                        required error={hasError('data')} helperText={errorMessage('data')}
                    />
                </Grid>
                <Grid className="panel_utilities_element" item xs={12}>
                    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)}>{t("go_back")}</Button>
                    <div className="right_part">
                        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}