import React, {createRef, useCallback, useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Repeatable from "../../../common/Repeatable";
import * as constants from "../../../common/Constants"
import CTextField from "../../../common/CTextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import * as XLSX from "xlsx";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import InvitationPreview from "./InvitationPreview";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import HelpIcon from "@material-ui/icons/Help";
import HelpDrawer from "../../../common/HelpDrawer";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {ReactSVG} from "react-svg";
import TooltipProvider from "../../../../../Providers/TooltipProvider";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import {InputTextHandler} from "../../../common/InputTextHandler";

let new_row = {'email': '', 'fullname': ''};

const edit_icon = {
    icon: "/images/edit.svg",
    delete: "/images/trash.svg",
}



export default function NewSendInvitation(props) {
    const {t, i18n} = useTranslation();

    const helpDrawerRef = createRef();
    const [gridRef, setGridRef] = useState(null);
    const [formData, setformData] = useState({
        // name: "newSendEmails"
    });
    const [formErrors, setFormErrors] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const {BoldTooltip} = useContext(TooltipProvider);

    const [loading, setLoading] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [copies, setCopies] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const {handleDraggableListItemInState} = InputTextHandler({state:formData,setState:setformData});
    const onSpeakersChange = (event, values) => {
        console.log("state",formData,event, values)
        handleDraggableListItemInState(event, values, 'users')
    }


    const new_schedule = makeStyles((theme) => ({
        root: {
            display: 'block',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(45),
                // height: theme.spacing(13),
            },
        },
        new_schedule: {
            // width:'100%',
            textAlign: 'center'
        }
    }));

    const handleSelectAllUsers = (event) => {

        let _allUsers = [];
        if(event.target.checked){
            allUsers.map((user,index) => {
                _allUsers.push(user.id)
            })

            handleDraggableListItemInState(event, allUsers, 'users')
        }

        setformData((prevState) => ({
            ...prevState,
            ["users"]: {
                ...prevState["users"],
                ['selected_items']: _allUsers,
            }
        }));


        console.log("formData",formData)
    }

    const onInputchange = (event) => {
        event.persist();
        // console.log(event);
        setformData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        // console.log(formData);
    };

    const repeat = (index) => (
        <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{fontWeight: '600'}}>
                <CTextField handler={onInputchange} name={"item." + index + ""} required
                            error={hasError("item." + index + "")} helperText={errorMessage("item." + index + "")}/>
            </Grid>
        </Grid>
    )

    const savedRepeat = (index, data) => (
        <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{fontWeight: '600'}}>
                <CTextField handler={onInputchange} name={"item." + index + ""} required
                            error={hasError("item." + index + "")} helperText={errorMessage("item." + index + "")}
                            value={data}/>
            </Grid>
        </Grid>
    )

    const onAdd = (index) => {
        // console.log('added replicant', index);
        setCopies({copies: copies + 1});
    }

    const onRemove = (index) => {
        setCopies({copies: copies - 1});
    }



    const onSubmitForm = () => {
        const validate = async () => {
            let postData = formData;
            postData.invitation_id = props.invitation.id;
            const response = await axios.post(constants.LocalUrl + '/data/validate_invitation_batch', postData);
            console.log('response', response);
            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            } else if (response.data == 1) {
                // if (window.confirm(t('send_invitation_confirm_text'))) {
                loadItems();
                // }
            }
        }
        const loadItems = async () => {
            let postData = formData;
            postData.invitation_id = props.invitation.id;
            const response = await axios.post(
                constants.LocalUrl + '/data/save_send_invitation', postData).then(response => {
                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                } else if (response.data.status == 'ok') {
                    props.triggerdrawer(false, null);
                    props.triggerdrawerfunc(false, null);
                    props.triggerdataload(response.data.data);
                }
            });
        }
        setFormErrors([]);
        validate();
    };

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ?
            <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    useEffect(() => {
        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + '/data/get_all_users_obj');

            // After fetching data stored it in posts state.
            setAllUsers(response.data);

            // Closed the loading page
            setLoading(false);
        }

        // Call the function
        loadPost();
    }, []);


    if (loading === true) {
        return <Loader/>
    }

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "array"});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                console.log(json);
                setformData({...formData, xlsxUsers: json});
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const rowStyle = ({data}) => {
        return null;
    }
    const gridStyle = {minHeight: 283, marginTop: 10, height: "100%"};
    const defaultPagination = 5;
    const deleteRow = (rowNum) => {
        if (window.confirm(t('user_delete_confirm_text'))) {
            let json = formData.xlsxUsers.filter((row) => row['__rowNum__'] != rowNum);
            setformData({...formData, xlsxUsers: json});
        }
    }

    return (
        <div>

            <div>
                <Typography aligncenter variant="h3" style={{textAlign: 'center'}} gutterBottom>
                    {t('create_send_list')}
                </Typography>
                <Typography aligncenter variant="h5" style={{textAlign: 'center'}} gutterBottom>
                    {t('Using')}: {props.invitation.title}
                </Typography>
                <Divider style={{backgroundColor: "#39BB0F", marginBottom: "15px"}}/>

                <Grid item xs={12}>
                    <CTextField handler={onInputchange} name='title' label={t('Title')} required
                                error={hasError('title')} helperText={errorMessage('title')}/>
                </Grid>

                <Grid item xs={12}>
                    <br/>

                    <AutoCompleteField
                        label={t('Existing Users')}
                        placeholder={t("Choose a user...")}
                        model={allUsers}
                        data={formData.users}
                        onChange={onSpeakersChange}
                        type="single"
                        selectAll={handleSelectAllUsers}
                    />
                </Grid>

                <Grid item xs={12}>
                    <br/>

                    <Repeatable
                        label={<Typography className={new_schedule} alignLeft variant="h6" gutterBottom
                                           style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}>
                            {t('Manually inserted')}
                        </Typography>}
                        onChange={onInputchange}
                        initialCopies={copies}
                        childrenRenderer={repeat}
                        childrenSavedRenderer={savedRepeat}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        savedFields={formData.emails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <br/>

                    <Grid container spacing={0} style={{display: "flex", justifyContent: "space-between"}}>
                        <Typography className={new_schedule} alignLeft variant="h6" gutterBottom
                                    style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}>
                            {t('Mass import emails with excel file')}
                            <HelpIcon style={{color: "rgba(0, 0, 0, 0.87)", marginLeft: "8px", cursor: 'pointer'}}
                                      onClick={() => helpDrawerRef.current.toggleDrawer(true)}/>
                        </Typography>
                        <a href="/LiveOn User Send List Template.xlsx" target="_blank">{t('Download Template')}</a>
                    </Grid>

                    <form style={{textAlign: "left", marginBottom: '15px', marginLeft: '20px'}}>
                        <input
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                            style={{display: "none"}}
                        />
                        <label htmlFor="upload">
                            <Button component="span" classname="login_register_button_wrapper" as="span"
                                    variant="outlined" className="item" style={{
                                display: "block",
                                marginTop: "10px",
                                padding: "5px 40px",
                                borderRadius: "20px",
                                borderColor: "rgba(0,0,0,0.87)"
                            }}>
                                <Typography className="login_register_button" align="center" variant="h6"
                                            style={{fontWeight: "500", fontSize: "15px", marginBottom: 0}}
                                            color="rgba(0,0,0,0.87)" gutterBottom>
                                    {t('Upload File')}
                                </Typography>
                            </Button>
                        </label>
                    </form>

                    {
                        formData.xlsxUsers && formData.xlsxUsers.length > 0
                            ? <ReactDataGrid
                                theme='green-light'
                                onReady={setGridRef}
                                showCellBorders='horizontal'
                                rowHeight='auto'
                                nativeScroll={true}
                                rowStyle={rowStyle}
                                idProperty="__rowNum__"
                                style={gridStyle}
                                columns={[
                                    {name: 'name', defaultFlex: 2, header: 'Name'},
                                    {name: 'email', defaultFlex: 3, header: 'Email'},
                                    {
                                        name: '__rowNum__', header: ' ', minWidth: 50, defaultFlex: 1,
                                        render: ({value}) => {
                                            return (
                                                <div style={{textAlign: "right"}}>
                                                    <BoldTooltip title={t("Delete")} arrow><Button className="delete-button"
                                                                                                   size="medium"
                                                                                                   onClick={() => deleteRow(value)}
                                                                                                   height="50"
                                                                                                   color="secondary">
                                                        <ReactSVG beforeInjection={(svg) => {
                                                            svg.classList.add('svg-edit-button');
                                                            svg.setAttribute('style', 'width:29px');
                                                        }} src={constants.LocalUrl + edit_icon.delete}/>
                                                    </Button></BoldTooltip>
                                                </div>
                                            )
                                        }
                                    },

                                ]}
                                pagination
                                dataSource={formData.xlsxUsers}
                                defaultLimit={defaultPagination}
                                enableKeyboardNavigation={false}
                            />
                            : <ListItem button fullwidth>
                                <Grid container spacing={2} xs={12}>
                                    <Grid item xs={12}>{t('xlsxUsers empty file list')}</Grid>
                                </Grid>
                            </ListItem>
                    }
                </Grid>

                {
                    showPreview
                        ?
                        <Grid item xs={12}>
                            <br/>
                            <InvitationPreview previewData={props.invitation}></InvitationPreview>
                            <br/>
                        </Grid>
                        : null
                }

                <HelpDrawer id="send-list-xlsx" ref={helpDrawerRef}/>

                <PanelBottomBar close={props.triggerdrawer(false)} submit={onSubmitForm} switch={showPreview}
                                switchhandler={() => setShowPreview(!showPreview)}/>
            </div>
        </div>
    )

}
