import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import * as constants from "../../common/Constants";
import {Loader} from "semantic-ui-react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CTabsAutoScroll from "../../../common/CTabsAutoScroll";
import StatCard from "./components/StatCard";
import StatCardMini from "./components/StatCardMini";
import {Line} from "react-chartjs-2";
import LineChart from "../../../common/LineChartAttendance";
import {Tooltip} from 'react-tooltip'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
} from 'chart.js';

const icons = {
    questions: "/images/panel/problem.svg",
}

const useStyles = makeStyles({
    rootBlue: {
        width: '100%',
        background: '#17a2b8',
        color: '#FFFFFF'
    },
    rootGreen: {
        width: '100%',
        background: '#28a745',
        color: '#FFFFFF'
    },
    rootYellow: {
        width: '100%',
        background: '#ffc107',
        color: '#FFFFFF'
    },
    rootRed: {
        width: '100%',
        background: '#dc3545',
        color: '#FFFFFF'
    },
    rootWhite: {
        width: '100%',
        height: '244px',
        background: '#ffffff',
        color: '#000000',
        borderRadius: '10px',
        boxShadow: '0px 0px 2px 2px #5e5c9a1a',
    },
    rootWhiteBig: {
        width: '100%',
        height: '465px',
        background: '#ffffff',
        color: '#000000',
        borderRadius: '10px',
        boxShadow: '0px 0px 2px 2px #5e5c9a1a',
    },
    footerDimmed: {
        backgroundColor: 'rgba(0,0,0,.15)',
        padding: 0,
        alignItems: "center"
    },
    footerButton: {
        color: '#FFFFFF',
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
    },
    title_bold: {
        fontSize: 18,
        fontWeight: "bold"
    },
    title_semi: {
        fontSize: 20,
    },
    title_semi_bold: {
        fontSize: 20,
        fontWeight: "bold"
    },
    title_number: {
        fontWeight: "bold"
    },
    title_mini_head: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    border_right_gray: {
        borderRight: '2px solid #DBDBDB'
    },
    pos: {
        marginBottom: 12,
    },
    table: {
        minWidth: '100%',
        width: '100%',
    },
    mini_head: {
        backgroundColor: '#F2F2F2',
        borderRight: '2px solid #DBDBDB',
        padding: '15px !important',
        height: 90,
        width: '20%',
        maxWidth: '20%'
    },
    mini: {
        borderRight: '2px solid #DBDBDB',
        width: '20%',
        maxWidth: '20%'
    },
    mini_no_border_right: {
        width: '20%',
        maxWidth: '20%'
    },
    mini2_head: {
        backgroundColor: '#fee599',
        borderRight: '2px solid #DBDBDB',
        padding: '15px !important',
        height: 90,
    },
    mini2: {
        borderRight: '2px solid #DBDBDB',
    },
    mini2_no_border_right: {
    },
    user_image: {
        borderRadius: '50%',
        border: '2px solid #DBDBDB',
        maxWidth: '70%',
        marginBottom: 10
    },
});

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
);

export default function Dashboard() {

    const {t, i18n} = useTranslation();
    const [data, setData] = useState(null);

    var time = 15;
    var initialOffset = 50;
    var i = 0;



    const loadData = async () => {
        await axios.post(constants.LocalUrl + '/data/dashboardData').then(response => {
            setData(response.data);
        });
    }

    useEffect(() => {
        function handleStatusChange(status) { }

        loadData();
        // const reloadInterval = setInterval(() => {
        //     loadData();
        // }, 15000);
        const reloadInterval = setInterval(function() {
            // $('#clue').text(time-i); // here is the clue
            $('.circle_animation').css('stroke-dashoffset', initialOffset-((i+1)*(initialOffset/time)));
            i++;
            if (i>=time) {
                i=0;
                loadData();
            }
        }, 1000);

        return () => {
            clearInterval(reloadInterval);
        };
    }, []);

    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    if (data == null) {
        return <Loader></Loader>
    }

    const getFontSize = (textLength) => {
        const baseSize = 5
        if (textLength >= baseSize) {
            textLength = baseSize - 2
        }
        const fontSize = baseSize - textLength
        return `${fontSize}vw`
    }

    const tabs = {
        titles: [
            t('dashboard_tabs_tab1'),
            t('dashboard_tabs_tab2'),
            t('dashboard_tabs_tab3'),
        ],
        content: [
            <div style={{margin: -8}}>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ margin: 0, width: '100%'}}
                >
                    <Grid item xs={4}>
                        <Card className={classes.rootWhiteBig + " start_card_wrapper"} style={{backgroundColor: '#E2EFD9', border: '2px solid #D2D1D2'}}>
                            <CardContent>
                                <Grid container alignItems={'center'} spacing={2} direction={"column"}>
                                    <Grid item>
                                         <br/>
                                        <Typography variant="h3" component="h3" className={classes.title_number} align={'center'} style={{fontSize: '2em'}}>
                                            {data.info.event_name}
                                        </Typography>
                                        <br/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.title_semi_bold} gutterBottom align={'center'}>
                                            {data.info.event_date}
                                            <br/>
                                            {data.info.event_time}
                                        </Typography>
                                        <br/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.title} gutterBottom align={'center'}>
                                            {t('dashboard_event_days')}: {data.info.event_days} {t('days')}<br/>
                                            {t('dashboard_sessions_time_sum')}: {data.info.sessions_time_sum}
                                        </Typography>
                                        <br/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={8} style={{padding: 0}}>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            style={{ margin: 0, width: '100%'}}
                        >
                            <Grid item xs={4}>
                                <StatCard className={classes.rootWhite  + " start_card_wrapper"} style={{backgroundColor: '#FFF2CC', border: '2px solid #D2D1D2'}}
                                          number={data.users.users_registered} number_title={t('dashboard_users_registered')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StatCard className={classes.rootWhite  + " start_card_wrapper"} style={{backgroundColor: '#FBE5D5', border: '2px solid #D2D1D2'}}
                                          number={data.users.users_logged_in_at_least_once} number_title={t('dashboard_users_logged_in_at_least_once')}
                                          percentage={data.users.users_logged_in_at_least_once_perc} percentage_title={t('dashboard_users_logged_in_at_least_once_perc')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <StatCard className={classes.rootWhite  + " start_card_wrapper"} style={{backgroundColor: '#DAE2F3', border: '2px solid #D2D1D2'}}
                                          number={data.users.users_logged_in} number_title={t('dashboard_users_logged_in')}
                                          percentage={data.users.users_logged_in_perc} percentage_title={t('dashboard_users_logged_in_perc')}
                                />
                            </Grid>
                            <Grid className="panel_dashboard_list" item xs={12} style={{margin: 8}}>
                                <Grid container alignItems={'center'} spacing={2} direction={"row"}>
                                    <Grid  item xs className={classes.mini_head + " panel_dashboard_list_header"}>
                                        <Typography variant="h3" component="h3" className={classes.title_mini_head} align={'center'}>
                                            <span dangerouslySetInnerHTML={{__html: t('dashboard_streams_and_sessions')}}></span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.stage_streams_count} number_title={t('dashboard_stage_streams_count')} style={{height: 85}}/>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.sessions_count} number_title={t('dashboard_sessions_count')} style={{height: 85}}/>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.sessions_subscriptions_sum} number_title={t('dashboard_sessions_subscriptions_sum')} style={{height: 85}}/>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.speakers_coordinators_count} number_title={t('dashboard_speakers_coordinators_count')} style={{height: 85}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="panel_dashboard_list" item xs={12} style={{margin: 8}}>
                                <Grid container alignItems={'center'} spacing={2} direction={"row"}>
                                    <Grid item xs className={classes.mini_head  + " panel_dashboard_list_header"} justifyContent={'center'}>
                                        <Typography variant="h3" component="h3" className={classes.title_mini_head} align={'center'}>
                                            {t('dashboard_sponsors_count')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.sponsors_count} number_title={t('dashboard_sponsors_count')} style={{height: 85}}/>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.executives_count} number_title={t('dashboard_executives_count')} style={{height: 85}}/>
                                    </Grid>
                                    <Grid item xs className={classes.mini}>
                                        <StatCardMini number={data.info.sponsorfiles_count} number_title={t('dashboard_sponsorfiles_count')} style={{height: 85}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.rootWhite} style={{display: "flex", flexDirection: "column", backgroundColor: '#f2f2f2', padding: '10px 20px', border: '2px solid #D2D1D2', height: "auto"}}>
                            <Typography variant="h3" component="h3" className={classes.title_semi_bold} style={{marginBottom: 12}}>
                                {t('dashboard_latest_registered_users')}
                            </Typography>
                            <Grid
                                className="latest_registered_users_dashboard"
                                container
                                direction="row"
                                spacing={1}
                                style={{ margin: 0, width: '100%'}}
                            >
                                {Object.values(data.users.latest_registered).map( (user) => <Grid item xs style={{display: "flex", flexDirection: "column", justifyItems: "center", alignItems: "center", textAlign: "center", maxWidth: '16.6%'}}>
                                    <img className={classes.user_image} src={user.user_img} alt={user.name} />
                                    <Typography variant="h6" component="h6" className={classes.title} style={{lineHeight: '18px', marginTop: 4}}>
                                        {user.name}
                                    </Typography>
                                    <Typography component="p" style={{marginTop: 4}}>
                                        {user.created_at_ago}
                                    </Typography>
                                </Grid>)}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>,
            <div>

                {/*<Line options={{*/}
                {/*    scales: {*/}
                {/*        y: {*/}
                {/*            min: 0,*/}
                {/*            ticks: {*/}
                {/*                // forces step size to be 50 units*/}
                {/*                stepSize: 1*/}
                {/*            }*/}
                {/*        }*/}
                {/*    },*/}
                {/*    responsive: true,*/}
                {/*    plugins: {*/}
                {/*        legend: {*/}
                {/*            position: 'top',*/}
                {/*        },*/}
                {/*        title: {*/}
                {/*            display: true,*/}
                {/*            text: 'Chart.js Line Chart',*/}
                {/*        },*/}
                {/*    },*/}
                {/*}} data={data.graphs.attendance} />*/}
                <LineChart data={data.graphs.attendance} />
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ margin: 0, width: '100%'}}
                >
                    <Grid item xs={6}>
                        <StatCard className={classes.rootWhite} style={{backgroundColor: '#FFF2CC', border: '2px solid #D2D1D2', height: 'auto'}}
                                  number={data.stage.stage_attending_now} number_title={t('dashboard_stage_attending_now')} show_percentage={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StatCard className={classes.rootWhite} style={{backgroundColor: '#DAE2F3', border: '2px solid #D2D1D2', height: 'auto'}}
                                  number={data.users.stage_attendance_avg} number_title={t('dashboard_stage_attendance_avg')} show_percentage={false}
                        />
                    </Grid>
                </Grid>
            </div>,
            <div>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ margin: 0, width: '100%'}}
                >
                    <Grid item xs={3}>
                        <StatCard className={classes.rootWhite} style={{backgroundColor: '#fbe5d5', border: '2px solid #D2D1D2', height: 305}}
                                  // icon={<ReactSVG beforeInjection={(svg) => {
                                  //     svg.classList.add('svg-class-name');
                                  //     svg.setAttribute('style', 'width:60px');
                                  //     svg.setAttribute('fill', '#000000');
                                  // }} src={constants.LocalUrl + icons.questions} />}
                                  icon={<img src="/images/panel/dashboard/question.png" width='60'/>}
                                  number={data.stage.stage_questions_asked} number_title={t('dashboard_stage_questions_asked')} show_percentage={false}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <div className={classes.rootWhite} style={{backgroundColor: '#fbe5d5', border: '2px solid #D2D1D2', padding: '10px 30px', height: 305}}>
                            <Typography variant="h3" component="h3" className={classes.title_semi_bold} style={{marginBottom: 30}}>
                                {t('dashboard_sponsors_count')}
                            </Typography>
                            <Grid container alignItems={'center'} spacing={2} direction={"row"} style={{marginBottom: 30}}>
                                <Grid item xs={3} className={classes.mini2_head} style={{height: 130, display: "flex", padding: '0 !important', alignItems: "center", justifyContent: "center"}}>
                                    <div style={{display: "flex", flexDirection: 'row', alignItems: "center", width: '90%', justifyContent: "space-evenly"}}>
                                        <img src="/images/panel/dashboard/visit.png" width='60'/>
                                        <StatCardMini number={data.stage.sponsor_visits_total} number_title={t('dashboard_sponsor_visits_total')} number_font_size={'2.2rem'} />
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={classes.mini2} style={{height: 130}}>
                                    <div style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                                        <img src="/images/panel/dashboard/business-card.png" width='60'/>
                                        <StatCardMini number={data.stage.sponsor_connects_total} number_title={t('dashboard_sponsor_connects_total')} number_font_size={'2.2rem'} />
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={classes.mini2} style={{height: 130}}>
                                    <div style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                                        <img src="/images/panel/dashboard/chat.png" width='60' style={{padding: 14}}/>
                                        <StatCardMini number={data.stage.sponsor_chats_total} number_title={t('dashboard_sponsor_chats_total')} number_font_size={'2.2rem'} />
                                    </div>
                                </Grid>
                                <Grid item xs={3} className={classes.mini2} style={{height: 130}}>
                                    <div style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                                        <img src="/images/panel/dashboard/video-call.png" width='60' style={{padding: 14}}/>
                                        <StatCardMini number={data.stage.sponsors_videocalls_total} number_title={t('dashboard_sponsors_videocalls_total')} number_font_size={'2.2rem'} />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} spacing={2} direction={"row"}>
                                <Grid item xs={6} className={classes.mini2}>
                                    <Grid container alignItems={'center'} spacing={2} direction={"row"} justifyContent={'flex-end'}>
                                        <Grid item>
                                            <img src="/images/panel/dashboard/file-view.png" width='60' style={{padding: 10}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" component="h3" className={classes.title_number} align={'center'} style={{fontSize: '2.2rem'}}>
                                                {data.stage.sponsorfiles_views_total}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography component="p" className={classes.title} align={'center'} style={{fontSize: '15px', textAlign: "left", maxWidth: 100}}>
                                                {t('dashboard_sponsorfiles_views_total')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} className={classes.mini2_no_border_right}>
                                    <Grid container alignItems={'center'} spacing={2} direction={"row"} justifyContent={'flex-start'}>
                                        <Grid item>
                                            <img src="/images/panel/dashboard/briefcase.png" width='60' style={{padding: 10}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" component="h3" className={classes.title_number} align={'center'} style={{fontSize: '2.2rem'}}>
                                                {data.stage.sponsorfiles_briefcase_total}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography component="p" className={classes.title} align={'center'} style={{fontSize: '15px', textAlign: "left", maxWidth: 100}}>
                                                {t('dashboard_sponsorfiles_briefcase_total')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container alignItems={'center'} spacing={2}>
                                <br/>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>,
        ],
    };

    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"658px", height:"658px", overflowX: "hidden"}}>
            <div style={{position: "absolute", right:10, top:9}}>
                <Tooltip anchorId="reloadTimer" content="When the timer ends, the data will be refreshed automatically"
                         place="left"/>
                <span id="reloadTimer" className='btn-group float-right autoreload' >
                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle_hint" r="8" cy="15" cx="15" strokeWidth="7" stroke="#eee" fill="none"/>
                    <circle id="circle" className="circle_animation" r="8" cy="15" cx="15" strokeWidth="6"
                            stroke="#6fdb6f" fill="none"/>
                </svg>
            </span>
            </div>
            <CTabsAutoScroll titles={tabs.titles} contents={tabs.content}></CTabsAutoScroll>
        </SimpleBar>
    );

}

