import React, {useEffect, useState} from 'react';
import * as constants from "../../../../common/Constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Editor from "../../../../common/Editor";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ReactSVG} from "react-svg";
import LanguageChangerForPanel from "../../../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../../../common/Loader";
import PanelBottomBar from "../../../../../common/PanelBottomBar";
import Divider from "@material-ui/core/Divider";


export default function NewPageLink(props) {

    const {t, i18n} = useTranslation();
    const [pageData, setPageData] = useState([]);
    const [pageDataLocale, setPageDataLocale] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [editorContent, setEditorContent] = useState(null);

    const changeLocale = (lang) => {
        setEditorContent(pageDataLocale.content);
        setLocaleLanguage(lang);
    }

    useEffect(() => {
        setLocaleData(pageData);
    },[localeLanguage])


    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null && value != "draggable_executives" && value != "files" && value != "executives"){
                console.log("a",value,data[value])
                localeData[value] = data[value][localeLanguage];
            }else{
                // setSponsorLocale([value]:data[value])
                console.log("b",value,data[value])
                localeData[value] = data[value];
            }
        })
        console.log("localeData",localeData,localeLanguage)
        setPageDataLocale(localeData);
        setEditorContent(localeData.content);
    }


    const handlePage = (event) => {
        event.persist();

            setPageData((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));


        setPageDataLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        console.log(pageData,pageDataLocale)
    }

    const handlePageDescription = (data) => {

        console.log("handlePageDescription",data)
        setPageDataLocale((prevState) => ({
            ...prevState,
            content: data,
        }));

        setPageData((prevState) => ({
            ...prevState,
            ['content']:{
                ...prevState['content'],
                [localeLanguage]:data,
            }
        }));

    }


    useEffect(() => {
        axios.post(
            constants.LocalUrl + '/data/get_page_data', {id:props.page_id,type:"panel"}).then(response => {


                setPageData(response.data.data);
                setLocaleData(response.data.data)

            console.log("response",response)
        });
    },[])

    const onSubmitForm = () => {
        // setLocaleData(pageData)
        console.log()
        // const loadItems = async () => {
        axios.post(
            constants.LocalUrl + '/data/save_page_data', {id:props.page_id,lang:localeLanguage,data:pageDataLocale}).then(response => {

            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            }
            else if (response.data.status == 'ok') {
                let index_id;
                const i = props.posts.map((single_post,index) =>  {
                        if(single_post.id === response.data.data.id){
                            index_id = index;
                            return true;
                        }
                    }
                );

                let items = [...props.posts];
                let item = {...items[index_id]};
                item.title = response.data.data.title;
                items[index_id] = item;

                props.triggerdataload(response.data.data);
                // props.triggerdrawerfunc(false);
            }
            console.log(response)
        });

    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    if(Object.keys(pageData).length === 0){
        return <Loader />
    }

    return (
        <div>
            <Typography aligncenter variant="h3" style={{textAlign: 'center'}} gutterBottom>
                {props.page_id == null?t('Create_page'):t('Update_page')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>

            <Grid container spacing={4} style={{display:'flex',justifyContent:'center',transition:'1s all',padding: '20px',maxWidth:'800px'}}>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{display: 'block',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                        {t('Page_Title')}:
                    </Typography>
                    <TextField
                        id="outlined-textarea"

                        placeholder=""
                        defaultValue=""
                        style={{width: '100%'}}
                        name='title'
                        onChange={handlePage}
                        value={pageDataLocale.title??[]}
                        variant="outlined"
                        required error={hasError('title')} helperText={errorMessage('title')}
                    />
                    <br/><br/>
                    <Editor lang={localeLanguage} handler={handlePageDescription} label={t("Page_Content")+':'} value={editorContent??""} name="content"/>
                </Grid>

                <PanelBottomBar classes={"panel_utilities_element basicinfo"} close={props.triggerdrawer(false)} style={{justifyContent:"space-between"}} submit={onSubmitForm} language={changeLocale}/>

                {/*<Grid className="panel_utilities_element" item xs={12}>*/}
                {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                {/*    <div className="right_part">*/}

                {/*        {props.page_id !== null?<LanguageChangerForPanel onChange={setLocaleLanguage}/>:null}*/}
                {/*        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t("Save")}</Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    )
}
