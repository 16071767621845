import React, {useContext, useEffect} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import * as constants from "../../Panel/common/Constants"
import {useTranslation} from "react-i18next";
import {Menu} from "semantic-ui-react";
import {ReactSVG} from "react-svg";
import PageProvider from "../../Page/PageContext";

var menu_icons = {
    language: "/images/language.svg",
};

export default function LanguageSelectorForMenu(props) {

    const {t, i18n} = useTranslation();
    const {settings, setReloadData} = useContext(PageProvider);

    useEffect(() => {
        const load = async () => {
        }
        load();
    }, []);

    return <>
        {settings.locale && settings.languages && Object.keys(settings.languages).length > 1 ?
            <Menu.Item as="a" className="settings_platform" position='right'>
                <ReactSVG beforeInjection={(svg) => {
                    svg.classList.add('svg-class-name');
                    svg.setAttribute('style', 'width:27px');
                }} src={constants.LocalUrl + menu_icons.language}/>
                {settings.languages[settings.locale]}
                {Object.keys(settings.languages).length > 1 ? <div className="submenu">
                    <ul>
                        {Object.keys(settings.languages).map((lang) => (lang != settings.locale ? <li>
                                <a href={"/locale/" + lang}>{settings.languages[lang]}</a>
                            </li> : null))}

                    </ul>
                </div> : null}
            </Menu.Item> : null}
    </>;

}

