import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Segment,} from 'semantic-ui-react'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as constants from "../../../Panel/common/Constants";
import Loader from "../../../common/Loader";
import {ReactSVG} from "react-svg";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import CloseIcon from '@material-ui/icons/Close';
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import MenuIcon from "@material-ui/icons/Menu";
import StageOndemandList from "./StageOndemandList";
import StageVideo from "./StageVideo";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

function StageIndividualSessionCard (props){

    const {t, i18n} = useTranslation();
    const [stage,setStage] = useState(null);
    const [currentSession,setCurrentSession] = useState(null);
    const [listens, setListens] = useState(false);

    useEffect(() => {
        const loadPost = () => {
            axios.post(constants.LocalUrl + "/data/get_stage_current_session").then((response) => {
                setStage(response.data.stage);
                setCurrentSession(response.data.current_session);
            });
        }
        // Call the function
        loadPost();
        if (!listens) {
            window.channel_stage.listen('.stage.change_card', (data) => {
                console.log('listened .stage.change_card');
                setStage(data.stage);
                setCurrentSession(data.current_session);
            });
            setListens(true);
        }
    }, []);

    if (stage == null) {
        return <Loader/>
    }

    return <>
        {
            currentSession == null
                ? <>

                </>
                : <>
                    <h6 className="card-title" style={{fontSize: '14px', fontWeight: 700}}>{currentSession.title}</h6>
                    <p className="card-text" style={{fontWeight: 500, fontSize: '12px', paddingBottom: '10px', borderBottom: '0.5px solid grey'}} dangerouslySetInnerHTML={{__html:  currentSession?currentSession.subtitle:""}}/>
                </>
        }
        {
            currentSession != null && currentSession.omilites
                ? <>
                    {currentSession.omilites.length > 0 ? <>
                        <h6 style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 700, paddingBottom: '10px'}}>{t('Speakers')}</h6>
                        {currentSession.omilites && Object.values(currentSession.omilites).map(user => (
                            <div>

                                <NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>
                                <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                            </div>
                        ))}
                    </> : <></> }
                </>
                : <>
                </>
        }
        {
            currentSession != null && currentSession.syntonistes
                ? <>
                    {currentSession.syntonistes.length > 0 ? <>
                        <h6 style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 700, paddingBottom: '10px'}}>{t('Coordinators')}</h6>
                        {currentSession.syntonistes && Object.values(currentSession.syntonistes).map(user => (
                            <div>
                                <NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>
                                <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                            </div>
                        ))}
                    </> : null }
                </>
                : null
        }
    </>;

}

export default function StageIndividual() {

    var icons = {
        stage:"/images/menu_stage.svg",
    };

    const {t, i18n} = useTranslation();
    const [loading,setLoading] = useState(false);
    const [sponsors,setSponsors] = useState([]);
    // const [hosts,setHosts] = useState([]);
    const [questions,setQuestions] = useState([]);
    const [question,setQuestion] = useState("");
    const [stage,setStage] = useState(null);
    const [stageStatus,setStageStatus] = useState(null);
    const [streamcode,setStreamcode] = useState(0);
    const [code,setCode] = useState(null);

    const [listensStatus,setListensStatus] = useState(null);
    const [demandSession,setDemandSession] = useState(null);
    const [listensQuestions,setListensQuestions] = useState(null);
    const [showMini,setShowMini] = useState(false);
    const [hasMini,setHasMini] = useState(false);
    const [openMinidial,setOpenMinidial] = useState(false);
    const [position,setPosition] = useState(false);

    const useStyles = makeStyles((theme) => ({
        speedDial: {
            position: 'fixed',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
        },
    }));
    const classes = useStyles();

    const actions = [
        { icon: <MinimizeIcon/>, name: 'Minimize' },
        { icon: <MaximizeIcon/>, name: 'Maximize' },
        { icon: <CloseIcon/>, name: 'Close' },
        { icon: <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:20px');}} src={constants.LocalUrl + icons.stage} />, name: 'Back to stage' },
    ];

    const actionShow = (action_name) => {
        if (action_name == actions[0].name) { return showMini; }
        else if (action_name == actions[1].name) { return !showMini }
        else if (action_name == actions[2].name) { return true; }
        else if (action_name == actions[3].name) { return true; }
        else return true;
    }

    const navigate = useNavigate();
    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }
    var location = usePathname();
    var strArray = location.split("/");
    var lposition = strArray[strArray.length - 1];

    const notify = () => toast.success(t('Your message has been received!'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const false_notify = () => toast.warn(t('Something went wrong!'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    useEffect(() => {
        if (!hasMini && lposition == 'stage') {
            setHasMini(true);
            setShowMini(true);
        }
        setPosition(lposition);
    }, [lposition]);

    useEffect(() => {

        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + "/data/get_stage_sponsors");


            // After fetching data stored it in posts state. 
            setStage(response.data.stage);
            setStageStatus(response.data.stage.status);
            setSponsors(response.data.sponsors);
            console.log("response.data.sponsors",response.data.sponsors)

            // setHosts(response.data.hosts);
            setCode(response.data.stage.stream_code)
            const questions = await axios.post(
                constants.LocalUrl + "/data/get_questions");

            let t_questions = questions.data;
            t_questions = t_questions.sort(sortQuestionsByDate);
            setQuestions(t_questions);
            // Closed the loading page
            setLoading(false);
        }
        // Call the function
        loadPost();

        if (!listensStatus) {
            window.channel_stage.listen('.stage.change_status', (data) => {
                console.log('listened .stage.change_status');
                setStage(data.all.stage);
                setStageStatus(data.all.stage.status);
            });
            setListensStatus(true);
        }
        if (!listensQuestions) {
            window.channel_stage.listen('.stage.reload_questions', (data) => {
                console.log('listened .stage.reload_questions');
                let t_questions = data.questions;
                t_questions = t_questions.sort(sortQuestionsByDate);
                setQuestions(t_questions);
            });
            setListensQuestions(true);
        }
    }, []);

    const handleQuestionData = (event) =>  {
        setQuestion(event.target.value);
    }

    const sortQuestionsByDate = (a, b) => {
        return new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime();
    }

    const toggleChildMenu = (attached,sponsor_id) => () => {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
        console.log(drawerstate);
    };
    const sendQuestion = () => {
        window.axios.post(constants.LocalUrl + '/data/save_question',{question:question}).then((response) => {
            notify()
            let t_questions = response.data;
            t_questions = t_questions.sort(sortQuestionsByDate);
            setQuestions(t_questions);
        }).catch(error => {
            false_notify()
        })

        setQuestion('');
    }

    const changeStream = (event) => {
        setStreamcode(event.target.attributes.stream.nodeValue)
        console.log(event.target.attributes.stream.nodeValue)
    }

    const changeDemandSession = (demandSession) => {
        setDemandSession(demandSession)
    }


    const handleOpenMinidial = () => {
        setOpenMinidial(!openMinidial);
    };

    const handleMinidialAction = (e, action_name) => {
        if (action_name == actions[0].name) { setShowMini(false) }
        else if (action_name == actions[1].name) { setShowMini(true) }
        else if (action_name == actions[2].name) { setHasMini(false); setShowMini(false); setOpenMinidial(false); }
        else if (action_name == actions[3].name) { navigate('/stage') }
    };


    if(loading === true && code === null){
        return  <Loader/>
    }

    return (

        <>

            {
                stageStatus && stageStatus == 1 && hasMini && position != 'stage'
                    ? <SpeedDial
                        ariaLabel="SpeedDial openIcon example"
                        className={classes.speedDial}
                        hidden={false}
                        icon={<MenuIcon/>}
                        onClose={handleOpenMinidial}
                        onOpen={handleOpenMinidial}
                        open={openMinidial}
                        direction={"right"}
                        FabProps={{
                            color: 'secondary'
                        }}
                    >
                        {actions.filter((action) => actionShow(action.name)).map((action) => (
                            <SpeedDialAction
                                open={actionShow(action.name)}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipPlacement={"top"}
                                onClick={(e)=>handleMinidialAction(e, action.name)}
                            />
                        ))}
                    </SpeedDial>
                    : <></>
            }

            <div className="stage" data-status={stageStatus} data-showmini={hasMini && showMini?'y':'n'} style={{padding:'20px 2.2% 0',width:'100%',minWidth:"1130px",margin:"0 auto",backgroundColor:"#fafafa",paddingBottom:"30px"}}>
                <Segment style={{ padding: '0em 0em' }} vertical>
                    <div className="row" style={{justifyContent:'space-between'}}>
                        <div className="left-column" style={{flex:'0 0 18%'}}>
                            <div className="card left-top" style={{borderRadius:'20px',marginBottom:'20px'}}>
                                <div className="card-header" style={{borderBottom: '1px solid var(--divider-color)', backgroundColor: '#ffffff',borderRadius:'16px 16px 0 0'}}>
                                    <ul className="nav nav-tabs card-header-tabs left-top-header" style={{backgroundColor: '#ffffff'}}>
                                        {stage && Object.values(stage['stream_code']).map((stream,key) => (
                                            <li className={key==0?'nav-item active':'nav-item'}><a className="nav-link" stream={key} onClick={changeStream} href="#" style={{color: 'black', fontWeight: 700, margin: '0px 10px 10px 0px', borderBottom: 'initial', borderLeft: 'none', borderTop: 'none', backgroundColor: '#ffffff',fontSize:'18px'}}>{stream['name']}</a></li>
                                        ))}

                                    </ul>
                                </div>
                                <div className={stage && stage.isarchived?"card-body archived":"card-body"}>
                                    {stage && stage.isarchived
                                        ? <StageOndemandList streamcode={streamcode} sessionHandler={changeDemandSession} currentSession={demandSession}></StageOndemandList>
                                        : <StageIndividualSessionCard></StageIndividualSessionCard>
                                    }
                                </div>
                            </div>

                            {stageStatus != null && stageStatus == 1 && stage.has_questions_form == 1
                                    ? <>
                                        <div className="card left-top" style={{borderRadius:'20px',marginBottom:'20px'}}>
                                            <div className="card-header" style={{borderBottom: '1px solid var(--divider-color)', backgroundColor: '#ffffff',borderRadius:'16px 16px 0 0'}}>
                                                <ul className="nav nav-tabs card-header-tabs left-top-header">
                                                    <li className="nav-item">
                                                        <h6 style={{fontSize: '14px', lineHeight: '23px', color: '#000000', fontWeight: 700, margin: '10px'}}>{t('Questions')}</h6>
                                                    </li>
                                                    <li className="nav-item" />
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                {questions && Object.values(questions).map(q => (
                                                    <div style={{paddingLeft: '10px', borderLeft: '3px solid #EFC000'}}>
                                                        <h6 style={{fontSize: '10px', lineHeight: '16px', color: '#8e8e8e', fontWeight: 500, marginBottom: 'auto'}}>{q.sent_at}</h6>
                                                        {q.from_user.rolenames.includes('Coordinator') || q.from_user.rolenames.includes('Speaker')?<NavLink className="item" style={{fontSize: '14px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}
                                                                                                                          to={constants.LocalUrl + "/profile/" + q.from_user.id}>{q.name}</NavLink>:<span style={{fontSize: '14px', lineHeight: '16px', color: '#000', fontWeight: 500, textDecoration: 'none'}}>{q.name}</span>}
                                                        <p style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 400}}>{q.message}</p>
                                                        <hr/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                        <div className={stage && (stage.status == 1 || stage.status == 9)?"center-column stage_wrapper live":"center-column stage_wrapper"} style={{flex:'0 0 62%'}}>
                            <div className="card">
                                {/*<div className="card-header" style={{borderBottom: '1px solid var(--divider-color)'}}>*/}
                                {/*    <ul className="nav nav-tabs card-header-tabs" style={{display: 'none'}}>*/}
                                {/*        <li className="nav-item"><a className="nav-link active" href="#" style={{margin: '0px 10px 10px 0px', borderBottom: 'initial', borderLeft: 'none', borderTop: 'none', backgroundColor: '#F9F9F9'}}>Stage 1</a></li>*/}
                                {/*        <li className="nav-item"><a className="nav-link" href="#">Stage 2</a></li>*/}
                                {/*        <li className="nav-item" />*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                <div className="card-body" style={{padding: 'initial'}}>
                                    { ((hasMini && showMini) || position == 'stage') ? <StageVideo video={streamcode} streams={code} stage={stage} demandSession={demandSession} style={{width:'100%'}}/> : null }
                                </div>
                            </div>
                            {
                                stageStatus != null && stageStatus == 1 && stage.has_questions_form == 1
                                    ? <>
                                        <div className="payer-info" style={{display: 'flex', padding: '23px 36px',backgroundColor:"transparent"}}><img style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"} />
                                            <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}} dangerouslySetInnerHTML={{__html:  stage?stage.msg_live:""}}/><br />
                                        </div>
                                        <div className="send-questions" style={{margin:"10px 0 0 0", padding: '10px 19px', width: '100%', display: 'flex', backgroundColor: 'white', borderRadius: '15px', border: '1px solid #EFC000',boxShadow:'-5px 3px 40px #5e5c9a1a'}}>
                                            <input onChange={handleQuestionData} value={question} type="text" style={{borderStyle: 'none', width: 'inherit'}} placeholder={t("You_can_ask_anything_here")} />
                                            <button onClick={sendQuestion} className="btn btn-primary" type="button" style={{fontSize: '15px', lineHeight: '24px', color: '#ffffff', fontWeight: 600, borderStyle: 'none', backgroundColor: '#EFC000', margin: '10px', borderRadius: '50px', paddingRight: '30px', paddingLeft: '30px'}}>{t('SEND')}</button>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                stageStatus != null && stageStatus == 2
                                    ? <>
                                        <div className="payer-info" style={{display: 'flex', padding: '23px 36px',backgroundColor:"transparent"}}><img style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"} />
                                            <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}} dangerouslySetInnerHTML={{__html:  stage?stage.msg_ended:""}}/><br />
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="right-column" style={{flex:'0 0 18%'}}>
                            {console.log("props.sponsors",sponsors)}
                            {sponsors && Object.values(sponsors).map((sponsor_cat,key) => (
                                <div className="card" style={{borderRadius:'20px',marginBottom:'20px',paddingBottom:'17px'}}>
                                    <h6 className="card-title" style={{fontSize: '10px', textAlign: 'center', fontWeight:'700',marginTop:'10px'}}>{sponsor_cat.category_name}</h6>
                                    {/*{sponsor_cat && Object.values(sponsor_cat.data).map((sponsor,key) => (*/}

                                        <NavLink as="a" to={constants.LocalUrl + "/sponsors/" + sponsor_cat.id} style={{textAlign:"center", fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>
                                            <img style={{width:'100%'}} src={sponsor_cat.logo}/>
                                        </NavLink>
                                    {/*))}*/}
                                </div>
                            ))}

                        </div>
                    </div>
                </Segment>
            </div>

        </>

    );

}

$(document).on("click",".left-column .card-header .nav-tabs .nav-item", function(){
    $(".left-column .card-header .nav-tabs .nav-item").removeClass('active');
    $(this).addClass('active');
});