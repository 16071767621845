import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import PageProvider from "../../Page/PageContext";
import {CircularProgress} from "@material-ui/core";
import {ReactSVG} from 'react-svg';
import LiveonChatDropzone from "./LiveonChatDropzone";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const chat_icons = {
    CloseIcon: "/images/panel/close_icon.svg",
    CameraIcon: "/images/panel/camera_icon.svg",
    MinimizeIcon: "/images/panel/minimize_icon.svg",
    MaximizeIcon: "/images/panel/maximize_window_icon.svg",
    SendMessageIcon: "/images/panel/send_message_icon.svg",
}

export default function LiveonChatWindow(props) {
    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();

    const messagesEndRef = useRef(null)

    const [myuser, setMyuser] = useState(null);
    const [show, setShow] = useState(true);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [hasNewMessage, setHasNewMessage] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [channel, setChannel] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const {putVideoCall, setPutVideoCall} = useContext(PageProvider);

    useEffect(() => {

        if (!channel) {
            console.warn('Connecting to ws channel ' + props.conversation.channel_id);
            let ch = window.Echo.join(props.conversation.channel_id)
                .here(users => {
                    // this.channelUsers = users;
                    // console.log('here');
                    // this.channel.whisper('typing', {
                    //     typing: true
                    // });
                })
                .joining(user => {
                    // this.channelUsers.push(user);
                })
                .leaving(user => {
                    // console.log('leaving');
                    // this.channelUsers = this.channelUsers.filter(u => u.id !== user.id);
                })
                .listenForWhisper('typing', ({typingData}) => {
                    console.log('listenForWhisper::typing=' + typingData);

                    /*this.users.forEach((user, index) => {
                        if (user.id === id) {
                            user.typing = true;
                            this.$set(this.users, index, user);
                        }
                    });*/
                })
                .listen('.meeting-chat.message-sent', (data) => {
                    setMessageList((messageList) => [...messageList, data.message]);
                    notify();
                });
            setChannel(ch);
        }

        setMyuser(settings.user);
        axios.post(constants.LocalUrl + '/data/chat_get_user_conversation_messages', {recipient_id: props.user_id})
            .then(res => {
                const messageList = res.data;
                setMessageList(messageList);
            })
        setHasNewMessage( props.conversation.last_message[window.temp_user_id] && props.conversation.last_message[window.temp_user_id].status == 'New' );
    }, []);

    useEffect(() => {
        let incomingMessages = messageList.filter((e) => { return e.to_user.id == window.temp_user_id })
        if (incomingMessages && incomingMessages[incomingMessages.length-1]) {
            setHasNewMessage( incomingMessages[incomingMessages.length-1].status == 'New' );
        }
        scrollToBottom();
    }, [messageList])

    const handleVideoCall = (data, uid) => {
        // if (data === true) {
        //     setPutVideoCall({activate: true, uid: uid})
        // }
        axios.post(constants.LocalUrl + '/data/video_call_notify', {from: window.temp_user_id, to: uid, type: 'start'}).then(() => {
            props.closeHandler(props.user_id);
        });
    }

    const toggleWindow = () => {
        setShow(!show);
    }

    const toggleInfoWindow = () => {
        setShowInfoWindow(!showInfoWindow);
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    const handleNewMessage = (e) => {
        setNewMessage(e.target.value);
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            sendMessage(newMessage);
        }
    }

    const sendMessage = (answer, stripHtml = true) => {
        // var answer = newMessage;
        setNewMessage('');

        if (stripHtml == true) {
            answer = strip(answer).trim();
        }

        if (answer != '') {

            var mode = "/chat/send";
            mode = mode.replace("0", "");
            axios.post(constants.LocalUrl + mode, {
                msg: answer,
                from: myuser.id,
                to: props.user_id,
                room_id: null,
                channel_id: props.conversation.channel_id,
                broadcast: true
            }).then((response) => {
                // liveOn.track('Networking','Chat message sent');
                setMessageList((messageList) => [...messageList, response.data]);
            });
        }
    }

    const sendFileMessage = (fileMessage) => {
        sendMessage(fileMessage, false);
    }

    const strip = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const markAllRead = () => {
        // if (hasNewMessage) {
        axios.post(constants.LocalUrl + '/data/chat_mark_user_conversation_messages_read' , {
            from: props.user_id,
        }).then((response) => {
            // liveOn.track('Networking','Chat message sent');
            setMessageList(response.data);
        });
        // }
    }

    const notify = () => {
        if (document.getElementById('chatNotification') != null) {
            document.getElementById('chatNotification').play()
        }
        // console.warn('NEW MESSAGE!');
    }

    function get_date(timestamp) {
        var start = new Date(timestamp * 1000);
        var start_time = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
        return start_time;
    }

    const holdFilename = (filename) => {
        console.log("filename")
        console.log(filename)
    };

    if (myuser)
        return (

            <div className={ (show ? 'chat-window' : 'chat-window minimized') + (hasNewMessage ? ' new-badge' : '') } data-status={hasNewMessage ? 'New' : 'Opened' } >
                <div className="chat-window-header">
                    <div className="chat-window-recipient">
                        <div className="chat-window-recipient-img">
                            <img src={props.conversation.other_user ? props.conversation.other_user.user_img : ""}
                                 className="face"/>
                            <span className="person-status  person-offline "></span>
                        </div>
                        <div className="chat-window-recipient-info" onClick={toggleInfoWindow}>
                            <b>{props.conversation.other_user.name} <i className="">▼</i></b>
                            <span title=""></span>
                        </div>
                    </div>
                    <div className="chat-window-tools">
                        <a className="chat-window-minimize" onClick={toggleWindow}>
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                            }} src={chat_icons.MinimizeIcon}/>
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                            }} src={chat_icons.MaximizeIcon}/>
                        </a>
                        <a className="chat-window-close" onClick={() => props.closeHandler(props.user_id)}>
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                                // svg.setAttribute('style', 'width:55px');
                            }} src={chat_icons.CloseIcon}/>
                        </a>
                    </div>
                </div>
                <div className="chat-window-body dz-clickable" id={"body" + props.user_id}>
                    {
                        messageList.map(message => <>
                            {
                                message.message == 'first_message_intro_text'
                                ? <>{ messageList.length == 1
                                        ? <div style={{textAlign: 'center'} }>
                                            <br/>
                                            <p>
                                                {
                                                    myuser.id == message.from_user.id
                                                    ? t('new_chat_request_sender')
                                                    : t('new_chat_request_recipient', {fullname: message.from_user.name})
                                                }
                                            </p>
                                            <hr/>
                                        </div>
                                        : null
                                }</>
                                : <div key={message.id}
                                       className={myuser.id == message.from_user.id ? 'bubble bg-teal pull-right' : 'bubble bg-teal pull-left'}>
                                        <em>{message.from_user.name} @ {get_date(message.sent_at)}
                                        </em>
                                        <div
                                            className={myuser.id == message.from_user.id ? "d-flex  justify-content-end  align-items-end" : "d-flex  justify-content-start  align-items-end"}>
                                            {myuser.id == message.from_user.id ? <div>
                                                    <span dangerouslySetInnerHTML={{__html: message.message}}/>
                                                    <img className="face"
                                                         src={message.from_user.user_img}
                                                         style={{marginLeft: "10px"}}
                                                    />
                                                </div>
                                                : <div>
                                                    <img className="face"
                                                         src={message.from_user.user_img}
                                                         style={{marginRight: "10px"}}
                                                    />
                                                    <span dangerouslySetInnerHTML={{__html: message.message}}/>
                                                </div>}

                                        </div>
                                    </div>
                            }
                        </>)
                    }
                    <div className="pull-right" ref={messagesEndRef}/>
                </div>
                <div className="chat-window-footer">
                    <a onClick={() => handleVideoCall(true, props.user_id)}
                       className="videochat chat-window-videocall">
                        <ReactSVG beforeInjection={(svg) => {
                            svg.classList.add('svg-class-name');
                            // svg.setAttribute('style', 'width:55px');
                        }} src={chat_icons.CameraIcon}/>
                    </a>
                    <input onChange={handleNewMessage}
                           // onKeyPress={() => {
                           //     channel.whisper('typing', {typing: true});
                           // }}
                           onClick={() => {
                               markAllRead();
                           }}
                           className="chat-window-user-msg" value={newMessage}/>
                    <LiveonChatDropzone forFileUpload={sendFileMessage} room_id={props.conversation.channel_id}></LiveonChatDropzone>
                    <a className="chat-window-send-msg" onClick={() => sendMessage(newMessage, true)}>
                        <ReactSVG beforeInjection={(svg) => {
                            svg.classList.add('svg-class-name');
                            // svg.setAttribute('style', 'width:55px');
                        }} src={chat_icons.SendMessageIcon}/>
                    </a>
                </div>
                <div className="chat-window-user-menu" style={showInfoWindow ? {display: "block"} : {display: "none"}}>
                    <div className="text-center mb-2 d-flex justify-content-center">
                        <img src={props.conversation.other_user ? props.conversation.other_user.user_img : ""} className="face"/>
                    </div>
                    <div className="clearfix mb-1">
                        <p>{props.conversation.other_user.Jobtitle} @ {props.conversation.other_user.company}</p>
                    </div>
                    <div className="text-muted small mt-2 mb-3" dangerouslySetInnerHTML={{__html: props.conversation.other_user.cv}}></div>
                    <div className="text-center">
                        <NavLink as="a" to={constants.LocalUrl + "/profile/" + props.conversation.other_user.id} className="btn btn-block btn-info">
                            {t('user_profile')}
                        </NavLink>
                    </div>
                </div>
            </div>
        )
    else return <CircularProgress/>;
}
