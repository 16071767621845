import React, {useContext, useEffect, useState} from 'react';
import * as constants from "../../common/Constants";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DraggableList from "../../../common/DraggableList"
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import DrawerWindow from "../../common/DrawerWindow";
import Register from "../../../Register";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Editor from "../../common/Editor";
import {toast} from "react-toastify";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import LanguageChangerForPanel from "../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../common/Loader";
import RegisterFieldsList from "../../../common/DraggableSimpleList/RegisterFieldsList";
import {makeStyles} from '@material-ui/core/styles';
import PanelBottomBar from "../../../common/PanelBottomBar";
import ToastProvider from "../../../../Providers/ToastProvider";
import PageProvider from "../../../Page/PageContext";


export default function RegistrationFields() {

    const {notify} = useContext(ToastProvider);
    const {setReloadData} = useContext(PageProvider);

    const {t, i18n} = useTranslation();
    const [rfields,setRfields] = useState([]);
    const [loading,setLoading] = useState(false);
    const [loadRegister,setLoadRegister] = useState(false);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [stateLocale, setStateLocale] = useState({});
    const [state, setState] = useState({});
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            open_cat: false,
            id: null,
            id_cat: null,
        }
    );

    useEffect(() => {
        const loadPost = async () => {

            setLoading(true);
            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + "/data/registration_fields");

            // After fetching data stored it in posts state.
            setRfields(response.data);
            console.log(response.data)

            // Closed the loading page
            setLoading(false);
        }

        // Call the function
        loadPost();
    }, []);

    const handleRegisterPage = (value) => {
        setTimeout(function(){
            setLoadRegister(value);

        }, 1000);
    }

    const onSubmitForm = () => {
        // let end_state = {...state};
        // if(end_state.footer_contact_textz){
        //     end_state.footer_contact_text = end_state.footer_contact_textz;
        // }
        // if(end_state.about_text_homepagez){
        //     end_state.about_text_homepage = end_state.about_text_homepagez;
        // }
        delete state.EVENT_DATE
        axios.post(
            constants.LocalUrl + '/data/saveSettings',state).then(response => {
            if(response.data.status == "ok"){
                setReloadData(true);
                notify(t('Succesfully saved'));
            }
        });
    }

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(value == "registration_header_text"){
                localeData[value] = data[value][localeLanguage]?data[value][localeLanguage]:" ";
            }

        })

        setStateLocale(localeData)
        // console.log("localeData",localeData,localeLanguage)
    }

    useEffect(() => {
         axios.post(constants.LocalUrl + '/data/get_settings').then(response => {
            // After fetching data stored it in posts state.
            // console.log("////");
            console.log(response.data);

            setState(response.data);
            setLocaleData(response.data)
        });
    },[])
    useEffect(() => {
        setLocaleData(state);
    },[localeLanguage])



    const handleRegisterPageInstant = (value) => {
        // console.log("handleRegisterPageInstant")
        // console.log(value)
            setLoadRegister(value);
    }

    const toggleChildMenuCat = (attached) => () => {
        setDrawerstate({...drawerstate, open: attached});
    };

    const handleSettingsAbout = (data) => {

        setState((prevState) => ({
            ...prevState,
            ['registration_header_text']:{
                ...prevState['registration_header_text'],
                [localeLanguage]:data,
            }
        }));

    }

    if(loading === true){
        return <Loader />
    }

    return(
        // <div style={{maxWidth:'800px',height:"590px",overflowY:"auto"}}>
        <SimpleBar forceVisible="y" autoHide={false} className="bottom_bar" style={{maxHeight:"658px", height:"658px"}}>
        {/*<div style={{maxWidth:'unset',height:"590px",overflowY:"auto"}}>*/}
            <Grid xs={12} container spacing={4} style={{padding:'20px'}}>
                <Grid item xs={12}>
                    <Editor lang={localeLanguage} handler={handleSettingsAbout} label={t("Registration_header_text")+':'} value={stateLocale.registration_header_text?stateLocale.registration_header_text:""} name="registration_header_text"/>
                </Grid>

                <Grid item xs={12}>
                    <RenderFields handleLoading={handleRegisterPage} data={rfields} />
                </Grid>

                <PanelBottomBar classes={"panel_utilities_element basicinfo"} preview={toggleChildMenuCat(true)} style={{justifyContent:"space-between"}} submit={onSubmitForm} language={setLocaleLanguage}/>


                {/*<Grid item xs={12}>*/}
                    {/*<Typography alignLeft variant="h6" style={{display: 'block'}} gutterBottom>*/}
                    {/*    Preview*/}
                    {/*</Typography>*/}
                    <DrawerWindow open={drawerstate.open}>
                        {/*{drawerstate.id?<LoadedSponsor props={drawerstate.id} posts={posts} triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={updatePostsHandler} />:<NewSponsor triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={newPostsHandler}/>}*/}
                        <Register type="registration_backend" setDrawer={toggleChildMenuCat}/>
                    </DrawerWindow>
                    {/*<UserFields type="registration_set" handleRegisterPageInstant={handleRegisterPage} loadingAction={loadRegister}/>*/}
                    {/*<Register handleRegisterPageInstant={handleRegisterPageInstant} loadingAction={loadRegister}/>*/}
                {/*</Grid>*/}
            </Grid>
        </SimpleBar>

    )

}


export function RenderFields(props){

    const {notify} = useContext(ToastProvider);
    const {setReloadData} = useContext(PageProvider);

    const {t, i18n} = useTranslation();
    const [fields,setFields] = useState([]);
    const [fieldsState,setFieldsState] = useState(null);
    const [loading,setLoading] = useState(false);

    let ar = {};
    useEffect(() => {
        setFields(props.data)
        // Object.entries(props.data).forEach(([key,value]) => { ar[key.name] = value.status })
        // console.log("asd");
        // console.log(props.data);
        props.data && props.data.map((field) =>{
            ar[field.name] = field.status
        })

        setFieldsState(ar);
    },[])

    const defaultFields = ["email","fname","lname","password","password_confirmation"];

    const  handleStatusField = (event) => {
        // console.log("handleChange",event)
        // console.log(fieldsState)
        // setFieldsState({ ...fieldsState, [event.target.name]: event.target.parentElement.getAttribute("data-value") == 1?0:1 });

        const response = axios.post(
            constants.LocalUrl + "/data/change_registration_fields",{id:event.target.parentElement.getAttribute("data-id"),status: event.target.parentElement.getAttribute("data-value") == 1?0:1}).then(

            notify(t('set_field_active', {field: event.target.parentElement.getAttribute("data-value_name"),status:event.target.parentElement.getAttribute("data-value") == 1?t("inactive_lang"):t("active_lang")}))
        );

        props.handleLoading(true);
    }

    const handleRequiredField = (event) => {
        // console.log("handleChange",event)
        // console.log(fieldsState)
        // setFieldsState({ ...fieldsState, [event.target.name]: event.target.parentElement.getAttribute("data-value") == 1?0:1 });

        axios.post(
            constants.LocalUrl + "/data/change_required_fields",{id:event.target.parentElement.getAttribute("data-id"),status: event.target.parentElement.getAttribute("data-value") == 1?0:1}).then(
            notify(t('set_field_required', {field: event.target.parentElement.getAttribute("data-value_name"),status:event.target.parentElement.getAttribute("data-value") == 1?t("not_required_lang"):t("required_lang")}))
        );
        props.handleLoading(true);
    }

    const saveFieldsOrder = (event) => {

        setLoading(true);

        const response = axios.post(
            constants.LocalUrl + "/data/saveRegistrationFieldsOrder",{data:event}).then(

        );

        setLoading(false);
        props.handleLoading(true);
    }

    if(fields && fields.length === 0){
        return <Loader/>;
    }

    return (
        <div>
            <Grid className="bottom_bar" item xs={12}>
                    <Typography alignLeft variant="h6" style={{display: 'block',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                        {t('Field selection')}
                    </Typography>
                    {/*<Paper style={{overflowY:'auto',height:'570px',padding:'20px', borderRadius:'23px'}}>*/}
                    <Paper style={{padding:'20px', borderRadius:'23px'}}>
                        <List component="nav"  style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}} fullwidth>
                            {/*<DraggableList deactivated={defaultFields} states={fieldsState} data={fields} handler={handleChange} save={saveFieldsOrder}/>*/}
                            <FieldsUi deactivated={defaultFields} states={fieldsState} data={fields} handler={handleStatusField} handleRequiredField={handleRequiredField} save={saveFieldsOrder}/>
                            {/*{fields.map((field) => (*/}
                            {/*    // <div>{field.name}</div>*/}
                            {/*<div>*/}
                            {/*<Divider light />*/}
                            {/*<ListItem button fullwidth>*/}

                            {/*    <div style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>*/}

                            {/*        <FormLabel style={{marginBottom:'0'}} component="legend">{field.label.en}</FormLabel>*/}
                            {/*        <FormGroup row>*/}

                            {/*            <FormControlLabel*/}
                            {/*                style={{margin:'0'}}*/}
                            {/*                control={<Switch checked={fieldsState[field.name]} onChange={handleChange} name={field.name} />}*/}
                            {/*                label=""*/}
                            {/*            />*/}
                            {/*        </FormGroup>*/}
                            {/*    </div>*/}
                            {/*</ListItem>*/}
                            {/*</div>*/}
                            {/*))}*/}

                            <Divider light />
                        </List>
                        </Paper>
                </Grid>
        </div>
    )

}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export function FieldsUi(props){

    const classes = useStyles();
    const [files,setFiles] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() => {

        setFiles(props.data?props.data:'');

    }, [props.data]);

    const save = () => {

    }
    // const handleSaveFileOrder = (files) => {
    //     setLoading(true);
    //
    //     const response = axios.post(
    //         constants.LocalUrl + "/data/saveSponsorFilesOrder",{data:files});
    //
    //     setLoading(false);
    // }

    // console.log("props.data")
    // console.log(files)

    if (files.length === 0) {
        <Paper style={{overflowY: 'auto', maxHeight: '600px', padding: '20px', borderRadius: '23px'}}>
            <List component="nav" className={classes.root}
                  style={{width: '100%', maxWidth: "unset", borderRadius: '20px'}} fullwidth>
            </List>
        </Paper>
    }

    return(
        <>

            <div style={{marginTop:"30px"}}>
                <RegisterFieldsList handleRequiredField={props.handleRequiredField} handler={props.handler} edit={props.OnClick} save={props.save} filename={props.filename} holdFilename={props.holdFilename} triggerdataload={props.triggerdataload} sponsor_id={props.sponsor_id} triggerdrawer={props.triggerdrawer} triggerdrawerfunc={props.triggerdrawerfunc}  data={props.data} OnClick={props.OnClick}/>
            </div>

        </>
    )

}
