import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonalData from "./components/PersonalData"
import RecoverPassword from "./components/RecoverPassword"
import {useTranslation} from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Profile() {
    const {t, i18n} = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
                <div className={"myinfo"}>
                    <Box sx={{ width: '100%' }} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange}  >
                                <Tab label={t("Personal Data")} {...a11yProps(0)} />
                                {/*<Tab label="Preferences" {...a11yProps(1)} />*/}
                                <Tab label={t("Change Password")} {...a11yProps(1)} />
                                {/*<Tab label="Social Accounts" {...a11yProps(3)} />*/}
                            </Tabs>
                        </Box>
                        {/*<TabPanel value={value} index={0} style={{maxHeight:"100vh",overflowY:"auto"}}>*/}
                        <TabPanel value={value} index={0} >
                            <PersonalData />
                        </TabPanel>
                        {/*<TabPanel value={value} index={1}>*/}
                        {/*    Item Two*/}
                        {/*</TabPanel>*/}
                        <TabPanel value={value} index={1}>
                            <RecoverPassword />
                        </TabPanel>
                        {/*<TabPanel value={value} index={3}>*/}
                        {/*    Item Two*/}
                        {/*</TabPanel>*/}
                    </Box>
                </div>
    );
}

