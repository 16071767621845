import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function CSwitchField(props){


    return(
        <div style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>
            <FormLabel style={{marginBottom:'0'}} component="legend">{props.label} </FormLabel>
            <FormGroup>
                <FormControlLabel
                    style={{margin:'0'}}
                    control={<Switch checked={props.state} onChange={props.handler} name={props.name} disabled={props.disabled} />}
                    label=""
                />
            </FormGroup>
        </div>
    )
}