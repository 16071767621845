import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";

import * as constants from "../../../../Panel/common/Constants"
import CTextField from "../../../../Panel/common/CTextField";
import Editor from "../../../../Panel/common/Editor";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, TimePicker, KeyboardDateTimePicker, DateTimePicker} from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageChangerForPanel from "../../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../../common/Loader";
import PageProvider from "../../../../Page/PageContext";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import {
    datepickerCancelLabelLocales,
    datepickerClearLabelLocales,
    datepickerLocales
} from "../../../../common/Language/DatepickerLocales";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

export default function LoadedSchedule(props){

    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [scheduleLocale, setScheduleLocale] = useState([]);
    const [editorSubtitle, setEditorSubtitle] = useState(null);
    const [checkbox, setCheckbox] = useState({});
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [speakers, setSpeakers] = useState([]);
    const [coordinators, setCoordinators] = useState([]);
    const [sponsors, setSponsors] = useState([]);

    const [formErrors, setFormErrors] = useState(null);
    const checkboxes_style = useedStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small" className={checkboxes_style.root}/>;



    useEffect(() => {
        setLocaleData(schedule);
    },[localeLanguage])

    const changeLocale = (lang) => {
        setEditorSubtitle(scheduleLocale.subtitle);
        setLocaleLanguage(lang);
    }
    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null && value != "draggable_speakers" && value != "draggable_syntonistes" && value != "syntonistes" && value != "speakers" && value != "start_time_picker" && value != "end_time_picker"){
                // console.log("a",value,data[value])
                localeData[value] = data[value][localeLanguage]?data[value][localeLanguage]:" ";
            }else{
                // console.log("b",value,data[value])
                localeData[value] = data[value]?data[value]:" ";
            }
        })
        console.log('setLocaleData', data, localeData,localeLanguage);
        // console.log("localeData",localeData,localeLanguage)
        setScheduleLocale(localeData);
        setEditorSubtitle(localeData.subtitle);
    }

    const container_styles = makeStyles({
        root: {
            padding: '40px',
        },
        fullList: {
            width: 'auto',
        },
    });

    const useStyles = makeStyles({
        list: {
            // width: '800px',
            padding:'40px'
        },
        fullList: {
            width: 'auto',
        },
    });

    const new_schedule = makeStyles((theme) => ({
        root: {
            display: 'block',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(45),
                // height: theme.spacing(13),
            },
        },
        new_schedule: {
            // width:'100%',
            textAlign:'center'
        }
    }));


    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const onInputTimechange = (event) => {
        event.persist();

        // console.log("-----");
        date = schedule.start_date + " " + event.target.value;
        var timestamp_date = Math.floor(new Date(date).getTime() / 1000)
        // console.log(date);
        // console.log(timestamp_date);
        // console.log("-----");

        setSchedule((prevState) => ({
            ...prevState,
            [event.target.name]: timestamp_date,
        }));
        // console.log(schedule);
    };

    const onInputchange = (event) => {
        event.persist();

        if(typeof schedule[event.target.name] === 'object'){
            // console.log('object')

            setSchedule((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));
        }else{
            // console.log('nope')
            setSchedule((prevState) => ({
                ...prevState,
                [localeLanguage]: event.target.value,
            }));

        }
        setScheduleLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        // console.log(schedule);
    };

    const handleChange = (event) => {
        // setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
        event.persist();
        setSchedule((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
        // console.log([event.target.name], event.target.checked);
    };


        const thid =  props.props;
        useEffect(() => {
            const loadSchedule = async () => {

                // Till the data is fetch using API
                // the Loading page will show.
                setLoading(true);

                const wert = await axios.post(constants.LocalUrl + '/data/schedule_full_data', {id: props.props,type:"panel"}).then(response => {
                    // After fetching data stored it in posts state.
                    if(response.data[0].start_time){

                        let start_time = new Date(response.data[0].start_time * 1000)
                        let end_time = new Date(response.data[0].end_time * 1000)

                        response.data[0].start_time_picker = start_time;
                        response.data[0].end_time_picker = end_time;
                    response.data[0].start_time = start_time.getTime();
                    response.data[0].end_time = end_time.getTime();

                    }
                    setSchedule(response.data[0]);
                    setLocaleData(response.data[0])
                    // Closed the loading page

                });

                window.axios.post(constants.LocalUrl + '/data/get_all_speakers').then((response) => {
                    setSpeakers(response.data);
                });

                window.axios.post(constants.LocalUrl + '/data/get_all_coordinators').then((response) => {

                    setCoordinators(response.data);
                    setLoading(false);
                });

                window.axios.post(constants.LocalUrl + '/data/get_all_sponsors').then((response) => {
                    setSponsors(response.data);
                });


            }

            // Call the function
            loadSchedule();

        }, []);


    const onSubmitForm = () => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            // setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            await axios.post(
                constants.LocalUrl + '/data/update_schedule',{...scheduleLocale , lang: localeLanguage}).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }
                else if (response.data.status == 'ok') {
                    let index_id;
                    const i = props.posts.map((single_post,index) =>  {
                            if(single_post.id === response.data.data.id){
                                index_id = index;
                                return true;
                            }
                        }
                    );

                    let items = [...props.posts];
                    let item = {...items[index_id]};
                    item.title = response.data.data.title;
                    item.startDate = response.data.data.startDate;
                    item.startTime = response.data.data.startTime;
                    items[index_id] = item;

                    props.triggerdataload(items);
                    props.ooo(false);
                }
            });

        }

        // Call the function
        loadItems();
    };

    const return_options_sponsors = (sponsores) => {
        let arr = [];
        sponsors.map((e,index) =>{
            if(sponsores && sponsores.includes(e.id)){
                arr.push(sponsors[index]);
            }
        });
        return arr;
    }

    const return_options_speakers = (omilites) => {
        let arr = [];
        speakers.map((e,index) =>{
            if(omilites && omilites.includes(e.id)){
                arr.push(speakers[index]);
            }
        });
        return arr;
    }

    const return_options_syntonistes = (syntonistes) => {
        let arr = [];
        coordinators.map((e,index) =>{
            if(syntonistes && syntonistes.includes(e.id)){
                arr.push(coordinators[index]);
            }
        });
        // console.log("return_options_syntonistes",syntonistes,coordinators,arr)
        return arr;
    }

    const saveScheduleSpeakersOrder = (event) => {

        setSchedule(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["speakersOrder"]: event      // update the value of specific key

        }))

        setScheduleLocale(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["speakersOrder"]: event      // update the value of specific key

        }))

    }

    const saveScheduleSyntonistesOrder = (event) => {

        setSchedule(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["syntonistesOrder"]: event      // update the value of specific key

        }))

        setScheduleLocale(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["syntonistesOrder"]: event      // update the value of specific key

        }))

    }

    const onSponsorsChange = (event, values) => {
        event.persist();
        setSchedule(
            prevState => ({
                               // object that we want to update
                    ...prevState,    // keep all other key-value pairs
                addsponsors: values      // update the value of specific key

            }))
    }


    const onSyntonistesChange = (event, values) => {
        event.persist();
        setSchedule(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addsyntonistes: values      // update the value of specific key

            }))

        setScheduleLocale(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addsyntonistes: values      // update the value of specific key

            }))
        // console.log(schedule, values);
    }

    const onSpeakersChange = (event, values) => {
        event.persist();
        setSchedule(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addspeakers: values      // update the value of specific key

            }))

        setScheduleLocale(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addspeakers: values      // update the value of specific key

            }))
        // console.log(schedule, values);
    }

    const handleDescription = (data) => {



        setSchedule((prevState) => ({
            ...prevState,
            ['subtitle']:{
                ...prevState['subtitle'],
                [localeLanguage]:data,
            }
        }));


        setScheduleLocale((prevState) => ({
            ...prevState,
            subtitle: data,
        }));
        //change

        // console.log(schedule);
        // console.log("handleSettings");
    }

    // const [started_time, handleStartTimeChange] = useState(new Date());
    // const [ended_time, handleEndTimeChange] = useState(new Date());
    const handleStartTime = (event) => {

        // let date = new Date(scheduleLocale.start_date);
        // date.setHours(event.getHours(), event.getMinutes(), 0, 0);
        let end_date_now = new Date(scheduleLocale.end_time_picker);
        let start_date_now = new Date(event);
        // console.log("start_date_now",start_date_now,start_date_now.getTime(),event.getTime())
        if(event.getTime() > end_date_now.getTime()){
            setScheduleLocale((prevState) => ({
                ...prevState,
                end_time_picker: start_date_now.setHours(start_date_now.getHours(), start_date_now.getMinutes() + 1, 0, 0),
            }));
            // event.setDate(end_date_now);
        }
        // handleStartTimeChange(event)
        // console.log(event,event.getTime(),date.getTime())
        setScheduleLocale((prevState) => ({
            ...prevState,
            start_time: event.getTime(),
        }));

        setScheduleLocale((prevState) => ({
            ...prevState,
            start_time_picker: event,
        }));

        setSchedule((prevState) => ({
            ...prevState,
            start_time: event.getTime(),
        }));

        setSchedule((prevState) => ({
            ...prevState,
            start_time_picker: event,
        }));

    }

    const handleEndTime = (event) => {

        let start_date_locale = new Date(scheduleLocale.start_time_picker);
        let end_date_now = new Date(event);

        if(end_date_now.getTime() < start_date_locale.getTime()){
            end_date_now.setHours(start_date_locale.getHours(), start_date_locale.getMinutes() + 1, 0, 0);
        }

        setScheduleLocale((prevState) => ({
            ...prevState,
            end_time: end_date_now.getTime(),
        }));
        setScheduleLocale((prevState) => ({
            ...prevState,
            end_time_picker: end_date_now,
        }));

        setSchedule((prevState) => ({
            ...prevState,
            end_time: end_date_now.getTime(),
        }));
        setSchedule((prevState) => ({
            ...prevState,
            end_time_picker: end_date_now,
        }));

        //change
        // console.log("handleSettings");
        // console.log(schedule);
        // console.log("handleSettings");
    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    let test = return_options_syntonistes(schedule.syntonistes)
    // let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'},{id:'local',title:'LiveOn'},{id:'facebook',title:'Facebook'}]
    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let currentdate = `${year}-${month<10?`0${month}`:`${month}`}-${date}`;
    const classess = new_schedule();


    var start = new Date(schedule.start_time * 1000);
    var end = new Date(schedule.end_time * 1000);
    var start_time = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
    var end_time = ('0' + end.getHours()).slice(-2) + ':' + ('0' + end.getMinutes()).slice(-2);



    if(loading === true || Object.keys(schedule).length == 0
        // || Object.keys(speakers).length == 0 || Object.keys(coordinators).length == 0 || Object.keys(sponsors).length == 0
        // || sponsors.length == 0 || coordinators.length == 0 || speakers.length == 0
    ){
        return <Loader />
    }

    return (
        <div className="bottom_bar">

            {/*<Grid container spacing={3} style={{padding:'20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)} className={close_button.root} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            {/*{changeLanguage()}*/}
            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Session')}
            </Typography>
            <Typography alignLeft variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{scheduleLocale.title}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
            <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

            <Grid item xs={12}>
                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Session Details')}
                </Typography>


                <Divider light />
            </Grid>

            <Grid item xs={12}>
                <CTextField value={scheduleLocale && scheduleLocale.title?scheduleLocale.title:''} handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
            </Grid>

            <Grid item xs={12}>
                <Editor lang={localeLanguage} handler={handleDescription} value={editorSubtitle} label={t("Description")}  name="subtitle"/>
            </Grid>

            <Grid item xs={6}>
                <Typography style={{marginBottom:"15px",marginLeft:"20px"}} alignLeft variant="h6" gutterBottom>
                    {t('Start Date')}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                    <DateTimePicker
                        id="outlined-textarea"
                        inputVariant="outlined"
                        ampm={true}
                        label=""
                        value={scheduleLocale.start_time_picker?scheduleLocale.start_time_picker:""}
                        required error={hasError('start_date')}
                        helperText={errorMessage('start_date')}
                        minDate={settings.EVENT_DATE}
                        maxDate={settings.EVENT_DATE_TO}
                        fullWidth
                        onChange={handleStartTime}
                        onError={console.log}
                        format="dd/MM/yyyy HH:mm"
                        cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                        clearLabel={datepickerClearLabelLocales[i18n.language]}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
                <Typography style={{marginBottom:"15px",marginLeft:"20px"}} alignLeft variant="h6" gutterBottom>
                    {t('End Date')}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                <DateTimePicker
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    ampm={true}
                    label=""
                    value={scheduleLocale.end_time_picker?scheduleLocale.end_time_picker:ended_time}
                    required error={hasError('end_date')}
                    helperText={errorMessage('end_date')}
                    minDate={settings.EVENT_DATE}
                    maxDate={settings.EVENT_DATE_TO}
                    fullWidth
                    onChange={handleEndTime}
                    onError={console.log}
                    format="dd/MM/yyyy HH:mm"
                    cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                    clearLabel={datepickerClearLabelLocales[i18n.language]}
                />
                </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Speakers & Coordinators')}
                </Typography>
                <Divider light />
            </Grid>
                {Object.keys(speakers).length > 0?<Grid item xs={6}>
                    <Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                        padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                        <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 20px 17px"}} gutterBottom>
                            {t('Speakers')}
                        </Typography>
                        <div id="autocomplete_selector">
                            <Autocomplete
                                multiple
                                popupIcon={<ExpandMoreIcon />}
                                id="checkboxes-tags-demo"
                                options={speakers}
                                disableCloseOnSelect
                                onChange={onSpeakersChange}
                                defaultValue={return_options_speakers(scheduleLocale.speakers)}
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="#000"
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                style={{ }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label=""  placeholder={t("Choose a speaker...")} fullwidth/>
                                )}
                            />
                            {scheduleLocale.addspeakers && Object.keys(scheduleLocale.addspeakers).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}
                            <DraggableSimplestList  data={scheduleLocale.addspeakers==='true'?scheduleLocale.draggable_speakers:scheduleLocale.addspeakers} save={saveScheduleSpeakersOrder}/>
                        </div>
                    </Paper>
                </Grid>:null}

                {Object.keys(coordinators).length > 0?<Grid item xs={6}>
                    <Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                        padding: 8,display:'nline-block',marginRight: '10px',width:'100%'}}>
                        <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 20px 17px"}} gutterBottom>
                            {t('Coordinators')}
                        </Typography>
                        <div id="autocomplete_selector">
                            <Autocomplete
                                multiple
                                popupIcon={<ExpandMoreIcon />}
                                id="checkboxes-tags-demo"
                                options={coordinators}
                                disableCloseOnSelect
                                onChange={onSyntonistesChange}
                                defaultValue={return_options_syntonistes(scheduleLocale.syntonistes)}
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="#000"
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                style={{ }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label=""  placeholder={t("Choose a coordinator...")} fullwidth/>
                                )}
                            />

                            {scheduleLocale.addsyntonistes && Object.keys(scheduleLocale.addsyntonistes).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}
                            <DraggableSimplestList  data={scheduleLocale.addsyntonistes==='true'?scheduleLocale.draggable_syntonistes:scheduleLocale.addsyntonistes} save={saveScheduleSyntonistesOrder}/>
                        </div>
                    </Paper>
                </Grid>:null}

                <PanelBottomBar language={changeLocale} close={props.triggerdrawer(false)}  submit={onSubmitForm}/>

                {/*<Grid id="panel_utilities_element" className="panel_utilities_element" item xs={12}>*/}
                {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                {/*    <div className="right_part">*/}
                {/*        <LanguageChangerForPanel onChange={setLocaleLanguage}/>*/}
                {/*        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <Button id="close_button" variant="contained"  color="primary" onClick={onSubmitForm} component="span">{t('SAVE')}</Button>*/}
            {/*</Grid>*/}

        </Grid>

        </div>
        </div>
    )
}