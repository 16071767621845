import React, {useContext, useEffect, useState} from 'react';

import "react-chat-elements/dist/main.css";
import PageProvider from "../Page/PageContext";
import {CircularProgress} from "@material-ui/core";
import LiveStageAdmin from "../Panel/tabs/Dashboard/LiveStageAdmin";
import LiveQuestionsAdmin from "../Panel/tabs/Dashboard/LiveQuestionsAdmin";
import {useTranslation} from "react-i18next";
import CAccordionControlled from "./CAccordionControlled";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

export default function EventManager(props) {

    const {settings} = useContext(PageProvider);
    const [myuser, setMyuser] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        setMyuser(settings.user);
    }, [])

    const tabs = {
        titles: [t('LIVE STAGE ADMIN'), t('LIVE QUESTIONS ADMIN')],
        content: [<LiveStageAdmin orientation='vertical'></LiveStageAdmin>, <LiveQuestionsAdmin></LiveQuestionsAdmin>],
    };

    if (window.temp_user_id) {
        return (
            <div className={'eventmanager_sidebar'}>
                {
                    props.open
                        ? <>
                            <Typography xs={12} alignLeft variant="h5" style={{
                                textAlign: 'left',
                                fontWeight: 600,
                                display: "flex",
                                lineHeight: "20px",
                                justifyContent: "flex-start",
                                marginTop: '20px'
                            }} gutterBottom>
                                {t('eventmanager')}
                            </Typography>
                            <Divider bold style={{height: "3px", backgroundColor: "var(--divider-color) "}}/>
                            <CAccordionControlled addclass="event_manager" initial={'panel0'} titles={tabs.titles}
                                                  contents={tabs.content}></CAccordionControlled>
                        </>
                        : null
                }
            </div>
        )
    } else return <CircularProgress/>;

}
