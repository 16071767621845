import React, {useEffect} from 'react';
import {createMedia} from "@artsy/fresnel";
import StageIndividual from "./StageIndividual";
import StageMobile from "./StageMobile";
import {Navigate} from "react-router-dom";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function StageLayout(props){

    useEffect(() => {

        if (!localStorage.getItem('userLoggedIn')) {
            return null;

        }

    }, [])

    if (!window.temp_user_id || window.temp_user_id == 0) {
        return null;
    }

    return (
        <MediaContextProvider>

            <Media at='mobile'>
                <StageMobile position={props.position} />
            </Media>

            <Media at='computer'>
                <StageIndividual position={props.position} />
            </Media>

        </MediaContextProvider>

    )

}