import React, {createRef, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import * as XLSX from "xlsx";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import * as constants from "../../../common/Constants";
import {Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {createMedia} from "@artsy/fresnel";
import {ReactSVG} from "react-svg";
import HelpIcon from "@material-ui/icons/Help";
import HelpDrawer from "../../../common/HelpDrawer";


const toArray = selected => Object.keys(selected).map(id => id * 1);
const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})
let icons = {
    back:"/images/back_mobile_lite.svg",
    save:"/images/save_mobile_lite.svg",
    preview:"/images/preview_mobile_lite.png",
    upload:"/images/upload_mobile_lite.svg",
}

export default function UsersImport(props) {
    const {t, i18n} = useTranslation();
    const helpDrawerRef = createRef();
    const [gridRef, setGridRef] = useState(null);
    const [selected, setSelected] = useState({});
    const [formData, setFormData] = useState({});
    const [rfields,setRfields] = useState([]);
    const [disableImportButton,setDisableImportButton] = useState(false);
    const [sendEmails, setSendEmails] = useState(false);
    const [advancedLayout, setAdvancedLayout] = React.useState(false);

    const onSelectionChange = useCallback(({ selected, data, unselected }) => {
        let finalSelected = {};

        if (selected === true) {
            for (const row of data) {
                if (row.hasOwnProperty('validation') && Object.keys(row.validation).length == 0) {
                    finalSelected[row.__rowNum__] = true;
                }
            }
        }
        else if (Object.keys(selected).length > 0) {
            finalSelected = {...selected};
            if (data.hasOwnProperty('validation') && Object.keys(data.validation).length > 0) {
                delete finalSelected[data.__rowNum__];
            }
            else if (data.hasOwnProperty('0') && data[0].hasOwnProperty('validation') && Object.keys(data[0].validation).length > 0) {
                delete finalSelected[data[0].__rowNum__];
            }
        }

        setSelected(finalSelected);
    }, [])

    useEffect(() => {
        const loadPost = async () => {
            const response = await axios.post(constants.LocalUrl + "/data/active_registration_fields?all=y");
            setRfields(response.data.import_fields);
        }
        loadPost();
    }, []);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "array"});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                let selectedRows = {};
                let loadedEmailsUnique = [];
                let datasource = [];

                for (const jsonElement of json) {
                    const validate = async (jsonElement) => {
                        const response = await axios.post(constants.LocalUrl + "/data/validateDataForImport", {type: advancedLayout?'all':'simple', data: {...jsonElement}});
                        jsonElement.validation = response.data.data;
                        if (loadedEmailsUnique.includes(jsonElement.email)) {
                            if (!jsonElement.validation.hasOwnProperty('email')) {
                                jsonElement.validation['email'] = [];
                            }
                            jsonElement.validation['email'].push('Email exists in previous line in uploaded file');
                        }
                        loadedEmailsUnique.push(jsonElement.email);
                        loadedEmailsUnique = loadedEmailsUnique.filter((value, index, self) => self.indexOf(value) === index);
                        if (Object.keys(response.data.data).length == 0) {
                            selectedRows[jsonElement.__rowNum__] = true;
                        }
                    }
                    validate(jsonElement);
                    datasource.push(jsonElement);
                }
                setFormData({...formData, xlsxUsers: datasource});
                setTimeout(()=>{
                    gridRef.current.selectAll();
                    gridRef.current.deselectAll()
                    setSelected(selectedRows);
                }, 300);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const downloadTemplate = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.json_to_sheet(templateData[advancedLayout?'all':'simple']??[]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "LiveOn User Import Template.xlsx");
    }

    const importSelected = () => {
        const run = async () => {
            setDisableImportButton(true);
            let importUsers = [];
            for (const key of Object.keys(formData.xlsxUsers)) {
                if (selected.hasOwnProperty((key*1)+1)) {
                    let xlsxUser = formData.xlsxUsers[key];
                    if (Object.keys(xlsxUser.validation??[]).length == 0) {
                        importUsers.push(xlsxUser);
                    }
                }
            }
            console.info(importUsers);
            const response = await axios.post(constants.LocalUrl + "/data/import_users", {users: importUsers, sendEmails: sendEmails, type: advancedLayout?'all':'simple'})
                .then((response) => {
                    toast.success(response.data.data.created + ' Users created!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setFormData({});
                })
                .catch((error)=>{});
            setDisableImportButton(false);
            if (props.triggerdataload != undefined) {
                props.triggerdataload();
            }
        }
        run();
    }

    const renderCell = (value, data, cellProps) => {
        if (data.hasOwnProperty('validation') && data.validation.hasOwnProperty(cellProps.name)) {
            return (
                <Tooltip title={
                    <>
                        <Typography color="inherit">
                            Error(s)
                        </Typography>
                        <Typography>
                            <div dangerouslySetInnerHTML={{__html: data.validation[cellProps.name].join('<br/>')}}></div>
                        </Typography>
                    </>
                } style={{maxWidth: '500px'}} placement="top">
                    <div>{value}</div>
                </Tooltip>
            )
        }
        else {
            return (
                <div>{value}</div>
            )
        }
    }

    let columns = {
        simple: [],
        all: [],
    };
    let templateData = {
        simple: [{}],
        all: [{}],
    };

    for (const field of rfields) {
        if (field.status == 1) {
            // console.info(field);
            let field_label = field.label[i18n.language] + ' ' + ( field.required == 1 ? '*' : '' );
            let field_column = {
                name: field.name,
                header: field_label,
                minWidth: 200,
                defaultFlex: 1,
                onRender: (cellProps, {data}) => {
                    if (data.hasOwnProperty('validation') && data.validation.hasOwnProperty(cellProps.name)) {
                        cellProps.style.background = '#FF0000';
                        cellProps.style.color = '#FFFFFF';
                    }
                },
                render: ({value, data, cellProps}) => renderCell(value, data, cellProps)
            };
            columns.all.push(field_column);
            // console.info(field.name, field.type);
            if (field.type == 'checkbox') {
                templateData.all[0][field.name] = '0 , 1'
            }
            else if (field.type == 'radio' || field.type == 'dropdown' || field.type == 'select') {
                templateData.all[0][field.name] = field.data.map(item=>Object.values(item).join('|')).join(' , ');
                // console.log('field.data', field.data);
            }
            else {
                if (field.name == 'fname') { templateData.all[0][field.label[i18n.language]] = 'George'; }
                else if (field.name == 'lname') { templateData.all[0][field.label[i18n.language]] = 'Browney'; }
                else if (field.name == 'email') { templateData.all[0][field.label[i18n.language]] = 'george@validemail.test'; }
                else templateData.all[0][field.label[i18n.language]] = field.label[i18n.language];
            }
            if ((['fname', 'lname', 'email']).includes(field.name)) {
                columns.simple.push(field_column);
                if (field.name == 'fname') { templateData.simple[0][field.label[i18n.language]] = 'George'; }
                else if (field.name == 'lname') { templateData.simple[0][field.label[i18n.language]] = 'Browney'; }
                else if (field.name == 'email') { templateData.simple[0][field.label[i18n.language]] = 'george@validemail.test'; }
            }
        }
    }

    const handleChooseLayout = (newLayout) => {
        console.log('newLayout', newLayout);
        setAdvancedLayout(newLayout);
    };

    const gridStyle = { minHeight: 550 }

    const rowStyle = ({ data }) => {
        return null;
        // const colorMap = {
        //     invalid: '#ef9a9a'
        // }
        // return {
        //     color: validateRow(data) ? null : colorMap["invalid"]
        // }
    }

    // const validateRow = (data) => {
    //     return Math.random() > 0.5;
    // }


    return (
        <div>

            <Typography xs={12} alignLeft variant="h5" style={{
                textAlign: 'left',
                fontWeight: 600,
            }} gutterBottom>
                {t("IMPORT USERS title")}
                <HelpIcon style={{color:"rgba(0, 0, 0, 0.87)",marginLeft:"8px"}} onClick={()=>helpDrawerRef.current.toggleDrawer(true)}/>
            </Typography>
            <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

            <br/><br/>
            <p id="simple-modal-description">
                {t("IMPORT USERS help")}
            </p>


            Simple
            <Switch checked={advancedLayout} onChange={()=>handleChooseLayout(!advancedLayout)} />
            All
            &nbsp;&nbsp;
            <a href={'#'} onClick={()=>downloadTemplate()}>{t("IMPORT USERS download template")}</a>

            <ReactDataGrid
                theme='green-light'
                onReady={setGridRef}
                idProperty="__rowNum__"
                columns={columns[advancedLayout?'all':'simple']}
                dataSource={formData.xlsxUsers??[]}
                style={gridStyle}
                nativeScroll={true}
                rowStyle={rowStyle}
                pagination defaultLimit={10}
                checkboxColumn
                selected={selected} onSelectionChange={onSelectionChange}
                enableKeyboardNavigation={false}
            />



                <MediaContextProvider>
                    <Media at='mobile' style={{justifyContent:"space-between",display:"flex",width:"100%"}}>
                        <Grid className="panel_utilities_element" item xs={12}>
                            <div>
                                <form>
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        onChange={readUploadFile}
                                        style={{display: "none"}}
                                    />
                                    <label htmlFor="upload">
                                        <Button component="span" classname="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 5px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                                            <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} color="rgba(0,0,0,0.87)" gutterBottom>
                                                <ReactSVG beforeInjection={(svg) => {
                                                    svg.classList.add('svg-class-name');
                                                    svg.setAttribute('style', 'width:25px');
                                                }} src={icons.upload}/>
                                            </Typography>
                                        </Button>
                                    </label>
                                </form>
                            </div>
                            <FormControlLabel className="import_users_send_emails_switch" style={{display:'flex',flexDirection:"column-reverse",marginBottom:"0",textAlign:"center"}}
                                              control={<Switch checked={sendEmails} onChange={()=>setSendEmails(!sendEmails)} />}
                                              label={t("import_users_send_emails")}
                            />
                            <div className="right_part">
                                <Button id="close_button" disabled={toArray(selected).length == 0 || disableImportButton == true} variant="contained" onClick={()=>importSelected()} color="primary" component="span">
                                    <ReactSVG beforeInjection={(svg) => {
                                        svg.classList.add('svg-class-name');
                                        svg.setAttribute('style', 'width:25px');
                                    }} src={icons.save}/>
                                </Button>
                            </div>
                        </Grid>
                    </Media>
                    <Media at='computer' style={{justifyContent:"space-between",display:"flex",width:"100%"}}>
                        <Grid className="panel_utilities_element" item xs={12}>
                            <div>
                                <form>
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        onChange={readUploadFile}
                                        style={{display: "none"}}
                                    />
                                    <label htmlFor="upload">
                                        <Button component="span" classname="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                                            <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} color="rgba(0,0,0,0.87)" gutterBottom>
                                                {t('Upload xlsx File')}
                                            </Typography>
                                        </Button>
                                    </label>
                                </form>
                            </div>
                            <FormControlLabel
                                control={<Switch checked={sendEmails} onChange={()=>setSendEmails(!sendEmails)} />}
                                label={t("import_users_send_emails")}
                            />
                            <div className="right_part">
                                <Button id="close_button" disabled={toArray(selected).length == 0 || disableImportButton == true} variant="contained" onClick={()=>importSelected()} color="primary" component="span">
                                    {t('Import Users')}
                                </Button>
                            </div>
                        </Grid>
                    </Media>
                </MediaContextProvider>


            <HelpDrawer id="users-import" ref={helpDrawerRef} />

            {/*<p>Selected rows: {JSON.stringify(toArray(selected))}.</p>*/}

        </div>
    );
}
