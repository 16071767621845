import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as constants from "../../../common/Constants";
import Loader from "../../../../common/Loader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useTranslation} from "react-i18next";

//  props.previewData
export default function InvitationPreview(props) {

    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            if (!template) {
                const response = await axios.post(constants.LocalUrl + "/data/invitation_preview");
                setTemplate(response.data);
            }

            // Closed the loading page
            setLoading(false);
        }

        // Call the function
        loadPost();
    }, []);


    if (loading === true || props.formData) {
        return <Loader/>
    }

    return (
        <div>
            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {t("Preview")}
            </Typography>
            {template ? <div className="email_preview_marketing" dangerouslySetInnerHTML={{__html: template.replaceAll('[[TITLE]]', props.previewData.title).replaceAll('[[MESSAGE]]', props.previewData.message)}}/> : <Loader/>}
        </div>
    );

}


