import React ,{useState} from 'react';


export function FormErrors() {

    const [formErrors, setFormErrors] = useState(null);

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ?
            <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }


    return {hasError,errorMessage,setFormErrors};
};