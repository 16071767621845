import React, {useEffect, useState} from "react";
import * as constants from "../../../../../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import Loader from "../../../../../common/Loader";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useTranslation} from "react-i18next";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MyDownloads(){

    const {t, i18n} = useTranslation();
    const [downloads, setDownloads] = useState([]);
    const [loading, setLoading] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        setLoading(true);
        window.axios.post(constants.LocalUrl + '/data/getDownloads').then(response => {
            console.log('response', response);
            // After fetching data stored it in posts state.
            setDownloads(response.data);
            setLoading(false);
        });

    },[]);



    const downloadHandler = (file) => {
        window.open(file,"_blank")
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(loading === true){
        return <Loader/>;
    }
console.log(downloads);
    return(
        <div>
            <div className="mydownloads" style={{justifyContent:'center',background:"#fff",borderRadius:"20px",boxShadow:"-5px 3px 40px #5e5c9a1a"}}>
                <Grid container spacing={3} style={{padding:'20px',maxWidth:'100%',margin:"0px"}}>
                    {downloads && Object.values(downloads).map(download => (
                       <div style={{width:"100%"}}>
                          <Divider light />
                            <div style={{display:"flex",marginTop:"20px"}} className="download_filename">
                                <span className="name">{download.text}</span>
                                <Button size="large" onClick={()=>downloadHandler(download.link)} variant="contained" color="primary" component="span" style={{backgroundColor:"rgb(57, 187, 15)",
                                    borderColor:"rgb(57, 187, 15)"}}>
                                    {t('Download')}
                                </Button>
                            </div>
                        </div>
                    ))}
                    <Divider light />
                </Grid>

            <Tabs style={{marginLeft:"19px"}} value={value} onChange={handleChange}  >
                <Tab label={t("How to use CSV files")} {...a11yProps(0)} />

                <Tab label={t("How to use VCF (vCard) files")} {...a11yProps(1)} />

            </Tabs>

            <TabPanel value={value} index={0} style={{maxHeight:"100vh",overflowY:"auto"}}>
                <div>
                    {t("How to use CSV files info")}
                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <div>
                    {t("How to use VCF (vCard) files info")}
                </div>
            </TabPanel>

            </div>
        </div>
    )
}