import React, {useContext, useEffect, useRef, useState} from 'react';
import PageProvider from "../../Page/PageContext";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import {JitsiMeeting} from '@jitsi/react-sdk';
import Button from "@material-ui/core/Button";
import CallEndIcon from "@material-ui/icons/CallEnd";
import MinimizeIcon from '@material-ui/icons/Minimize';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import CloseIcon from '@material-ui/icons/Close';
import VideoChatWindow from "./VideoChatWindow";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Logo from "../Logo";
import CTabsAutoScroll from "../CTabsAutoScroll";
import VideoChatParticipants from "./VideoChatParticipants";
import {Rnd} from "react-rnd";

export default function VideoWindow() {


    const toastId = React.useRef(null);
    const {putVideoCall, setPutVideoCall, settings} = useContext(PageProvider);
    const [minimize, setMinimize] = useState(false);
    const [videoRequest, setVideoRequest] = useState({});
    const [receivedCall, setReceivedCall] = useState(false);
    const [answer, setAnswer] = useState(false);
    const [callNotification, setCallNotification] = useState(false);
    const [socket, setSocket] = useState(false);
    const [channel, setChannel] = useState(false);
    const [enableResize, setEnableResize] = useState(false);
    const [disableDrag, setDisableDrag] = useState(true);
    const [floatLimits, setFloatLimits] = useState('parent');
    const [channelUsers, setChannelUsers] = useState([]);

    let x = false;
    const {t, i18n} = useTranslation();
    useEffect(() => {

        window.channel_user_notifications.listen('.video-chat.video-receive', (data) => {
            console.log(data.room_id, receivedCall, "TTTTTTT");
            if (data.room_id && receivedCall == false) {
                x = true;

                // setPutVideoCall({activate:true})
                console.log("!!res.data!!")
                console.log(data)
                setVideoRequest(data);
                console.log(videoRequest)
                setCallNotification(true);
                setReceivedCall(true);
                setSocket(true);
            }
        });

        if (receivedCall === true && callNotification === true && videoRequest) {
            handleAnswerCall();
            setCallNotification(false);
        }

    }, [socket]);

    useEffect(() => {

        if (settings.user && putVideoCall.activate === true) {
            console.log("loadVideoRequest")
            loadVideoRequest();
        }
    }, [putVideoCall.activate])
    // function CallNotification(props){
    //     console.log("CallNotification_inside")


    const call_msg = () => {


        return (
            <div className="callCompoent">
                <div className="caller">{props.name}</div>
                <div className="callee_buttons">
                    {/*<Button className="answer_button" onClick={props.handleReceiveCall}><CallIcon/></Button>*/}
                    <Button className="hangup_button" onClick={props.handleReceiveCall}><CallEndIcon/></Button>


                </div>
            </div>
        )
    }


    const loadVideoRequest = async () => {
        if (Object.keys(videoRequest).length === 0) {
            axios.post(constants.LocalUrl + '/data/requestVideoCall', {from: settings.user.id, to: putVideoCall.uid})
                .then(res => {
                    // const messageList = res.data;
                    setVideoRequest(res.data);
                    connectToVideoChannel(res.data.room_id);
                    // createVideoPlayer(res.data);
                })
        }
    }

    const connectToVideoChannel = (room_id) => {
        if (!channel) {
            let ch = window.Echo.join("video." + room_id)
                .here(users => {
                    setChannelUsers(users);
                    // this.channel.whisper('typing', {
                    //     typing: true
                    // });
                })
                .joining(user => {
                    setChannelUsers((channelUsers) => [...channelUsers, user]);
                })
                .leaving(user => {
                    let chUsers = channelUsers.filter(u => u !== user);
                    setChannelUsers(chUsers);
                })
                .listen('.videocall.end', (data) => {
                    setReceivedCall(false);
                    setPutVideoCall({activate: false});
                    toast.dismiss(toastId.current);
                });
            setChannel(ch);
        }
    }

    const handleFloatingWindow = () => {
        setDisableDrag(!disableDrag)
        setEnableResize(!enableResize)
        setFloatLimits(floatLimits == "parent" ? "window" : "parent")
    }

    const handleAnswerCall = () => {
        setPutVideoCall({activate: true})
        toast.dismiss(toastId.current)
        connectToVideoChannel(videoRequest.room_id);
    }

    const handleCancelCall = () => {
        setReceivedCall(false);
        handleCloseCall();
    }

    const handleCloseCall = () => {
        axios.post(constants.LocalUrl + '/video/close', {})
            .then(res => {
                setPutVideoCall({activate: false})
                toast.dismiss(toastId.current);
                setReceivedCall(false);
            })
    }

    const handleMinimize = () => {
        setMinimize(!minimize);
    }

    // const handleVideoCall = () => {
    //     setPutVideoCall({activate: false});
    // }

    let videoCallWrapper;
    if (minimize === true) {
        videoCallWrapper = "videoCallWrapper minimize"
    } else {
        videoCallWrapper = "videoCallWrapper"
    }

    if (floatLimits == "window") {
        videoCallWrapper = "videoCallWrapper float"
    }

    const renderVideoButtons = () => (
        <div style={{margin: '15px 0', background: "#fff", borderRadius: "20px", padding: "8px"}}>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Button className="close_call_button" onClick={() => {
                    handleCloseCall()
                }}><CallEndIcon/></Button>
            </div>
        </div>
    )

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0",
        position:"fixed"
    };
    // console.log("CallNotification")
    // console.log(receivedCall,videoRequest,settings)
    // if(receivedCall === true){
    //
    //     return <CallNotification />;
    //
    // }
    // if(receivedCall === true){
    //     setReceivedCall(false)
    // }

    if (putVideoCall.activate === false || Object.keys(videoRequest).length == 0 || !settings) {
        return "";
    }
    // console.warn("PPPPPPPPPPPP "+Object.keys(videoRequest).length
    // )
    // else if(!videoRequest.room_id){
    //
    //     return "";
    // }
    // else if(answer === false){
    //     return "";
    // }
    const tabs = {
        titles: ['PARTICIPANTS', 'CHAT'],
        content: [<div className="participants_body">
            <VideoChatParticipants videoRequest={videoRequest} channelUsers={channelUsers}/>
        </div>, <div className="participants_body">
            <VideoChatWindow room_id={videoRequest.room_id} user_id={settings.user.id}
                             to_user_id={putVideoCall.uid}/>
        </div>],
    };

    return (

        <div className={videoCallWrapper}>
            <Grid container spacing={2} style={{maxWidth: "80%", margin: "0 auto", display: "block"}}>

                <Grid item xs={12} className="video_header">
                    <div className="logo_video">
                        {/*<Logo src={settings.logo} alt={t('alt_Logo')} width="75px"/>*/}
                        {t('Video_Call_title')}
                    </div>
                    <div className="video_window_controls">
                        <span className="floating_call" onClick={() => handleFloatingWindow()}><FeaturedVideoIcon/></span>
                        <span onClick={() => handleMinimize()}>{minimize === false ? <MinimizeIcon/> :
                            <FullscreenIcon/>}</span>
                        {/*<span onClick={() => handleCloseCall()}><CloseIcon/></span>*/}
                    </div>
                </Grid>

                <Grid item xs={12} className="video_content" style={{height: "87%", overflowY: "auto"}}>

                    <Grid item xs={8} className="video_display">

                        <Rnd
                            lockAspectRatio="16/9"
                            bounds={floatLimits}
                            lockAspectRatioExtraHeight={50}
                            disableDragging={disableDrag}
                            enableResizing={enableResize}
                            style={style}
                            className={floatLimits == "parent" ? "normalPosition" : "dragPosition"}
                            default={{
                                x: 0,
                                y: 0,
                                // width: "100%",
                                // height: "100%",
                                // height: 320*9/16
                            }}
                        >
                            <div className="float_controls" style={{display:"flex",justifyContent:"space-between"}}>
                                {t('Video_Call_title')}
                                <span style={{position:"relative",left:"-9px"}} onClick={() => handleFloatingWindow()}><FeaturedVideoIcon/></span>
                            </div>
                            <Jitsi room_id={videoRequest.room_id} meet_url={videoRequest.meet_url}
                                   jwt={videoRequest.jwt} handleCloseCall={handleCloseCall}/>
                        </Rnd>


                    </Grid>
                    <Grid item xs={4} className="chat_display" style={{marginRight: "0"}}>
                        <>
                            {renderVideoButtons()}
                            <div className="card"
                                 style={{borderRadius: '20px', marginBottom: '20px', paddingBottom: '17px'}}>
                                <CTabsAutoScroll titles={tabs.titles} contents={tabs.content}></CTabsAutoScroll>
                            </div>
                        </>
                        {/*<div className="right-column" style={{flex: "0 0 18%"}}>*/}
                        {/*<Grid item xs={12} className="video_participants">*/}
                        {/*    <div className="card"*/}
                        {/*         style={{borderRadius: '20px', marginBottom: '20px', paddingBottom: '17px'}}>*/}
                        {/*        <h6 className="card-title" style={{*/}
                        {/*            fontSize: '10px',*/}
                        {/*            textAlign: 'center',*/}
                        {/*            fontWeight: '700',*/}
                        {/*            marginTop: '10px'*/}
                        {/*        }}>{t("Participants")}</h6>*/}
                        {/*        <div className="participants_body">*/}
                        {/*            <ul>*/}
                        {/*                <li style={{textAlign: "left"}}>*/}
                        {/*                    <img style={{borderRadius: "50%", marginRight: "20px", height:"45px", width:"45px"}}*/}
                        {/*                         src={videoRequest.from.user_img} alt=""/>*/}
                        {/*                    {videoRequest.from.name}*/}
                        {/*                    ({channelUsers.includes(videoRequest.from.id) ? 'joined' : 'not joined'})*/}
                        {/*                </li>*/}
                        {/*                <li style={{textAlign: "left",marginTop:"15px"}}>*/}
                        {/*                    <img style={{borderRadius: "50%", marginRight: "20px",height:"45px", width:"45px"}}*/}
                        {/*                         src={videoRequest.to.user_img} alt=""/>*/}
                        {/*                    {videoRequest.to.name}*/}
                        {/*                    ({channelUsers.includes(videoRequest.to.id) ? 'joined' : 'not joined'})*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} className="video_participants">*/}
                        {/*    <div className="card"*/}
                        {/*         style={{borderRadius: '20px', marginBottom: '20px', paddingBottom: '0'}}>*/}
                        {/*        <h6 className="card-title" style={{*/}
                        {/*            fontSize: '10px',*/}
                        {/*            textAlign: 'center',*/}
                        {/*            fontWeight: '700',*/}
                        {/*            marginTop: '10px'*/}
                        {/*        }}>{t("Chat")}</h6>*/}
                        {/*        <div className="participants_body">*/}
                        {/*            <VideoChatWindow room_id={videoRequest.room_id} user_id={settings.user.id}*/}
                        {/*                             to_user_id={putVideoCall.uid}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        {/*</div>*/}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )


}


export function Jitsi(props) {

    let videoRequest = {
        room_id: props.room_id,
        meet_url: props.meet_url,
        jwt: props.jwt,
    }

    const apiRef = useRef();
    const [logItems, updateLog] = useState([]);
    const [showNew, toggleShowNew] = useState(false);
    const [knockingParticipants, updateKnockingParticipants] = useState([]);

    const printEventOutput = payload => {
        updateLog(items => [...items, JSON.stringify(payload)]);
    };

    const handleAudioStatusChange = (payload, feature) => {
        if (payload.muted) {
            updateLog(items => [...items, `${feature} off`])
        } else {
            updateLog(items => [...items, `${feature} on`])
        }
    };

    const handleChatUpdates = payload => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        apiRef.current.executeCommand('toggleChat');
        updateLog(items => [...items, `you have ${payload.unreadCount} unread messages`])
    };

    const handleKnockingParticipant = payload => {
        updateLog(items => [...items, JSON.stringify(payload)]);
        updateKnockingParticipants(participants => [...participants, payload?.participant])
    };

    const resolveKnockingParticipants = condition => {
        knockingParticipants.forEach(participant => {
            apiRef.current.executeCommand('answerKnockingParticipant', participant?.id, condition(participant));
            updateKnockingParticipants(participants => participants.filter(item => item.id === participant.id));
        });
    };

    const handleJitsiIFrameRef1 = iframeRef => {
        iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '100%';
        iframeRef.style.width = '100%';
    };

    const handleJitsiIFrameRef2 = iframeRef => {
        iframeRef.style.marginTop = '10px';
        iframeRef.style.border = '10px dashed #df486f';
        iframeRef.style.padding = '5px';
        iframeRef.style.height = '100%';
        iframeRef.style.width = '100%';
    };

    const handleApiReady = apiObj => {
        apiRef.current = apiObj;
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
        apiRef.current.on('audioMuteStatusChanged', payload => handleAudioStatusChange(payload, 'audio'));
        apiRef.current.on('videoMuteStatusChanged', payload => handleAudioStatusChange(payload, 'video'));
        apiRef.current.on('raiseHandUpdated', printEventOutput);
        apiRef.current.on('titleViewChanged', printEventOutput);
        apiRef.current.on('chatUpdated', handleChatUpdates);
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
    };

    const handleReadyToClose = () => {
        /* eslint-disable-next-line no-alert */
        alert('Ready to close...');
    };

    const generateRoomName = () => `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;

    // Multiple instances demo
    const renderNewInstance = () => {
        if (!showNew) {
            return null;
        }

        return (
            <JitsiMeeting
                roomName={generateRoomName()}
                getIFrameRef={handleJitsiIFrameRef2}/>
        );
    };

    const renderVideoButtons = () => (
        <div style={{margin: '15px 0', background: "#fff", borderRadius: "20px", padding: "8px"}}>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Button className="close_call_button" onClick={() => {
                    props.handleCloseCall()
                }}><CallEndIcon/></Button>
            </div>
        </div>
    )
    const renderButtons = () => (
        <div style={{margin: '15px 0'}}>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <button
                    type='text'
                    title='Click to execute toggle raise hand command'
                    style={{
                        border: 0,
                        borderRadius: '6px',
                        fontSize: '14px',
                        background: '#000000',
                        color: '#040404',
                        padding: '12px 46px',
                        margin: '2px 2px'
                    }}
                    onClick={() => apiRef.current.executeCommand('toggleRaiseHand')}>
                    Raise hand
                </button>
                <button
                    type='text'
                    title='Click to approve/reject knocking participant'
                    style={{
                        border: 0,
                        borderRadius: '6px',
                        fontSize: '14px',
                        background: '#0056E0',
                        color: 'white',
                        padding: '12px 46px',
                        margin: '2px 2px'
                    }}
                    onClick={() => resolveKnockingParticipants(({name}) => !name.includes('test'))}>
                    Resolve lobby
                </button>
                <button
                    type='text'
                    title='Click to execute subject command'
                    style={{
                        border: 0,
                        borderRadius: '6px',
                        fontSize: '14px',
                        background: '#df486f',
                        color: 'white',
                        padding: '12px 46px',
                        margin: '2px 2px'
                    }}
                    onClick={() => apiRef.current.executeCommand('subject', 'New Subject')}>
                    Change subject
                </button>
                <button
                    type='text'
                    title='Click to create a new JitsiMeeting instance'
                    style={{
                        border: 0,
                        borderRadius: '6px',
                        fontSize: '14px',
                        background: '#3D3D3D',
                        color: 'white',
                        padding: '12px 46px',
                        margin: '2px 2px'
                    }}
                    onClick={() => toggleShowNew(!showNew)}>
                    Toggle new instance
                </button>
            </div>
        </div>
    );

    const renderLog = () => logItems.map(
        (item, index) => (
            <div
                style={{
                    fontFamily: 'monospace',
                    padding: '5px'
                }}
                key={index}>
                {item}
            </div>
        )
    );

    const renderSpinner = () => (
        <div style={{
            fontFamily: 'sans-serif',
            textAlign: 'center'
        }}>
            Loading..
        </div>
    );


    return (

        // <div>
            <JitsiMeeting
                domain={videoRequest.meet_url}
                roomName={videoRequest.room_id ? videoRequest.room_id : ""}
                spinner={renderSpinner}
                jwt={videoRequest.jwt}
                config={{
                    subject: 'lalalala',
                    hideConferenceSubject: false
                }}
                configOverwrite={{
                    subject: 'LiveOn Video Chat',
                    displayName: "",
                    startWithVideoMuted: false,
                    startWithAudioMuted: false,


                    toolbarButtons: ['microphone', 'camera', 'desktop', 'fullscreen', 'raisehand', 'tileview', 'select-background', 'mute-everyone', 'mute-video-everyone'
                        // '{!! implode("','",explode(",",omnius('JITSI_TOOLBAR_BUTTONS',[])))!!}'
                    ],
                    toolbarConfig: {
                        timeout: 60000 * 60,
                        initialTimeout: 60000 * 60,
                        alwaysVisible: true
                    },
                    enableClosePage: true,
                    disable1On1Mode: true,
                    disableProfile: true,
                    prejoinPageEnabled: false,
                    debug: true,

                    analytics: {
                        defaultLanguage: 'en'
                    },


                    userInfo: {
                        email: 'mail',
                        displayName: 'name',
                        avatarURL: 'img'
                    },
                    doNotStoreRoom: true
                }}
                interfaceConfigOverwrite={{
                    DEFAULT_BACKGROUND: "#000000",
                    DEFAULT_LOGO_URL: "",
                    POLICY_LOGO: "",
                    PROVIDER_NAME: 'LiveOn',
                    SHOW_BRAND_WATERMARK: false,
                    JITSI_WATERMARK_LINK: 'https://liveon.tech',
                    DEFAULT_REMOTE_DISPLAY_NAME: "LiveOn participant",
                    SHOW_JITSI_WATERMARK: false,
                    APP_NAME: "LiveOn Video Chat",
                    HIDE_INVITE_MORE_HEADER: true,
                    MOBILE_APP_PROMO: false,
                    LANG_DETECTION: false,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    TOOLBAR_TIMEOUT: 60000 * 60,
                    INITIAL_TOOLBAR_TIMEOUT: 60000 * 60,
                    TOOLBAR_ALWAYS_VISIBLE: true,
                }}
                onApiReady={externalApi => handleApiReady(externalApi)}
                onReadyToClose={handleReadyToClose}
                getIFrameRef={handleJitsiIFrameRef1}/>

        // </div>

    )
}


