import React, {useContext, useEffect, useState} from 'react';
import * as constants from "../../../../../../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageProvider from "../../../../../PageContext";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function RecoverPassword() {

    const {t, i18n} = useTranslation();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [formData, setFormData] = useState({
        'password': '',
        'new_password': '',
        'new_password_confirmation': ''
    });
    const [formErrors, setFormErrors] = useState(null);

    const {guser} = useContext(PageProvider);

    useEffect(() => {

        const loadUser = async () => {
            setLoading(true);
            console.log(guser)
            window.axios.post(constants.LocalUrl + '/data/user_full_data', {id: guser.id}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setUser(response.data[0]);
                setLoading(false);
            });
        }

        loadUser();

    }, []);

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const onSubmitForm = () => {
        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/update_user_password', formData).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                } else {
                    setFormData({
                        'password': '',
                        'new_password': '',
                        'new_password_confirmation': ''
                    });
                    toast.success(t('Password changed'), {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }
        setFormErrors(null);
        // Call the function
        loadItems();
    };


    const onInputchange = (event) => {
        event.persist();

        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));


    if (loading === true || user.length === 0) {
        console.log("asd")
        return <CircularProgress/>
    }

    return (

        <div style={{
            display: 'flex',
            justifyContent: 'center',
            background: "#fff",
            borderRadius: "20px",
            boxShadow: "-5px 3px 40px #5e5c9a1a"
        }}>
            <Grid container spacing={3} style={{padding: '20px', maxWidth: '100%'}}>


                <React.Fragment key={'right'}>

                    <Grid container spacing={3} style={{padding: '20px'}}>
                        <Grid item xs={12}>
                            <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left'}} gutterBottom>
                                {t('Password Recovery')}
                            </Typography>


                            <Divider light/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginLeft:"20px",marginBottom:"15px"}} alignLeft variant="h6" gutterBottom>
                                {t('Current Password')}
                            </Typography>
                            {/*<Typography alignLeft variant="subtitle1" gutterBottom>*/}
                            {/*    subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur*/}
                            {/*</Typography>*/}
                            <TextField id="filled-basic" type="password" variant="outlined" value={formData.password} name="password"
                                       onChange={onInputchange} fullWidth
                                       required error={hasError('password')} helperText={errorMessage('password')}
                                />

                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginLeft:"20px",marginBottom:"15px"}} alignLeft variant="h6" gutterBottom>
                                {t('New Password')}
                            </Typography>
                            {/*<Typography alignLeft variant="subtitle1" gutterBottom>*/}
                            {/*    subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur*/}
                            {/*</Typography>*/}
                            <TextField id="filled-basic" type="password" variant="outlined" value={formData.new_password} name="new_password"
                                       onChange={onInputchange} fullWidth
                                       required error={hasError('new_password')} helperText={errorMessage('new_password')}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginLeft:"20px",marginBottom:"15px"}} alignLeft variant="h6" gutterBottom>
                                {t('Confirm Password')}
                            </Typography>
                            {/*<Typography alignLeft variant="subtitle1" gutterBottom>*/}
                            {/*    subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur*/}
                            {/*</Typography>*/}
                            <TextField id="filled-basic" type="password" variant="outlined" value={formData.new_password_confirmation}
                                       name="new_password_confirmation" onChange={onInputchange} fullWidth
                                       required error={hasError('new_password_confirmation')} helperText={errorMessage('new_password_confirmation')}
                            />

                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid item xs={12} style={{textAlign:"right",paddingRight:"20px"}}>
                        <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary"
                                component="span">{t('SAVE')}</Button>
                    </Grid>
                    {/*</Grid>*/}
                </React.Fragment>
            </Grid>
        </div>
    )

}