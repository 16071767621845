import React, {useContext, useEffect, useState} from "react";
import * as constants from "../../common/Constants";
import {Loader} from "semantic-ui-react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";
import FileUploadComponent from "../../common/FileUploadComponent";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from '@material-ui/core/styles';
import CTabsAutoScroll from "../../../common/CTabsAutoScroll";
import CAccordionControlled from "../../../common/CAccordionControlled";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export default function OndemandDashboard() {
    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);

    const [ondemand, setOndemand] = useState(null);

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        select : {
            width: '400px'
        }
    }));
    const classes = useStyles();

    if (settings != undefined && Object.keys(settings).length == 0 && !settings.setup_object.On_Demand) {
        return <>
            <h3>{t('Ondemand not purchased')}</h3>
            <div>{t('Ondemand not purchased info')}</div>
        </>
    }

    const loadData = () => {
        axios.post(constants.LocalUrl + '/data/get_ondemand_videos_for_panel').then((response) => {
            setOndemand(response.data);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (!ondemand) {
        return <Loader></Loader>
    }

    const stageVideoHandler = (event, stream) => {
        let name = event.target.name;
        let temp = {...ondemand};
        temp.stage[stream] = {...temp.stage[stream], [name]: event.target.value};
        setOndemand(temp);
    }

    const sessionVideoHandler = (event, si, stream) => {
        let name = event.target.name;
        let temp = {...ondemand};
        temp.sessions[si][stream] = {...temp.sessions[si][stream], [name]: event.target.value};
        setOndemand(temp);
        saveOndemand();
    }

    const saveOndemand = () => {
        axios.post(constants.LocalUrl + '/data/set_ondemand_videos', {'ondemand': ondemand}).then(response => {
            console.info(response.data);
        });
    }

    if (settings && Object.keys(settings.setup_object).length == 0) {
        return <Loader />
    }

    if (ondemand.titles.length == 0) {
        return <div>
            {t('No streams to set ondemand for')}
        </div>
    }

    if (ondemand.sessions.length == 0) {
        return <div>
            {t('No sessions to set ondemand for')}
        </div>
    }

    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"658px", height:"658px", overflowX: "hidden"}} >

        <div style={{overflowY: 'auto', padding: '2px'}}>
            {console.log("sessionVideoHandler",ondemand)}
            <CTabsAutoScroll addclass="settings_video_ondemand_wrapper" titles={ondemand.titles} contents={

                ondemand.titles.map((title, ti) =>
                    <CAccordionControlled
                        titles={Object.keys(ondemand.sessions).map((si) => <>
                            {
                                (ondemand.sessions[si][ti].video.trim().length > 0
                                ? <CheckIcon style={{position: "relative", top: -2, color: 'green'}}></CheckIcon>
                                : <CloseIcon style={{position: "relative", top: -2, color: 'red'}}></CloseIcon>)
                            }
                            {' ' + ondemand.sessions[si][ti].title+' ('+title+')'}
                            </>
                        )}
                        contents={Object.keys(ondemand.sessions).map((si) => <>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs={6} style={{paddingRight:"15px"}}>
                                    <Typography alignLeft variant="h6" style={{display: 'block'}} gutterBottom>
                                        {t('Video Stream')}
                                    </Typography>
                                    <Typography alignLeft variant="h7" style={{display: 'block',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                                        {t('Video Stream Type')}
                                    </Typography>
                                    <FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}} fullwidth>
                                        {/*<InputLabel id={'session_typevideo_label' + si}>TYPE_VIDEO</InputLabel>*/}

                                        <Select
                                            label=""
                                            labelId={'session_typevideo_label' + si}
                                            name="type_video"
                                            value={ondemand.sessions[si][ti].type_video}
                                            onChange={(e) => sessionVideoHandler(e, si, ti)}
                                            fullWidth
                                        >
                                            <MenuItem value="">
                                                <em>{t('None')}</em>
                                            </MenuItem>
                                            <MenuItem value={'youtube'}>{t('YOUTUBE ID')}</MenuItem>
                                            <MenuItem value={'vimeo'}>{t('VIMEO ID')}</MenuItem>
                                            {/*<MenuItem value={'local'}>LOCAL</MenuItem>*/}
                                            {/*<MenuItem value={'facebook'}>FACEBOOK</MenuItem>*/}
                                        </Select>
                                    </FormControl>
                                    <Typography alignLeft variant="h7" style={{display: 'block',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                                        {t('Video Stream Id')}
                                    </Typography>
                                    <FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}} fullwidth>
                                        <TextField  onChange={(e) => sessionVideoHandler(e, si, ti)} id="outlined-basic" label="" variant="outlined"
                                                    value={ondemand.sessions[si][ti].video} name="video" fullWidth/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} style={{paddingLeft:"20px"}}>
                                    {/*<h2>{si}: {ti}: {ondemand.sessions[si][ti].title}:{title}</h2>*/}
                                    <Typography alignLeft variant="h6" style={{display: 'block',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                                        {t('Video Stream Thumbnail')}
                                        <span className="image_text_description">{t('upload_dimensions', {text: '150px * 150px'})}</span>
                                    </Typography>
                                    <FileUploadComponent image={ondemand.sessions[si][ti].video_image} extra={{action: "/data/addOndemandImage", 'for': 'session', 'session_id': ondemand.sessions[si][ti].session_id, 'stream': ti, 'fieldname': 'video_image'}}
                                                         accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                         maxWidth={150} maxHeight={150}
                                    />
                                    {/*<CTextField handler={(e) => sessionVideoHandler(e, si, ti)} label="VIDEO_IMAGE" value={ondemand.sessions[si][ti].video_image} name="video_image"/>*/}
                                </Grid>
                            </Grid>
                        </>)}
                    ></CAccordionControlled>
                )
            }
            ></CTabsAutoScroll>

        </div>
        </SimpleBar>
    )
}
