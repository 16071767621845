import React, {Component, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import * as constants from "../../Panel/common/Constants"
import {NavLink} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import {Stage} from "../../Panel/tabs/Stage";
import InlineTranslate from "../../common/Language/InlineTranslate";
import FrontEmptyDiv from "../../common/FrontEmptyDiv";
import PageProvider from "../PageContext";
import Loader from "../../common/Loader";

export default function Speakers() {

    const {t, i18n} = useTranslation();
    const [persons, setPersons] = useState([]);
    const [loading, setLoading] = useState(false);
    const {settings, setSettingsWindow} = useContext(PageProvider);


    useEffect(() => {
        setLoading(true)
        axios.post(constants.LocalUrl + '/data/get_start_page_speakers_ui')
            .then(res => {
                const persons = res.data;
                setPersons(persons);
                setLoading(false)
            })
    }, [])

    if(loading === true){
        return <Loader />
    }

    return (

        <>

            <section id="speakers" className="section align-center" style={{padding: 0, marginTop: "70px"}}>
                <div className="container">
                    <div style={{textAlign: 'center'}}>
                            <span className="icon section-icon icon-faces-users-04"
                                  style={{display: 'block', marginBottom: '20px', fontSize: '50px', lineHeight: 1}}/>
                        <h3 style={{
                            fontSize: '50px',
                            letterSpacing: '-0.05em',
                            fontWeight: 400,
                            marginBottom: '10px'
                        }}>
                            {t('Speakers')}
                        </h3>

                        {persons.length === 0 ?
                            <FrontEmptyDiv className={"container agenda-wrapper agenda tab-content"}>
                                <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
                                <br/>
                                <br/>

                                {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator")) ? <>
                                    <span
                                        dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_1')}}></span>
                                    <span className="set_pointer url_alike" onClick={() => setSettingsWindow({
                                        activate: true,
                                        tab: 5
                                    })}><b>{t("USERS")}</b></span>
                                    <span
                                        dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_2')}}></span><span
                                    className="set_pointer "><b>{t("SETTINGS")}</b></span>.</> : null}
                            </FrontEmptyDiv> :
                            <>
                                <br/>
                                <br/>
                                <div className="content row">
                                    {persons
                                        .map(person => <>
                                                <div className="col-sm-4" id={person.id}>
                                                    <div className="speaker"
                                                         style={{marginBottom: '40px', textAlign: 'center'}}>
                                                        <div className="photo-wrapper rounded" style={{
                                                            margin: '0 auto 7px',
                                                            display: 'inline-block',
                                                            maxWidth: '100%',
                                                            borderRadius: '50%',
                                                            overflow: 'hidden'
                                                        }}><img src={person.user_img} alt={person.title}
                                                                className="img-responsive"
                                                                style={{display: 'block', maxWidth: '100%'}}/></div>
                                                        <h3 className="name" style={{
                                                            color: '#404040',
                                                            letterSpacing: '0em',
                                                            textTransform: 'none',
                                                            fontSize: '24px',
                                                            fontWeight: '500',
                                                            lineHeight: '25px',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            marginBottom: "7px"
                                                        }}><NavLink className="item"
                                                                    to={constants.LocalUrl + "/profile/" + person.id}>{person.title}</NavLink>
                                                        </h3>
                                                        <p className="text-alt" style={{
                                                            color: '#B1B1B1',
                                                            fontSize: '30px',
                                                            margin: '0 0 10px',
                                                            width: '80%',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            marginBottom: "5px"
                                                        }}><small style={{fontSize: '20px',lineHeight:"1.2285em"}}>{person.jobtitle}</small></p>
                                                        <p key={person.id} className="about" style={{
                                                            margin: '0 0 10px',
                                                            padding: '0 15px',
                                                            fontWeight: '700',
                                                            fontSize: '18px',
                                                            color: '#808080',
                                                            lineHeight: '20px'
                                                        }}>{person.company}</p>

                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </div>
                            </>
                        }


                    </div>
                </div>
            </section>


        </>
    );

}

