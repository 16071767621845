import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import * as constants from "../../../../Panel/common/Constants"
import CTextField from "../../../../Panel/common/CTextField";
import {ChromePicker} from "react-color";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import {ReactSVG} from "react-svg";
import Divider from "@material-ui/core/Divider";
import LanguageChangerForPanel from "../../../../common/Language/LanguageChangerForPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";


export default function LoadedSponsor(props)  {

    const {t, i18n} = useTranslation();
    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [sponsorCategoryLocale, setSponsorCategoryLocale] = useState([]);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [formErrors, setFormErrors] = useState(null);


    const handleChange = (event) => {
        setSponsorcategory(event.target.value);
        event.persist();

        setSponsor((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    useEffect(() => {
        setLocaleData(sponsor);
    },[localeLanguage])

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null){
                localeData[value] = data[value][localeLanguage];
            }else{
                localeData[value] = data[value];
            }
        })
        console.log("localeData",localeData,localeLanguage)
        setSponsorCategoryLocale(localeData)
    }


    const thid =  props;
    useEffect(() => {
        const loadSponsor = async () => {
            setSponsorid(props.props);
            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + '/data/sponsor_category_full_data', {id: thid,type:"panel"}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setSponsor(response.data);
                setLocaleData(response.data)

                // Closed the loading page
                setLoading(false);
            });
        }

        // Call the function
        loadSponsor();
    }, []);

    const onSubmitForm = () => {
        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/update_sponsor_category',{...sponsorCategoryLocale, lang: localeLanguage}).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }else {
                    let index_id;
                    const i = props.posts.map((single_post, index) => {
                            if (single_post.id === response.data.data.id) {
                                index_id = index;
                                return true;
                            }
                        }
                    );

                    let items = [...props.posts];
                    let item = {...items[index_id]};
                    item.title = response.data.data.title;
                    items[index_id] = item;

                    props.triggerdataload(items);
                    props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    const onInputchange = (event) => {
        event.persist();

        if(typeof sponsor[event.target.name] === 'object'){
            console.log('object')

            setSponsor((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));
        }else{
            console.log('nope')
            setSponsor((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }));

        }

        setSponsorCategoryLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    };

    const createSponsorCategory = async (event) => {
        // console.log(event);
        var newcategory = $('#outlined-basic').val();
        await axios.post(
            constants.LocalUrl + '/data/create_sponsor_category',{cat:newcategory}).then(response => {
            setSponsorcategories(response.data);
        });
    };


    const handleCategoryColor = (data) => {
        setSponsor((prevState) => ({
            ...prevState,
            category_color: data.hex,
        }));

        setSponsorCategoryLocale((prevState) => ({
            ...prevState,
            category_color: data.hex,
        }));
    }

    let category_icons = {
        1 : "/app/award-1.svg",
        2 : "/app/award-2.svg",
        3 : "/app/award-3.svg",
        4 : "/app/award-4.svg",
        5 : "/app/award-5.svg",
    };

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    if(loading === true || sponsor.length == 0){
        return <Loader />
    }



    return (
        <div className="bottom_bar">
            {/*<Grid container spacing={3} style={{padding:'20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)} className={close_button.root} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            {/*{changeLanguage()}*/}
            <Typography aligncenter variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Sponsor Category')}
            </Typography>
            <Typography alignLeft variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{sponsorCategoryLocale.title_single}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
                <React.Fragment key={'right'}>
                    <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                        <Grid item xs={6}>
                            <CTextField value={sponsorCategoryLocale.title_single?sponsorCategoryLocale.title_single:""} handler={onInputchange} name='title_single' label={t('Title single')} required error={hasError('title_single')} helperText={errorMessage('title_single')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField value={sponsorCategoryLocale.title_plural?sponsorCategoryLocale.title_plural:""} handler={onInputchange} name='title_plural' label={t('Title single')} required error={hasError('title_plural')} helperText={errorMessage('title_plural')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor_category_color')}:
                            </Typography>
                            <ChromePicker color={sponsor.category_color?sponsor.category_color:""} onChange={handleCategoryColor} disableAlpha />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography alignLeft variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor_category_icons')}
                            </Typography>

                            <Select IconComponent={ExpandMoreIcon} style={{borderRadius:'23px'}} onChange={onInputchange} className="category_icon" id="grouped-native-select" value={sponsorCategoryLocale && sponsorCategoryLocale.category_icon?sponsorCategoryLocale.category_icon:""} variant="outlined" fullWidth  name="category_icon" >
                                {Object.keys(category_icons).map(data => (
                                    <MenuItem value={data}>
                                        <ReactSVG beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name');
                                            svg.setAttribute('style', 'width:35px');
                                        }} src={category_icons[data]}/>
                                    </MenuItem>
                                ))}
                            </Select>

                        </Grid>
                        <PanelBottomBar close={props.triggerdrawer(false)} submit={onSubmitForm} language={setLocaleLanguage}/>

                        {/*<Grid className="panel_utilities_element" item xs={12}>*/}
                        {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                        {/*    <div className="right_part">*/}
                        {/*        {changeLanguage()}*/}
                        {/*        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>
                </React.Fragment>
            </div>
        </div>

    );

}

