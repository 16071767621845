import request from '../../../utils/request'

function get(locale) {
    return request({
        url:    `/data/get_all_users`,
        method: 'POST',
        data: {lang:locale}
    });
}

function speakers(locale) {
    return request({
        url:    `/data/get_all_speakers`,
        method: 'POST',
        data: {lang:locale}
    });
}

function coordinators(locale) {
    return request({
        url:    `/data/get_all_coordinators`,
        method: 'POST',
        data: {lang:locale}
    });
}


function create({subject, content}) {
    return request({
        url:    '/message/create',
        method: 'POST',
        data:   {
            subject,
            content
        }
    });
}

const MessageService = {
    get, speakers, coordinators //, update, delete, etc. ...
}

export default MessageService;