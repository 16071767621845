import React, {useEffect, useState} from 'react';
import axios from 'axios';
import * as constants from "../../../Panel/common/Constants";
import Loader from "../../../common/Loader";
import {makeStyles} from '@material-ui/core/styles';
import StageVideo from "./StageVideo"
import StageQuestions from "./StageQuestions"
import StageIndividualSessionCard from "./StageIndividualSessionCard"
import {ReactSVG} from "react-svg";
import StageSessions from "./StageSessions";
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import CloseIcon from '@material-ui/icons/Close';
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import MenuIcon from "@material-ui/icons/Menu";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createMedia} from "@artsy/fresnel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";


const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function StageMobile(props) {

    const {t, i18n} = useTranslation();
    var icons = {
        stage:"/images/menu_stage.svg",
        info:"/images/Mobile_Stage_Info.svg",
        questions:"/images/Mobile_Stage_Questions.svg",
        speakers:"/images/Mobile_Stage_Speakers.svg",
        polls:"/images/Mobile_Stage_Polls.svg",
    };

    const [loading,setLoading] = useState(false);
    const [sponsors,setSponsors] = useState([]);
    // const [hosts,setHosts] = useState([]);
    const [questions,setQuestions] = useState([]);
    const [question,setQuestion] = useState("");
    const [stage,setStage] = useState(null);
    const [stageStatus,setStageStatus] = useState(null);
    const [streamcode,setStreamcode] = useState(0);
    const [code,setCode] = useState(null);

    const [listensStatus,setListensStatus] = useState(null);
    const [demandSession,setDemandSession] = useState(null);
    const [listensQuestion,setListensQuestion] = useState(null);
    const [showMini,setShowMini] = useState(false);
    const [hasMini,setHasMini] = useState(false);
    const [openMinidial,setOpenMinidial] = useState(false);
    const [position,setPosition] = useState(false);

    const useStyles = makeStyles((theme) => ({
        speedDial: {
            position: 'absolute',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
        },
    }));
    const classes = useStyles();

    const actions = [
        { icon: <MinimizeIcon/>, name: 'Minimize'},
        { icon: <MaximizeIcon/>, name: 'Maximize'},
        { icon: <CloseIcon/>, name: 'Close'},
        { icon: <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:20px');}} src={constants.LocalUrl + icons.stage} />, name: 'Back to stage'},
    ];

    const actionShow = (action_name) => {
        if (action_name == actions[0].name) { return showMini; }
        else if (action_name == actions[1].name) { return !showMini }
        else if (action_name == actions[2].name) { return true; }
        else if (action_name == actions[3].name) { return true; }
        else return true;
    }

    const navigate = useNavigate();
    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }
    var location = usePathname();
    var strArray = location.split("/");
    var lposition = strArray[strArray.length - 1];

    useEffect(() => {
        console.log('lposition', lposition);
        if (!hasMini && lposition == 'stage') {
            setHasMini(true);
            setShowMini(true);
            jQuery('body').addClass('ministage_'+'Maximize');
        }
        setPosition(lposition);
    }, [lposition]);

    useEffect(() => {

        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + "/data/get_stage_sponsors");


            // After fetching data stored it in posts state.
            setStage(response.data.stage);
            setStageStatus(response.data.stage.status);
            setSponsors(response.data.sponsors);

            // setHosts(response.data.hosts);
            setCode(response.data.stage.stream_code)
            const questions = await axios.post(
                constants.LocalUrl + "/data/get_questions");

            let t_questions = questions.data;
            t_questions = t_questions.sort(sortQuestionsByDate);
            setQuestions(t_questions);
            // Closed the loading page
            setLoading(false);
        }
        // Call the function
        loadPost();

        if (!listensStatus) {
            window.channel_stage.listen('.stage.change_status', (data) => {
                console.log('listened .stage.change_status');
                setStage(data.all.stage);
                setStageStatus(data.all.stage.status);
            });
            setListensStatus(true);
        }
        if (!listensQuestion) {
            window.channel_stage.listen('.stage.reload_questions', (data) => {
                console.log('listened .stage.reload_questions');
                let t_questions = data.questions;
                t_questions = t_questions.sort(sortQuestionsByDate);
                setQuestions(t_questions);
            });
            setListensQuestion(true);
        }
    }, []);

    const handleQuestionData = (event) =>  {
        setQuestion(event.target.value);
    }

    const sortQuestionsByDate = (a, b) => {
        return new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime();
    }

    const toggleChildMenu = (attached,sponsor_id) => () => {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
        console.log(drawerstate);
    };
    const sendQuestion = () => {

        window.axios.post(constants.LocalUrl + '/data/save_question',{question:question}).then((response) => {
            let t_questions = response.data;
            t_questions = t_questions.sort(sortQuestionsByDate);
            setQuestions(t_questions);
        })
        console.log(question)
    }

    const changeStream = (event) => {
        setStreamcode(event.target.attributes.stream.nodeValue)
        console.log(event.target.attributes.stream.nodeValue)
    }

    const changeDemandSession = (demandSession) => {
        setDemandSession(demandSession)
    }

    const handleOpenMinidial = () => {
        setOpenMinidial(!openMinidial);
    };

    const handleMinidialAction = (e, action_name) => {
        actions.map((action) => {
            jQuery('body').removeClass('ministage_'+action.name);
        })
        if (action_name == actions[0].name) {
            jQuery('body').addClass('ministage_'+action_name);
            setShowMini(false)
        }
        else if (action_name == actions[1].name) {
            jQuery('body').addClass('ministage_'+action_name);
            setShowMini(true)
        }
        else if (action_name == actions[2].name) {
            setHasMini(false); setShowMini(false); setOpenMinidial(false);
        }
        else if (action_name == actions[3].name) {
            navigate('/stage')
        }
        // setOpenMinidial(false);
    };

    if (lposition == 'stage' && props.position == 'footer') {
        return null;
    }

    if(loading === true && code === null){
        return  <Loader/>
    }


        return (
            <div className={ ( stageStatus && stageStatus == 1 && hasMini && position != 'stage' ) ? 'ministage_main_outer_container' : ''}>
                <div className="stage mobile container" data-status={stageStatus} data-showmini={showMini?'y':'n'}>

                    {
                        position == 'stage'
                            ?
                            <MediaContextProvider>
                                <Media at='mobile'>
                                    <header className="page_header" style={{margin:"0 auto",marginBottom:"27px",marginTop:"23px",color:"rgba(0,0,0,0.87)"}}>{t('STAGE')}</header>
                                </Media>
                            </MediaContextProvider>
                            : null
                    }

                    {
                        stageStatus && stageStatus == 1 && hasMini && position != 'stage'
                            ? <div>
                                {actions.filter((action) => actionShow(action.name)).map((action) => (
                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={(e)=>handleMinidialAction(e, action.name)}>
                                        {action.icon}
                                    </IconButton>
                                ))}
                            </div>
                            : <></>
                    }
                    {
                        position == 'stage'
                            ?
                            <div className="row">
                                <div className="col-md-12 stream_picker" style={{}}>
                                    {/*<ul className="nav nav-tabs card-header-tabs left-top-header" style={{backgroundColor: '#ffffff'}}>*/}
                                    {/*<div className="btn-group" role="group" style={{display: 'flex'}}>*/}
                                    {/*    {stage && Object.values(stage['stream_code']).map((stream,key) => (*/}
                                    {/*        stream['name'].length > 0?<button stream={key} onClick={changeStream} className="btn btn-light" type="button" style={{fontWeight: 'bold', fontFamily: 'Ubuntu, sans-serif', marginBottom: '23px', marginTop: '25px', marginLeft: '35px', marginRight: '8px', width: '126px', color: 'black', height: '43px', borderRadius: '22px', filter: 'drop-shadow(0px 4px 2px rgba(62,73,84,0.04))', backgroundColor: '#ffffff', fontSize: '16px'}}>{stream['name']}</button>:""*/}
                                    {/*    ))}*/}
                                    {/*</div>*/}
                                    <StageSessions stage={stage} changeStream={changeStream}/>
                                    {/*</ul>*/}

                                </div>
                            </div>
                            : null
                    }
                    <div className="row" style={{bsGutterX: '0rem',justifyContent:'center'}}>
                        {/*<div className="col-md-12"><iframe allowFullScreen frameBorder={0} src="https://www.youtube.com/embed/SsEizVg9ZR4" width="100%" style={{minHeight: '379px'}} /></div>*/}
                        { ((stageStatus && stageStatus == 1 && position != 'stage' && hasMini && showMini) || position == 'stage') ? <StageVideo video={streamcode} streams={code} stage={stage} demandSession={demandSession} style={{width:'100%'}}/> : <></> }
                    </div>
                    {position == 'stage' && stageStatus && stageStatus == 1 && stage.has_questions_form ?
                        <div className="row">
                            <div className="col" style={{padding: 0}}>
                                <div>
                                    <ul className="nav nav-tabs stage-tabs" role="tablist" style={{display: 'flex', justifyContent: 'center', borderBottom: 'none', marginTop: '19px', marginLeft: '6px', marginRight: '6px'}}>
                                        <li className="nav-item active" role="presentation" style={{backgroundColor: 'transparent'}}><a className="nav-link active" role="tab" data-bs-toggle="tab" href="#tab-1" style={{border: 'none', marginRight: '15px', padding: 0, backgroundColor: 'transparent'}}><ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:62px');}} src={constants.LocalUrl + icons.speakers} /></a></li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" role="tab" data-bs-toggle="tab" href="#tab-2" style={{border: 'none', marginRight: '15px', padding: 0, backgroundColor: 'transparent'}}><ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:62px');}} src={constants.LocalUrl + icons.questions} /></a></li>
                                        {/*<li className="nav-item" role="presentation"><a className="nav-link" role="tab" data-bs-toggle="tab" href="#tab-3" style={{border: 'none', marginRight: '15px', padding: 0, backgroundColor: 'transparent'}}><ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:27px');}} src={constants.LocalUrl + icons.polls} /></a></li>*/}
                                        <li className="nav-item" role="presentation"><a className="nav-link" role="tab" data-bs-toggle="tab" href="#tab-4" style={{border: 'none', marginRight: '15px', padding: 0, backgroundColor: 'transparent'}}><ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:62px');}} src={constants.LocalUrl + icons.info} /></a></li>
                                    </ul>
                                    <div className="tab-content" style={{margin: '11px', borderRadius: '20px', backgroundColor: 'white', paddingBottom: '17px'}}>
                                        <div className="tab-pane active" role="tabpanel" id="tab-1">
                                            <StageIndividualSessionCard stage={stage} changeStream={changeStream}/>
                                        </div>
                                        <div className="tab-pane" role="tabpanel" id="tab-2">
                                            <StageQuestions stage={stage} stageStatus={stageStatus} question={question} handleQuestionData={handleQuestionData} sendQuestion={sendQuestion}/>
                                        </div>
                                        {/*<div className="tab-pane" role="tabpanel" id="tab-3">*/}
                                        {/*    <p>Content for tab 3.</p>*/}
                                        {/*</div>*/}
                                        <div className="tab-pane" role="tabpanel" id="tab-4">
                                            {
                                                stageStatus != null && stageStatus == 1 && stage.has_questions_form == 1
                                                    ? <>
                                                        <div className="payer-info" style={{display: 'flex', padding: '23px 36px',backgroundColor:"transparent"}}><img style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"} />
                                                            <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}} dangerouslySetInnerHTML={{__html:  stage?stage.msg_live:""}}/><br />
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                            {
                                                stageStatus != null && stageStatus == 2
                                                    ? <>
                                                        <div className="payer-info" style={{display: 'flex', padding: '23px 36px',backgroundColor:"transparent"}}><img style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"} />
                                                            <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}} dangerouslySetInnerHTML={{__html:  stage?stage.msg_ended:""}}/><br />
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>:""}
                </div>
            </div>
        );

}
