import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as constants from "../../../common/Constants"
import UserFields from "../../../common/UserFields";
import {toast} from "react-toastify";
import FileUploadComponent from "../../../common/FileUploadComponent";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import {FormHelperText} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import {InputTextHandler} from "../../../common/InputTextHandler";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

export default function NewUser(props)  {

    const {t, i18n} = useTranslation();
    const thid =  props;
    const [user, setUser] = useState([]);
    const [countries, setCountries] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pid, setPid] = useState(null);
    const [checkbox, setCheckbox] = useState({
        acceptpolicy : false
    });
    const [error, setError] = useState([]);
    const checkboxes_style = useedStyles();
    const [optionsState, setOptionsState] = useState([
        {key: 'Coordinator', title: t('Coordinator'), id: 2},
        // {title: t('Attendee'), id: 3},
        {key: 'Speaker', title: t('Speaker'), id: 4},
        {key: 'Executive', title: t('Executive'), id: 5},
        // {key: 'Hidden', title: t('Hidden'), id: 7},
        {key: 'Administrator', title: t('Administrator'), id: 6},
    ]);
    const {handleDraggableListItemInState} = InputTextHandler({state: user, setState: setUser});

    useEffect(() => {
        const loadUser = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            // await axios.post(`/Lite/data/user_full_data`, {id: thid}).then(response => {
            //     console.log('response', response);
            //     // After fetching data stored it in posts state.
            //     setUser(response.data);
            //
            //     // Closed the loading page
            //     setLoading(false);
            // });

            await axios.post(constants.LocalUrl + '/data/get_all_countries', {id: thid}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setCountries(response.data);

                // Closed the loading page

            });

            await axios.post(constants.LocalUrl + '/data/get_all_timezones').then((response) => {
                setTimezones(response.data);
            })
            setLoading(false);

        }

        // Call the function
        loadUser();
    }, []);


    const onRolesChange = (event, values) => {
        handleDraggableListItemInState(event, values,'roles')
    }

    const onInputchange = (event) => {

        event.persist();

        console.log(event);

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        console.log("Edituser",user)
    };

    const onEditorCVchange = (event) => {

        setUser((prevState) => ({
            ...prevState,
            cv: event,
        }));

    };


    const handleCheckbox = (event) => {
        event.persist();

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked?1:0,
        }));

    }

    const getImage = (data) => {
        // event.persist();

        setUser((prevState) => ({
            ...prevState,
            file: data,
        }));
        console.log(user)
    }

    const hasError = (field_name) => {
        return error && error[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return error && error[field_name] ? error[field_name].join('<br/>') : false;
    }

    const onSubmitForm = () => {
        const loadItems = async () => {
            const formData = new FormData();
            Object.keys(user).map((value,index)=>{
                if( typeof user[value] === 'object' && value != "file"){
                    formData.append(value, JSON.stringify(user[value]));
                }else{
                    formData.append(value, user[value]);
                }
            })

            axios({
                url: '/data/create_user',
                method: 'POST',
                data: formData,
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }).then(response => {
                if(response.data.status == "error"){
                    setError(response.data.data.errors);
                }else{
                    setError([]);
                    notify();
                    console.log("asdad",response.data)
                    props.triggerdataload(response.data);
                    props.triggerdrawerfunc(false);
                }
            });

            // await axios.post(
            //     constants.LocalUrl + '/data/create_user',user).then(response => {
            //
            //     if(response.data.status == "error"){
            //         setError(response.data.data.errors);
            //     }else{
            //         notify();
            //         console.log("asdad",response.data)
            //         props.triggerdataload(response.data);
            //         props.triggerdrawerfunc(false);
            //     }
            //
            // });
        }
        // Call the function
        loadItems();
    };

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    if(loading === true){
        return <Loader />
    }

    const options = [
        {key:'Coordinator',title: t('Coordinator'), id: 2},
        // {title: t('Attendee'), id: 3},
        {key:'Speaker',title: t('Speaker'), id: 4},
        {key:'Executive',title: t('Executive'), id: 5},
        {key:'Hidden',title: t('Hidden'), id: 7},
        {key:'Administrator',title: t('Administrator'), id: 6},
    ];

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon className={checkboxes_style.root} fontSize="small" />;

    const notify = () => toast.success(t('User created!'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const onCountryChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['Country']: newValue ? newValue.code : null,
        }));
    };

    const onTimezoneChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['timezone']: newValue ? newValue.area : null,
        }));
    };

    return (
        <div>

            <div style={{display:'flex',justifyContent:'center'}}>
                <Grid className="registration_container backend" container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>
                    <Grid item xs={12}>
                    <Typography variant="h3" style={{textAlign:'center',width:"100%"}} gutterBottom>
                        {t('New User')}
                    </Typography>
                        <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left'}} gutterBottom>
                            {t('Information')}
                        </Typography>
                        <Divider light/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                            {t('User_image')}:
                            <span className="image_text_description">{t('upload_dimensions', {text: '200px * 200px'})}</span>
                        </Typography>
                        <FileUploadComponent style={{marginBottom:"100px"}} type="user_registration"
                                             getImage={getImage}
                                             notautoprocess={true}
                                             extra={{
                                                 action: constants.LocalUrl + '/data/addUserImg'
                                             }} fullwidth
                                             accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                             maxWidth={150} maxHeight={150}
                        />

                    </Grid>

                    <UserFields classname="user_backend_registration backend" type="user_backend" user={user} handleCountry={onCountryChange} handleTimezone={onTimezoneChange} handleUser={onInputchange} handleEditor={onEditorCVchange} handleCheckbox={handleCheckbox} error={error}/>
                    <React.Fragment key={'right'}>

                        <Grid container spacing={3} style={{padding:'20px'}}>
                            <Grid item xs={12}>
                                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>
                                    {t("Roles")}
                                </Typography>
                                <Divider />
                            </Grid>

                            {hasError('roler')?<FormHelperText style={{color:"#f44336"}}>{errorMessage('roler')}</FormHelperText>:""}

                            <Grid item xs={12}>

                                <AutoCompleteField
                                    // label={t('Roles')}
                                    placeholder={t("Choose a role...")}
                                    model={optionsState}
                                    data={user.roles}
                                    onChange={onRolesChange}
                                    type="single"
                                />
                                {/*<Autocomplete*/}
                                {/*    multiple*/}
                                {/*    id="tags-standard"*/}
                                {/*    options={optionsState}*/}
                                {/*    popupIcon={<ExpandMoreIcon />}*/}
                                {/*    disableCloseOnSelect*/}
                                {/*    onChange={onTagsChange}*/}
                                {/*    getOptionLabel={(option) => option.title}*/}
                                {/*    renderOption={(option, { selected }) => (*/}
                                {/*        <React.Fragment>*/}
                                {/*            <Checkbox*/}
                                {/*                icon={icon}*/}
                                {/*                checkedIcon={checkedIcon}*/}
                                {/*                style={{ marginRight: 8 }}*/}
                                {/*                checked={selected}*/}
                                {/*                color="#39BB0F"*/}
                                {/*            />*/}
                                {/*            {option.title}*/}
                                {/*        </React.Fragment>*/}
                                {/*    )}*/}

                                {/*    renderInput={(params) => (*/}
                                {/*        <TextField {...params}  label="" IconComponent={ExpandMoreIcon} placeholder={t("Choose a role...")} />*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </Grid>

                        </Grid>
                    </React.Fragment>
                    <PanelBottomBar classes={"panel_utilities_element"} close={props.triggerdrawer(false)} submit={onSubmitForm}/>

                </Grid>
            </div>
        </div>
    );

}

