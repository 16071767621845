import React, {useEffect} from "react";
import {Outlet, Link, Navigate, NavLink, Route, Routes, Redirect} from "react-router-dom";
import user from "./../../auth/user";
// const {stepButtonState,setStepButtonState} = useContext(WizardContext);

export const ProtectedRoute = ({...rest}) => {

console.log("ProtectedRoute",localStorage.getItem('userLoggedIn'));
    if (!localStorage.getItem('userLoggedIn')) {
        window.history.replaceState(null, "Home", "/");
        return <Navigate to={"/app/login"}/>

    }else{
        return <Outlet />
    }




}

export default ProtectedRoute