import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import * as constants from "../../../../../Panel/common/Constants";
import Test from "../../../../../common/DraggableSimpleList/test";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));



export default function ListFiles(props) {

    const classes = useStyles();
    const [files,setFiles] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        // const loadSchedule = async () => {
        setFiles(props.data?props.data:'');
        // }
        // console.log(props.data);
        // console.log("asd");

        // Call the function
        // loadSchedule();

    }, [props.data]);

    const handleSaveFileOrder = (files) => {
        setLoading(true);

        const response = axios.post(
            constants.LocalUrl + "/data/saveSponsorFilesOrder",{data:files});

        setLoading(false);
    }

        console.log("props.data")
        console.log(files)

    if (files.length === 0){
        <Paper style={{overflowY:'auto',maxHeight:'600px',padding:'20px', borderRadius:'23px'}}>
            <List component="nav" className={classes.root}  style={{width:'100%',maxWidth:"unset", borderRadius:'20px'}} fullwidth>
            </List>
        </Paper>
    }
    return (
        <div style={{marginTop:"30px"}}>
            <Test  data={files} edit={props.OnClick} save={handleSaveFileOrder} filename={props.filename} holdFilename={props.holdFilename} triggerdataload={props.triggerdataload} sponsor_id={props.sponsor_id} triggerdrawer={props.triggerdrawer} triggerdrawerfunc={props.triggerdrawerfunc}  data={props.data} OnClick={props.OnClick}/>
            {/*<DraggableSimpleList  data={files} edit={props.OnClick} save={handleSaveFileOrder}/>*/}
        </div>
    );

}
