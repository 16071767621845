import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';


const lang = document.getElementsByTagName("html")[0].attributes.lang.value;
// const domain = document.getElementById("main-site-domain").content;
// console.log(domain);
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({

    fallbackLng: lang,
    debug: true,
    detection: {
        order: ['querystring', 'cookie'],
        cache: ['cookie'],
    },
    interpolation: {
        escapeValue: false
    },
    backend: {
        loadPath: '/locales/' + lang + '/translation.json',
        addPath: '/locales/add/' + lang + '/translation',
    },
    saveMissing: false,
})

export default i18n;