import React, {forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import HelpDrawer from "../Panel/common/HelpDrawer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "transparent",
        padding: 0

    },


}));

const CTabsAutoScroll = forwardRef((props, ref) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('newValue', newValue);
        setValue(newValue);
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        handleChange, setValue,
    }));

    return (
        <div ref={ref} className={classes.root + " " + props.addclass}>
            <AppBar position="static" color="transparent" elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#39BB0F",
                        }
                    }}
                >
                    {props.titles.map((title, ti) => <Tab key={ti} label={title} {...a11yProps(ti)} /> )}
                </Tabs>
            </AppBar>
            {props.contents.map((content, ci) => <TabPanel value={value} key={ci} index={ci}>
                {props.contents[ci]}
            </TabPanel> )}
        </div>
    );
});

CTabsAutoScroll.displayName = "CTabsAutoScroll";
export default CTabsAutoScroll;
