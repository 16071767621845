import React, {useEffect, useState} from "react";
import "./styles.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import CSwitchField from "../../Panel/common/CSwitchField"
import {ListItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ImportExportIcon from '@material-ui/icons/ImportExport';

export default function App(props) {

    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        // const loadSchedule = async () => {
            setItemList(props.data?props.data:'');
        // }
        console.log(props.data);
        console.log("asd");

        // Call the function
        // loadSchedule();

    }, []);
    // React state to track order of items


    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        props.save(updatedList);
    };

    return (
        <div className="App">
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div
                                    className="list-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {itemList.map((item, index) => (

                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                            {(provided) => (

                                                <div
                                                    className="item-container"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <Divider light />
                                                    <ListItem button fullwidth>
                                                    <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-8px"}}/>
                                                    <CSwitchField state={props.states?props.states[item.name]:""} handler={props.handler} label={item.label.en} name={item.name} disabled={props.deactivated.includes(item.name)?true:false}/>
                                                    </ListItem>
                                                </div>


                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
        </div>
    );
}