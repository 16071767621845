import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../common/FileUploadComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as constants from "../../../common/Constants"
import UserFields from "../../../common/UserFields"
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {FormHelperText} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import {InputTextHandler} from "../../../common/InputTextHandler";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

export default function EditUser(props) {

    const thid = props.props;
    const {t, i18n} = useTranslation();
    const [user, setUser] = useState([]);
    const [countries, setCountries] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [optionsState, setOptionsState] = useState([
        {key: 'Coordinator', title: t('Coordinator'), id: 2},
        // {title: t('Attendee'), id: 3},
        {key: 'Speaker', title: t('Speaker'), id: 4},
        {key: 'Executive', title: t('Executive'), id: 5},
        // {key: 'Hidden', title: t('Hidden'), id: 7},
        {key: 'Administrator', title: t('Administrator'), id: 6},
    ]);
    const [loading, setLoading] = useState(true);
    const checkboxes_style = useedStyles();
    const [pid, setPid] = useState(null);
    const [checkbox, setCheckbox] = useState({
        acceptpolicy: false
    });
    const [error, setError] = useState([]);
    const {handleDraggableListItemInState} = InputTextHandler({state: user, setState: setUser});


    useEffect(() => {
        const loadUser = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            window.axios.post(constants.LocalUrl + '/data/user_full_data', {id: thid}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setUser(response.data[0]);

                // Closed the loading page
                setLoading(false);
            });

            window.axios.post(constants.LocalUrl + '/data/get_all_countries', {id: thid}).then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setCountries(response.data);

                // Closed the loading page

            });

            window.axios.post(constants.LocalUrl + '/data/get_all_timezones').then((response) => {
                setTimezones(response.data);
            })


        }

        // Call the function
        loadUser();
    }, []);

    const notify = () => toast.success(t('User updated!'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const onSubmitForm = () => {
        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/update_user', user).then(response => {

                if (response.data.status == "error") {
                    setError(response.data.data.errors);
                } else {
                    setError([]);

                    notify();
                    // console.log(response)

                    let index_id;
                    const i = props.posts.map((single_post, index) => {
                            if (single_post.id === response.data.data.id) {
                                index_id = index;
                                return true;
                            }
                        }
                    );


                    let items = [...props.posts];
                    let item = {...items[index_id]};

                    item.name = response.data.data.name;
                    item.email = response.data.data.email;
                    item.roles = response.data.data.roles;
                    items[index_id] = item;
                    console.log("sss");
                    console.log(items);
                    props.triggerdataload(items);
                    props.triggerdrawerfunc(false);

                }
            });
            // }
        }

        // Call the function
        loadItems();
    };



    const onInputchange = (event) => {

        event.persist();

        console.log(event);

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        console.log("Edituser", user)
    };

    const onEditorCVchange = (event) => {

        setUser((prevState) => ({
            ...prevState,
            cv: event,
        }));

    };


    const handleCheckbox = (event) => {
        event.persist();

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
        console.log(user);
    }

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));


    const onRolesChange = (event, values) => {
        handleDraggableListItemInState(event, values,'roles')
    }


    const onCountryChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['Country']: newValue ? newValue.code : null,
        }));
    };

    const onTimezoneChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['timezone']: newValue ? newValue.area : null,
        }));
    };

    const hasError = (field_name) => {
        return error && error[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return error && error[field_name] ?
            <div dangerouslySetInnerHTML={{__html: error[field_name].join('<br/>')}}></div> : false;
    }

    // console.log("countries",countries,Object.keys(countries).length)
    if (loading === true || user.length === 0 || Object.keys(countries).length == 0) {
        return <Loader/>
    }
    return (


        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Grid className="registration_container backend" container spacing={4}
                  style={{padding: '0', maxWidth: '800px'}}>
                <Grid item xs={12}>
                    <Typography aligncenter variant="h3" style={{textAlign: 'center'}} gutterBottom>
                        {t('Edit User')}
                    </Typography>

                    <Typography alignLeft variant="subtitle1" style={{textAlign: 'center'}} gutterBottom>
                        <span
                            style={{fontSize: "21px", fontStyle: "italic"}}>{user && user.name ? user.name : ""}</span>
                    </Typography>
                    <Divider style={{backgroundColor: "#39BB0F", marginBottom: "15px"}}/>
                </Grid>

                {/*{user.onboarding_imported_url}*/}

                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left'}} gutterBottom>
                        {t('Information')}
                    </Typography>

                    <Divider light/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t('User_image')}:
                        <span
                            className="image_text_description">{t('upload_dimensions', {text: '200px * 200px'})}</span>
                    </Typography>
                    <FileUploadComponent style={{marginBottom: "100px"}} image={user.user_img ? user.user_img : ''}
                                         type="user_registration"
                                         extra={{
                                             id: props.props,
                                             action: constants.LocalUrl + '/data/addUserImg'
                                         }} fullwidth
                                         accept={constants.acceptedUploadMimeGroups.images}
                                         maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={150} maxHeight={150}
                    />

                </Grid>

                <UserFields classname="user_backend_registration" type="user_backend" user={user}
                            handleUser={onInputchange}
                            handleCountry={onCountryChange} handleTimezone={onTimezoneChange}
                            handleEditor={onEditorCVchange} handleCheckbox={handleCheckbox} error={error}/>


                <Grid item xs={12}>

                        <Typography xs={12} alignLeft variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                    className="main_settings_section_title" gutterBottom>
                            {t("Roles")}
                        </Typography>
                        <Divider/>

                    <Divider light/>

                    {hasError('roler') ?
                        <FormHelperText style={{color: "#f44336"}}>{errorMessage('roler')}</FormHelperText> : ""}
                    <AutoCompleteField
                        // label={t('Roles')}
                        placeholder={t("Choose a role...")}
                        model={optionsState}
                        data={user.roles}
                        onChange={onRolesChange}
                        type="single"
                    />

                </Grid>


                <PanelBottomBar classes={"panel_utilities_element"} close={props.triggerdrawer(false)}
                                submit={onSubmitForm}/>

            </Grid>
        </div>

    );

}

