import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Container, CssBaseline, TextField, Typography} from "@material-ui/core";
import {NavLink, useSearchParams} from 'react-router-dom';
// import CTextField from "../../common/CTextField";
// import user from "./../../auth/user";
import "../../login.css"
import PageProvider from "../Page/PageContext"
import * as constants from "../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import Logo from "../common/Logo";
import {useTranslation} from "react-i18next";

export default function RememberPassword(props){

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setData((prevState) => ({
            ...prevState,
            "token": searchParams.get("token"),
        }));
        setData((prevState) => ({
            ...prevState,
            "email": searchParams.get("email"),
        }));
        // setData({"token":searchParams.get("token")})
        // setData({"email":searchParams.get("email")})
        // setToken(searchParams.get("token"));
        // setEmail(searchParams.get("email"));
    },[])


    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [data,setData] = useState({});



    const handleData = (event) => {
        event.persist();
        setData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        // setData({[event.target.name]:event.target.value})
    }

    console.log("props.params",props.params)
    const handleSubmit = (event) => {
        // event.preventDefault()
        // const formData = new FormData(event.currentTarget);

        // const loginCredentials = {
        //     email: formData.get('email'),
        // password: formData.get('password')
        // }

        // function authenticatedCallback() {
        //     let {from} = location.state || {from: {pathname: '/'}}
        //     history.replace(from)
        // }
// console.log(email)
        window.axios.post('/panel/password/reset', data).then((response) => {

            // user.authenticated(response.data, authenticatedCallback)
            console.log('Logged successfully!')
        })
    }

    return (
        <Container maxWidth={"xs"} className="login_container">

            <CssBaseline/>
            <Box align="center">
                <Grid item xs={12} style={{borderBottom:"2px solid var(--divider-color)"}}>
                    <Box align="center">
                        <NavLink className="item" to={constants.LocalUrl + "/"} >
                            <Logo src={settings.logo} alt={t('alt_Logo')} height="75px"/>
                        </NavLink>
                    </Box>
                    <Typography variant="h5" style={{textAlign:'center',position:"relative",top:"0",fontSize:"24px",color:"rgb(33, 37, 41)",marginTop:"15px"}} gutterBottom>
                        {t('Reset Password')}
                    </Typography>
                </Grid>
            </Box>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                <Box component={"form"} style={{width:"100%",marginTop:"0",textAlign:"center"}} onSubmit={handleSubmit}>
                    <Typography variant="h6" style={{textAlign:'left',marginLeft:"20px",marginBottom:"15px",marginTop:"15px"}} gutterBottom>
                        {t("E-mail")}
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="text"
                        style={{ width: '100%' }}
                        name="email"
                        // label=""
                        value={data.email?data.email:""}
                        onChange={handleData}
                        variant="outlined"
                        fullWidth
                    />


                    <Typography variant="h6" style={{textAlign:'left',marginLeft:"20px",marginBottom:"15px",marginTop:"15px"}} gutterBottom>
                        {t('New Password')}
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="password"
                        style={{ width: '100%' }}
                        name="password"
                        // label=""
                        // value={email?email:""}
                        onChange={handleData}
                        variant="outlined"
                        fullWidth
                    />

                    <Typography variant="h6" style={{textAlign:'left',marginLeft:"20px",marginBottom:"15px",marginTop:"15px"}} gutterBottom>
                        {t('New Password Confirmation')}
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="password"
                        style={{ width: '100%' }}
                        name="password_confirmation"
                        // label=""
                        // value={email?email:""}
                        onChange={handleData}
                        variant="outlined"
                        fullWidth
                    />

                    <br/>
                    <Button onClick={handleSubmit} style={{width:"150px",marginTop:"20px"}} id="close_button" variant="contained" type={"submit"} color="primary" component="span">{t('Save')}</Button>




                    <br />
                    <br />
                </Box>
            </Box>
        </Container>
    )
}

