import React, {useContext, useEffect, useState} from 'react';
import "react-chat-elements/dist/main.css";
import {useTranslation} from "react-i18next";
import PageProvider from "../Page/PageContext";
import {createMedia} from "@artsy/fresnel";
import AgendaMobile from "../Page/content/Agenda/AgendaMobile";
import AgendaIndividual from "../Page/content/Agenda/AgendaIndividual";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import Container from '@material-ui/core/Container';
import EventManager from "./EventManager";
import LiveonChat from "./LiveonChat/LiveonChat";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import Divider from "@material-ui/core/Divider";

const chat_icons = {
    SidebarChatIcon: "/images/panel/sidebar_chat.svg",
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

const useStyles_mobile = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        class:"sotiris",
        // zIndex: '9999 !important'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #fff',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 3),
        width: '95%',
        height: '98%',
        overflow: 'auto'
    },
}));

export default function RightSidebar(props) {

    const classes = useStyles_mobile();
    const {settings, setReloadData, eventManagerState,setEventManagerState} = useContext(PageProvider);
    const [showIndex, setShowIndex] = useState({});
    const [open, setOpen] = React.useState({});

    const {t, i18n} = useTranslation();

    useEffect(() => {
        console.log("eventManagerState",eventManagerState)
        if(eventManagerState.activate == true){
            console.log("eventManagerStates",eventManagerState)
            let tempStatuses = {};
            props.drawerData.map( item => (
                tempStatuses[item.name] = false
            ));
            setShowIndex(tempStatuses);
            setOpen(tempStatuses)
            setEventManagerState({activate:false})
        }
    },[eventManagerState])

    useEffect(() => {
        let tempStatuses = {};
        props.drawerData.map( item => (
            tempStatuses[item.name] = false
        ));
        setShowIndex(tempStatuses);
        setOpen(tempStatuses)
    }, [])

    const toggleSidepanel = (toggleName) => {
        let tempIndexes = {...showIndex};
        let newStatus = !tempIndexes[toggleName];
        for (const [key, value] of Object.entries(tempIndexes)) {
            tempIndexes[key] = false;
        }
        tempIndexes[toggleName] = newStatus;
        console.dir('tempIndexes', tempIndexes);
        setShowIndex(tempIndexes);
        setOpen(tempIndexes)
    }



    const handleClose = (event,reason) => {
        if (reason && (reason == "backdropClick" || "escapeKeyDown")){
            return;
        }else{
            setOpen(false);
        }
    };

    if (settings.user == undefined) {
        return null;
    }

    return (
        <div className="right_sidebar_wrapper">
            <MediaContextProvider>
                <Media at='mobile'>
                    <div id="sidepanel" >

                        <div className="col-xs-3">
                            <ul className="nav nav-tabs tabs-left">
                                {props.drawerData.map((item) => <>
                                    {
                                        item.roles.map((role) => settings.user.rolenames.includes(role)).includes(true)
                                            ? <li title={t(item.label)}>
                                                <a onClick={() => toggleSidepanel(item.name)} data-toggle="tab" accessKey={item.accessKey}>
                                                    {item.icon}
                                                </a>
                                            </li>
                                            : null
                                    }
                                </>)}
                            </ul>
                        </div>

                            {props.drawerData.map((item) => <>
                                {
                                    item.roles.map((role) => settings.user.rolenames.includes(role)).includes(true)
                                        ?
                                        showIndex[item.name] ?
                                            <Modal
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                className={classes.modal}
                                                open={open}
                                                onClose={handleClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                                // disableBackdropClick
                                                // disableEscapeKeyDown
                                            >

                                                <Fade in={open}>
                                                    <div className={classes.paper + " right_sidebar_content_wrapper"} style={{position:"relative"}}>
                                                        <Container maxWidth="lg">
                                                        <CloseIcon className="close_icon" onClick={handleClose} style={{position:'absolute',right:'22px',top:'24px'}}/>
                                                        <div className="tab-pane" id={item.name + '-tab'}>
                                                            <div className={item.name + '-nav'}>
                                                                {/*{item.content}*/}
                                                                {item.name == 'eventmanager' ? <EventManager open={showIndex[item.name]}/> : null}
                                                                {item.name == 'my_chats' ? <LiveonChat open={showIndex[item.name]}/> : null}
                                                            </div>
                                                        </div>
                                                        </Container>
                                                    </div>
                                                </Fade>
                                            </Modal>

                                            : null
                                        : null
                                }
                            </>)}

                    </div>
                </Media>

                <Media at='computer'>
                    <div id="sidepanel" className={Object.values(showIndex).includes(true) ? 'open' : ''}>
                        <div className="col-xs-3">
                            <ul className="nav nav-tabs tabs-left">
                                {props.drawerData.map((item) => <>
                                    {
                                        item.roles.map((role) => settings.user.rolenames.includes(role)).includes(true)
                                            ? <li title={t(item.name)}>
                                                <a onClick={() => toggleSidepanel(item.name)} data-toggle="tab" accessKey={item.accessKey}>
                                                    {item.icon}
                                                </a>
                                            </li>
                                            : null
                                    }
                                </>)}
                            </ul>
                        </div>
                        <div className="col-xs-9">
                            {props.drawerData.map((item) => <>
                                {
                                    item.roles.map((role) => settings.user.rolenames.includes(role)).includes(true)
                                        ?
                                        <div className="tab-pane" id={item.name + '-tab'}>
                                            <div className={item.name + '-nav'}>
                                                {/*{item.content}*/}
                                                {item.name == 'eventmanager' ? <EventManager open={showIndex[item.name]}/> : null}
                                                {item.name == 'my_chats' ? <LiveonChat open={showIndex[item.name]}/> : null}
                                            </div>
                                        </div>
                                        : null
                                }
                            </>)}
                        </div>
                    </div>
                </Media>

            </MediaContextProvider>

        </div>
    );
}
