import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import {ReactSVG} from 'react-svg'
import * as constants from "../../../common/Constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import InvitationPreview from "./InvitationPreview";
import LoadedInvitation from "./LoadedInvitation";
import NewInvitation from "./NewInvitation";
import DrawerWindow from "../../../common/DrawerWindow";
import Typography from "@material-ui/core/Typography";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LoadedEmail from "./LoadedEmail";
import {Loader} from "semantic-ui-react";
import TooltipProvider from "../../../../../Providers/TooltipProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const edit_icon = {
    icon: "/images/edit.svg",
    SendIcon: "/images/send.svg",
    delete: "/images/trash.svg",
}

export default function TablePredefinedEmails(props) {

    const {BoldTooltip} = useContext(TooltipProvider);
    const {t, i18n} = useTranslation();
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            row: null
        }
    );

    if (props.email_list == undefined) {
        return <p>email_list not set in props</p>;
    }

    const classes = useStyles();

    const loadPost = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        loadPostNoLoading();

        // Closed the loading page
        setLoading(false);
    }

    const loadPostNoLoading = async () => {
        const response = await axios.post(constants.LocalUrl + "/data/get_predefined_emails", {email_list: props.email_list});
        setEmails(response.data);
    }

    useEffect(() => {
        // Call the function
        loadPost();
    }, []);

    const toggleChildMenu = (attached, row) => () => {
        setDrawerstate({...drawerstate, open: attached, row: row});
    };

    if (loading) {
        return <Loader/>;
    }
    return (
        <div>
            <Paper id="draggable_simple_list" style={{padding:'20px',borderRadius:'23px'}}>
                {props.label ?? null}
                <List component="nav" className={classes.root}  style={{width:'100%',maxWidth:"unset"}} fullwidth>
                    {emails.map((row) => (
                        <div>
                        <Divider light />
                        <ListItem button fullwidth key={row.id}>
                            <ListItemText primary={row.subject[i18n.language]} />
                            <BoldTooltip title={t("Edit")} arrow>
                            <Button className="edit-button" size="medium" data-emailid={row.id} onClick={toggleChildMenu(true,row)} height="50" color="secondary">
                                <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} />
                            </Button>
                            </BoldTooltip>
                        </ListItem>
                        </div>
                    ))}
                </List>
            </Paper>
            <DrawerWindow open={drawerstate.open && drawerstate.row != null}>
                {drawerstate.row != null ? <LoadedEmail props={drawerstate.row.id} triggerdrawer={toggleChildMenu} triggerdataload={loadPostNoLoading} ></LoadedEmail> : null}
            </DrawerWindow>
        </div>
    );
}