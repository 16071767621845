import React, {useContext, useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as constants from "../../../Panel/common/Constants";
import FileUploadComponent from "../../../Panel/common/FileUploadComponent";
import {toast} from "react-toastify";
import {useTranslation} from 'react-i18next';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import BasicInfoService from "../../../../api/services/Panel/basicInfo";
import CTextField from "../../../Panel/common/CTextField";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Loader from "../../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import WizardContext from '../../WizardContext'
import DateFnsUtils from "@date-io/date-fns";
import {
  datepickerLocales,
  datepickerCancelLabelLocales,
  datepickerClearLabelLocales
} from "../../../common/Language/DatepickerLocales.js"

export default function BasicInfo () {


  const {t, i18n} = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const notify = () => toast.success(t('Succesfully saved!'), {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const {saveBasicInfo,setSaveBasicInfo} = useContext(WizardContext);
  const [state,setState] =  useState({});
  const [timezoneInputValue,setTimezoneInputValue] =  useState({});
  const [zones,setZones] =  useState([]);
  const [settings,setSettings] =  useState([]);
  const [loading,setLoading] =  useState([]);
  const [localeLanguage, setLocaleLanguage] = useState(i18n.language);

  let timezone_formats = {
    1 : "24hr",
    2 : "12hr",
  };

  let date_formats = {
    1 : "day/month/year",
    2 : "month/day/year",
    3 : "year/month/day",
  };

  const setLanguage = (value) =>{
    BasicInfoService.get(value).then((response) => {
      console.log(response,"lll");
      let s_time = new Date(response.EVENT_TIME * 1000)
      response.start_time_picker = s_time;
      setState(response);
      setLocaleLanguage(value)
    });
  }



  const handleStartDate = (event) => {

    let date = new Date(state.EVENT_DATE);
    // date.setHours(event.getHours(), event.getMinutes(), 0, 0);

    console.log("handleStartDate",event,event.getDate(),date.getTime())
    setState((prevState) => ({
      ...prevState,
      EVENT_DATE: event.getTime(),
    }));

    setState((prevState) => ({
      ...prevState,
      start_date_picker: event,
    }));

  }

  const handleStartTime = (event) => {

    let date = new Date(state.EVENT_DATE);
    date.setHours(event.getHours(), event.getMinutes(), 0, 0);

    console.log(event,event.getTime(),date.getTime())
    setState((prevState) => ({
      ...prevState,
      EVENT_TIME: event.getTime(),
    }));

    setState((prevState) => ({
      ...prevState,
      start_time_picker: event,
    }));

  }

  const onInputchange = (event) => {

    event.persist();
    setState((prevState) => ({
            ...prevState,    // keep all other key-value pairs
            [event.target.name]: event.target.value       // update the value of specific key

        }))

  }

  const onTimezoneChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,    // keep all other key-value pairs
      ['timezone']: newValue ? newValue.area : '',
    }))
  };

  useEffect(() => {

    if(saveBasicInfo === true){
    window.axios.post(constants.LocalUrl + '/data/save_basic_info',{form:state,lang:localeLanguage}).then((response) => {
      if(response.data.status == "ok"){
        // notify();
      }
    })
      setSaveBasicInfo(false)
    }

    // saveState(state);
    // console.log(state);

  },[saveBasicInfo])

  useEffect(() => {

    if(zones.length == 0){

      BasicInfoService.timezones().then((response) => {
        setZones(response);
      });

      BasicInfoService.get().then((response) => {
        console.log(response,"lll");
        let s_time = new Date(response.EVENT_TIME * 1000)
        // let s_date = new Date(response.EVENT_DATE)
        // console.log(s_date);
        response.start_time_picker = s_time;
        // response.start_date_picker = s_date;
        setState(response);
      });


       window.axios.post(constants.LocalUrl + '/data/get_settings').then(response => {

        // After fetching data stored it in posts state.
        console.log("////");
        console.log(response.data);

        setSettings(response.data);
        console.log("|||//||");

      });
    }

  },[])



  if(zones.length == 0 || Object.values(settings).length === 0 || Object.values(state).length === 0){
      return <Loader />;
  }

  console.log("state",state,Object.values(state).length)
    return (
      <SimpleBar forceVisible="y" autoHide={false} className="bottom_bar" style={{maxHeight:"528px", height:"528px"}}>
      <Grid container spacing={4} style={{width: '100%', margin: '0 0 0 -15px'}}>

        <Grid item xs={12}>
          <Grid item xs={6} className="logo_images">
          <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
            {t('Event logo')}
            <span className="image_text_description">{t('Maximum')} {t('upload_dimensions', {text: '400px * 130px'})}</span>
          </Typography>
          <FileUploadComponent id="1" image={settings && settings.logo?settings.logo:''} extra={{action:"/data/addSettingsLogo"}}
                               accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                               maxWidth={400} maxHeight={130}
          />
          </Grid>
        </Grid>

        <Grid item xs={6} direction="column">
          <CTextField  handler={onInputchange} name='EVENT_NAME' label={t('Event name')} value={state.EVENT_NAME}/>
        </Grid>
        <Grid item xs={6} direction="column">
        <Typography alignLeft variant="h6"  style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
          {t('Event date')}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
            <DatePicker
                id="outlined-textarea"
                inputVariant="outlined"
                name="EVENT_DATE"
                ampm={true}
                label=""
                value={state.start_date_picker?state.start_date_picker:state.EVENT_DATE}
                required
                // error={hasError('start_date')}
                // helperText={errorMessage('start_date')}
                // minDate={new Date(settings.EVENT_DATE)}
                // maxDate={new Date(settings.EVENT_DATE_TO)}
                fullWidth
                onChange={handleStartDate}
                onError={console.log}
                format={state.EVENT_DATE_FORMAT_UI}
                cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                clearLabel={datepickerClearLabelLocales[i18n.language]}
            />
          </MuiPickersUtilsProvider>
          {/*<TextField onChange={onInputchange} style={{borderRadius: '23px'}} id="outlined-basic"  variant="outlined" format="dd/mm/yyyy" defaultValue="24/05/2017"  type="date" fullWidth InputLabelProps={{ shrink: state.EVENT_DATE?true:false }} name="EVENT_DATE" value={state.EVENT_DATE}/>*/}
        </Grid>
        <Grid item xs={6}>
        <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}}  gutterBottom>
          {t('Event time')}
          </Typography>
          {/*<TextField onChange={onInputchange} style={{borderRadius: '23px'}} id="outlined-basic" defaultValue="07:30"  type="time"  variant="outlined" fullWidth InputLabelProps={{ shrink: state.EVENT_TIME?true:false }} name="EVENT_TIME" value={state.EVENT_TIME}/>*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
            <TimePicker
                id="date-picker-dialog"
                inputVariant="outlined"
                clearable
                ampm={state.TIME_FORMAT == 1?false:true}
                label=""
                value={state.start_time_picker?state.start_time_picker:new Date()}
                onChange={handleStartTime}
                variant="outlined"
                fullWidth
                cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                clearLabel={datepickerClearLabelLocales[i18n.language]}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6}>
          <CTextField  handler={onInputchange} name='EVENT_DATE_STR' label={t('Event date (in text)')} value={state.EVENT_DATE_STR}/>
        </Grid>

        <Grid item xs={6}>
          <CTextField  handler={onInputchange} name='EVENT_DAY' label={t('Event day')} value={state.EVENT_DAY}/>
        </Grid>

        <Grid item xs={6}>
            <Typography alignLeft variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
              {t('Timezone')}
            </Typography>
              <Autocomplete
                  id="checkboxes-tags-demo"
                  popupIcon={<ExpandMoreIcon />}
                  fullWidth
                  onChange={onTimezoneChange}
                  options={zones}
                  groupBy={(option) => option.zone}
                  getOptionLabel={(option) => option.area}
                  renderOption={(option) => (
                      <React.Fragment>
                        {option.area} {option.timezone}
                      </React.Fragment>
                  )}
                  defaultValue={zones.filter((item)=> item.area == state.timezone)[0]}
                  required={true}
                  renderInput={(params) => <TextField {...params} name='timezone' variant="outlined" style={{padding: 0}} />}
              />
        </Grid>

        <Grid item xs={6}>
          <Typography alignLeft variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
            {t('TIME_FORMAT')}
          </Typography>
          <Select style={{borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined" value={state && state.TIME_FORMAT?state.TIME_FORMAT:""} fullWidth name="TIME_FORMAT" IconComponent={ExpandMoreIcon}>
                {Object.keys(timezone_formats).map(data => (
                    <option /*selected={state.timezone === data?'selected':''}*/ value={data}>{timezone_formats[data]}</option>
                ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography alignLeft variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
            {t('DATE_FORMAT')}
          </Typography>

          <Select style={{borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined" fullWidth value={state && state.DATE_FORMAT?state.DATE_FORMAT:""} name="DATE_FORMAT" IconComponent={ExpandMoreIcon}>
            {Object.keys(date_formats).map(data => (
                <MenuItem value={data}>{date_formats[data]}</MenuItem>
            ))}
          </Select>

        </Grid>

        {/*<PanelBottomBar classes={"panel_utilities_element basicinfo"} style={{justifyContent:"flex-end"}} submit={onSubmitForm} language={setLanguage}/>*/}

        {/*<Grid className="panel_utilities_element basicinfo" style={{justifyContent:"flex-end"}} item xs={12}>*/}

        {/*  <div className="right_part" >*/}
        {/*    {changeLanguage()}*/}
        {/*    <Button size="large" onClick={onSubmitForm} variant="contained" color="primary" component="span">*/}
        {/*      {t('SAVE')}*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</Grid>*/}

        </Grid>
      {/*</div>*/}
      </SimpleBar>
      
    );

}

// export default withTranslation()(BasicInfo);
