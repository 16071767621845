import React from 'react';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

export default function CTextField(props){

    return(
        <div>
            {
                props.label ? <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                    {props.label}
                    {props.required?' *':''}
                </Typography>
                : null
            }
            <TextField
                id="outlined-textarea"
                placeholder=""
                type={props.type?props.type:"text"}
                defaultValue={props.defaultValue?props.defaultValue:""}
                style={{ width: '100%' }}
                name={props.name}
                // label=""
                value={props.value}
                onChange={props.handler}
                variant="outlined"
                inputProps={props.inputProps?props.inputProps:{}}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
                autoComplete={props.autocomplete}
            />
        </div>
    )
}