import React, {useEffect, useState} from "react";
import "./styles.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpdateFile from "../../Panel/tabs/Sponsors/components/files/UpdateFile";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import {ListItem} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import StageMobile from "../../Page/content/Stage/StageMobile";
import StageIndividual from "../../Page/content/Stage/StageIndividual";
import {createMedia} from "@artsy/fresnel";



export default function App(props) {

    const {t, i18n} = useTranslation();
    const [itemList, setItemList] = useState([]);

    useEffect(() => {

        setItemList(props.data?props.data:'');

    }, []);



    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        props.save(updatedList);
    };

    const handleItemListValues = (event) => {

        let value = event.target.parentElement.getAttribute("data-value");
        let name = event.target.parentElement.getAttribute("data-name");
        let item_index = event.target.parentElement.getAttribute("data-index");
        // let value_name = event.target.parentElement.getAttribute("data-value_name");

        let field_values = [...itemList]
        field_values[item_index][name] = value == 1?0:1;

        if(name == "in_registration"){
            props.handler(event);
        }else if (name == "required"){
            props.handleRequiredField(event)
        }

        setItemList(field_values);

    }

    let requiredLabel = (value,id,value_name,index) => {
        return(
            <Button className={value == 1?"registration_switch active":"registration_switch inactive"}
                    style={value === 1?{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px",borderColor:"#39BB0F",color:"#39BB0F"}:{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px"}}
                    data-id={id}
                    data-index={index}
                    data-value={value}
                    data-value_name={value_name}
                    data-name="required"
                    onClick={handleItemListValues}
                    button-value={value}
                    variant="outlined" color="secondary" >{value === 1?t("Required_field"):t("Not_required_field")}</Button>
        )
    };

    let activeLabel = (value,id,value_name,index) => {
        console.log("value,id,value_name,index",value,id,value_name,index);
        return(

            <Button className={value == 1?"registration_switch active":"registration_switch inactive"}
                    style={value === 1?{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px",borderColor:"#39BB0F",color:"#39BB0F"}:{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px"}}
                    data-id={id}
                    data-index={index}
                    data-value={value}
                    data-value_name={value_name}
                    data-name="in_registration"
                    onClick={handleItemListValues}
                    button-value={value} variant="outlined" color="secondary" >{value === 1?t("Active_field"):t("Inactive_field")}</Button>
        )
    };

    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            mobile: 0,
            tablet: 768,
            computer: 1024,
        },
    })

    return (
        <div className="App register_panel_fields">
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {console.log("itemList",itemList)}
                            {itemList.map((item, index) => (

                                <Draggable key={item.title} draggableId={item.title + "_" + item.id} index={index}>
                                    {(provided) => (

                                        <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            <Divider light />
                                            <MediaContextProvider>
                                            <Media at='mobile'>
                                            <ListItem button fullwidth>
                                                <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-8px",top:"2px"}}/>

                                                <div className="registration_list_cus_mobile_wrapper">
                                                    {/*<ListItemText className="registration_fields_title" primary={item.title} />*/}
                                                    <ListItemText className="registration_fields_title" primary={<span dangerouslySetInnerHTML={{__html: item.title}}/>} />
                                                    {/*<ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={requiredLabel(item.required,item.id,item.title,index)}  primary={t("panel_field_required")} left/>*/}
                                                    {/*<ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={activeLabel(item.status,item.id,item.title,index)}  primary={t("panel_field_status")} left/>*/}
                                                    <div className="registration_fields_buttons_wrapper">
                                                        <ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={requiredLabel(item.required,item.id,item.title,index)} left/>
                                                        <ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={activeLabel(item.in_registration,item.id,item.title,index)}  left/>
                                                    </div>
                                                </div>

                                            </ListItem>
                                            </Media>
                                            <Media at='computer'>
                                                <ListItem button fullwidth>

                                                    <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-8px",top:"2px"}}/>
                                                    <ListItemText className="registration_fields_title" primary={<span dangerouslySetInnerHTML={{__html: item.title}}/>} />
                                                    <ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={requiredLabel(item.required,item.id,item.title,index)} left/>
                                                    <ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={activeLabel(item.in_registration,item.id,item.title,index)} left/>
                                                    {/*<ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={requiredLabel(item.required,item.id,item.title,index)}  primary={t("panel_field_required")} left/>*/}
                                                    {/*<ListItemText className="registration_fields_buttons" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} secondary={activeLabel(item.status,item.id,item.title,index)}  primary={t("panel_field_status")} left/>*/}

                                                </ListItem>
                                            </Media>
                                            </MediaContextProvider>
                                        </div>


                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}