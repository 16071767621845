import {NavLink} from "react-router-dom";
import * as constants from "../Panel/common/Constants";
import React from "react";
import {useTranslation} from "react-i18next";

export default function UserCard(props){
    const {t, i18n} = useTranslation();

    return(
        <div style={{display: 'flex'}}>
            <div style={{width: '100%', padding: '17px 0', display: 'flex'}}>
                <NavLink as="a" to={constants.LocalUrl + "/profile/" + props.user.id}>
                    <img
                        src={props.user.user_img?props.user.user_img:props.user.image} style={{
                        marginRight: '12px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        maxWidth: '65px',
                        maxHeight: '65px',
                        width: '65px',
                        height: '65px'
                    }}/>
                </NavLink>
                <div className="session_hosts_card">
                    <div>
                        <NavLink as="a" to={constants.LocalUrl + "/profile/" + props.user.id}>
                            <h1 className="agenda_host_name">{props.user.name}</h1>
                        </NavLink>
                        <p className="agenda_host_name_job">{props.user.jobtitle} {props.user.company ? t("by") + ' ' + props.user.company : ""}</p>
                    </div>
                </div>
            </div>
            {props.children ?? ''}
        </div>
    )
}