import React, {useEffect, useState} from 'react';
import * as constants from "./Constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ListItem from "@material-ui/core/ListItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Editor from "./Editor";
import CTextField from "./CTextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../common/Loader";

export default function UserFields(props){

    const {t, i18n} = useTranslation();
    const [data,setData] = useState([])
    const [user,setUser] = useState([])
    const [timezones,setTimezones] = useState([])
    const [countries,setCountries] = useState([])
    const [loading,setLoading] = useState(false)
    const [loadingAction,setLoadingAction] = useState(false)

    useEffect(() => {

        setLoading(true);
        if(props.type === 'user_backend' || props.type === 'register_page'|| props.type === 'user_profile'){
            loadContent();
        }else if(props.type === 'registration_set'){
            loadContent();
            props.handleRegisterPageInstant(false);
        }


    }, [props.loadingAction]);


    function loadContent(){
        setLoading(true);
        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.


            // console.log("props.user")
            // console.log(props.user)
            // Await make wait until that
            // promise settles and return its reult
            window.axios.post(constants.LocalUrl + '/data/active_registration_fields').then(response => {
                // console.log('response', response);
                // After fetching data stored it in posts state.
                setData(response.data);

                // Closed the loading page

            });
            // const response = await axios.post(
            //     constants.LocalUrl + "/data/active_registration_fields");

            // After fetching data stored it in posts state.
            // setData(response.data);
            // console.log("response.data")
            // console.log(response.data)

            // window.axios.post(constants.LocalUrl + '/data/get_all_countries').then(response => {
            //     // console.log('response', response);
            //     // After fetching data stored it in posts state.
            //     setCountries(response.data);
            //
            //     // Closed the loading page
            //
            // });
            //
            // window.axios.post(constants.LocalUrl + '/data/get_all_timezones').then((response) => {
            //     setTimezones(response.data);
            // })
            // Closed the loading page
            setUser(props.user)
            setLoading(false);
        }
        loadPost();
    }

    const onInputchange = (event) => {

        props.handleUser(event);

    };

    const onCountryChange = (event, newValue) => {
        props.handleCountry(event, newValue);
    };

    const onTimezoneChange = (event, newValue) => {
        props.handleTimezone(event, newValue);
    };

    const onInputchangeEditor = (event) => {
        props.handleEditor(event);

    };

    const onInputchangeCheckbox = (event) => {
        props.handleCheckbox(event);

    };




    // console.log("handleRegisterPage",props.user)
    // if(loading === true || (user && Object.keys(props.user).length === 0)|| (data && Object.keys(data).length === 0)){
    if(loading === true || data.length === 0){
        return <Loader />
    }

    return (

        <React.Fragment>
                    <Grid item xs={12} className="register_default_fields">
                        <GetFieldsUi handleUser={onInputchange} user={props.user} data={data} error={props.error} type={"default_fields"} comefrom={props.type}/>
                    </Grid>
                    <hr style={{width: '97.4%', margin: '0 auto'}}/>
                    <Grid item xs={12} className="register_dynamic_fields">
                        <GetFieldsUi handleUser={onInputchange} handleCountry={onCountryChange} handleTimezone={onTimezoneChange} handleEditor={onInputchangeEditor} handleCheckbox={onInputchangeCheckbox} user={props.user} error={props.error} data={data} type={"active_fields"} comefrom={props.type}/>
                    </Grid>
        </React.Fragment>


    )
}


function GetFieldsUi(props){

    var type = props.type;
    const classes = useStyles();
    var text_fields = ["fname","lname","email","phone","jobtitle","password","password_confirmation","Website","nickname","company"];
    var select_fields = ["gender","language","Title"];
    var checkbox_fields = ["gdpracceptance","epeksergasia","politiki","emfanisi","promotion"];
    var autocomplete_fields = ["Country","timezone"];
    var editor_fields = ["cv"];
    const {t, i18n} = useTranslation();
    const [formErrors, setFormErrors] = useState(null);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);

    useEffect(() => {
        setUser(props.user)
        setData(props.data)
    },[])

    useEffect(() => {
        setFormErrors(props.error)
    },[props.error])

    const onTagsChange = (event, values) => {

        event.persist();
        setUser(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            roler: values      // update the value of specific key

        }))

    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const handleCheckbox = (event) => {
        event.persist();

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked?1:0,
        }));
        props.handleCheckbox(event)
    }

    const handleDescription = (data) => {
        props.handleEditor(data);
    }

    const onInputchange = (event) => {
        event.persist();

        if(event.target.name == "password" || event.target.name == "password_confirmation"){
            console.log("passworddd")
            setUser((prevState) => ({
                ...prevState,
                ["user_inputs"]: true,
            }));
            console.log(user)
        }

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        props.handleUser(event);
    };

    const onCountryChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['Country']: newValue ? newValue.code : null,
        }));
        props.handleCountry(event, newValue);
    };

    const onTimezoneChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['timezone']: newValue ? newValue.area : null,
        }));
        props.handleTimezone(event, newValue);
    };

    if(Object.keys(data).length === 0){
        return <CircularProgress/>
    }

    return(
        <Grid container spacing={4} style={{padding:"0",alignItems:"flex-start",width:"100%",margin:"0"}}>
            {data[type] && data[type].map((field) => {

                let gridSize = 6;
                if (field.name == "email" || field.name == "password_confirmation" || field.name == "password" ) {
                    gridSize = 4;
                }

                if(text_fields.includes(field.name)){

                    return (

                    <Grid item xs={12} md={gridSize}>
                        {user && user['user_inputs']?<CTextField required={field.required == 1?1:0} label={field.locale_label} error={hasError(field.name)} helperText={errorMessage(field.name)} value={user && user[field.name]?user[field.name]:""}
                                                         autocomplete={props.comefrom == "user_backend" && (field.name == "password" || field.name == "password_confirmation")?"off":""} name={field.name} type={field.name == "password" || field.name == "password_confirmation"?"password":''} variant="outlined" handler={onInputchange}/>:
                            <CTextField required={field.required == 1?1:0} label={field.locale_label} error={hasError(field.name)} helperText={errorMessage(field.name)} value={user && user[field.name] && field.name != "password" && field.name != "password_confirmation"?user[field.name]:""}
                                                         autocomplete={props.comefrom == "user_backend" && (field.name == "password" || field.name == "password_confirmation")?"off":""} name={field.name} type={field.name == "password" || field.name == "password_confirmation"?"password":''} variant="outlined" handler={onInputchange}/>}
                    </Grid>
                    )
                }else if(checkbox_fields.includes(field.name)){

                    return (

                        <ListItem button fullwidth>
                            {/*{console.log(user[field.name],field.name)}*/}
                            <div style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}}>

                                <FormLabel style={hasError(field.name)?{marginBottom:0,color:"#f44336"}:{marginBottom:0}} component="legend" >
                                    <div className='switch_label_wrapper' dangerouslySetInnerHTML={{__html: (field.required == 1?"<strong >*</strong>":"") + field.locale_label}}></div>
                                </FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        style={{margin:'0'}}
                                        control={<Switch checked={user && user[field.name] == 1?1:0} onChange={handleCheckbox} name={field.name} />}
                                        // label="asd"
                                        required={field.required == 1?1:0}
                                        error={hasError(field.name)} helperText={errorMessage(field.name)}
                                    />

                                </FormGroup>
                                {errorMessage(field.name)?<FormHelperText style={{color:"#f44336"}}>{formErrors[field.name]}</FormHelperText>:""}
                            </div>
                        </ListItem>
                    )

                }else if(select_fields.includes(field.name)){

                    return(
                        <Grid item xs={12} md={gridSize} error={errorMessage(field.name)?1:0}>
                        <FormControl style={{width:"100%"}}>
                        <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                            {field.locale_label} {field.required == 1?"*":""}
                        </Typography>
                        <Select required={field.required == 1?1:0} error={hasError(field.name)} IconComponent={ExpandMoreIcon}  style={{borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined" fullWidth name={field.name}  value={user && user[field.name]?user[field.name]:""}>
                            {Object.values(field.data).map(data => (
                                <MenuItem value={data[0]}>{data[1]}</MenuItem>
                            ))}
                        </Select>
                        {errorMessage(field.name)?<FormHelperText style={{color:"#f44336"}}>{formErrors[field.name]}</FormHelperText>:""}
                        </FormControl>
                        </Grid>
                    )

                } else if(field.name == 'timezone'){
                    return(
                        <Grid item xs={12} md={gridSize}>
                            <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {field.locale_label} {field.required == 1?"*":""}
                            </Typography>
                            <Autocomplete
                                defaultValue={field.data.filter((item)=> item.area == user.timezone)[0]}
                                id="grouped-native-select"
                                popupIcon={<ExpandMoreIcon />}
                                fullWidth
                                onChange={onTimezoneChange}
                                options={field.data}
                                groupBy={(option) => option.zone}
                                getOptionLabel={(option) => option.area}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.area} {option.timezone}
                                    </React.Fragment>
                                )}
                                required={field.required == 1?1:0}
                                renderInput={(params) => <TextField {...params} error={hasError(field.name)} helperText={errorMessage(field.name)} name={field.name} variant="outlined" style={{padding: 0}} />}
                            />
                        </Grid>
                    )
                }else if(field.name == 'Country'){
                    return(
                        <Grid item xs={6} md={gridSize}>
                            <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {field.locale_label} {field.required == 1?"*":""}
                            </Typography>
                            <Autocomplete
                                defaultValue={field.data.filter((item)=> item.code == user.Country)[0]}
                                id="grouped-native-select"
                                popupIcon={<ExpandMoreIcon />}
                                fullWidth
                                onChange={onCountryChange}
                                options={field.data}
                                getOptionLabel={(option) => option.name}
                                required={field.required == 1?1:0}
                                renderInput={(params) => <TextField {...params} error={hasError(field.name)} helperText={errorMessage(field.name)} name={field.name} variant="outlined" style={{padding: 0}} />}
                            />
                        </Grid>
                    )
                }else if(editor_fields.includes(field.name)){
                    return(
                        <Grid item xs={6} md={4}>
                        <Editor required={field.required == 1?1:0} handler={handleDescription} label={field.label[i18n.language]} value={user && user[field.name]?user[field.name]:" "} name={field.name}/>
                        {errorMessage(field.name)?<FormHelperText style={{color:"#f44336"}}>{formErrors[field.name]}</FormHelperText>:""}
                        </Grid>
                    )

                }

            })}

                {props.type == "active_fields"?<Grid item xs={12}>
                    {props.type === 'user_profile'?<Button id="close_button" variant="contained" color="primary" component="span">{t('Save')}</Button>:""}
                </Grid>:""}

            </Grid>


    )

}

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));
