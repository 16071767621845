import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../common/FileUploadComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from "../../../common/Constants"
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

const useStyles = makeStyles({
    list: {
        // width: '800px',
        // padding: '40px'
    },
    fullList: {
        width: 'auto',
    },
    select : {
        width: '100%'
    }
});
const close_button = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        right: '9px',
        top: '0px'
    },
}));

export default function NewSponsor(props)  {

    const {t, i18n} = useTranslation();
    const [formData, setformData] = useState({
        name: "newsponsor"
    });
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [executives, setExecutives] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const checkboxes_style = useedStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small" className={checkboxes_style.root}/>;
    const classes = useStyles();



    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    useEffect(() => {

        const loadSponsor = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);


            await axios.post(constants.LocalUrl + '/data/get_sponsor_categories').then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setSponsorcategories(response.data);

                // Closed the loading page
                setLoading(false);
            });

            await axios.post(constants.LocalUrl + '/data/get_all_executives').then(response => {
                console.log('response', response);
                // After fetching data stored it in posts state.
                setExecutives(response.data);

                // Closed the loading page
                // setLoading(false);
            });

        }

        // Call the function
        loadSponsor();
    }, []);

    const handleChange = (event) => {
        event.persist();
        if (event.target.name == 'category_id') {
            setSponsorcategory(event.target.value);
        }
        setformData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const onInputchange = (event) => {
        setformData({ ...formData, [event.target.name]: event.target.value });
        console.log("edo");
    };

    const onExecutivesChange = (event, values) => {

        event.persist();
        setformData(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                ['executives']: {
                    ...prevState['executives'],
                    ['items']: values,
                }      // update the value of specific key

            }))

    };

    const handleExecutivesOrder = (values) => {

        // event.persist();
        setformData(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                ['executives']: {
                    ...prevState['executives'],
                    ['items']: values,
                }      // update the value of specific key

            }))

    };

    const handleSettingsFunc = (data) => {

        // event.persist();
        setformData((prevState) => ({
            ...prevState,
            description: data,
        }));

        console.log("handleSettings");
    }

    const saveSponsorsSpeakersOrder = (event) => {

        setformData(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["executivesOrder"]: event      // update the value of specific key

        }))

    }

    const onInputchangeSocials = (event) => {
        event.persist();


        console.log('nope')
        setformData((prevState) => ({
            ...prevState,
            socials:{
                ...prevState['socials'],
                [event.target.name]:event.target.value,
            }
        }));

    };

    const getImageLogo = (data) => {
        // event.persist();

        setformData((prevState) => ({
            ...prevState,
            logo_file: data,
        }));

    }

    const getImageWideBanner = (data) => {
        // event.persist();

        setformData((prevState) => ({
            ...prevState,
            banner_file: data,
        }));

    }

    const onSubmitForm = () => {
        const loadItems = async () => {

            const __formData = new FormData();
            Object.keys(formData).map((value,index)=>{
                if( typeof formData[value] === 'object' && value != "banner_file" && value != "logo_file"){
                    __formData.append(value, JSON.stringify(formData[value]));
                }else{
                    __formData.append(value, formData[value]);
                }
            })

            axios({
                url: '/data/create_sponsor',
                method: 'POST',
                data: __formData,
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }).then(response => {
                if(response.data.status == "error"){
                    setFormErrors(response.data.data.errors);
                }else{
                    setLoading(false);

                    props.triggerdataload(response.data.data);
                    console.log("asda");
                    props.triggerdrawerfunc(false);
                }

            // const response = await axios.post(
            //     constants.LocalUrl + '/data/create_sponsor',formData).then(response => {
            //
            //     if (response.data.status == 'error') {
            //         setFormErrors(response.data.data.errors);
            //     }else {
            //         setLoading(false);
            //
            //         props.triggerdataload(response.data.data);
            //         console.log("asda");
            //         props.triggerdrawerfunc(false);
            //     }

            });

            // After fetching data stored it in posts state.
            // setformData(response.data)
        }

        // Call the function
        loadItems();
    };

    const return_options_executives = (data) => {
        let arr = [];
        // console.log("executives");
        // console.log(executives);
        // console.log("executives");
        executives.map((e,index) =>{
            if(data && data.includes(e.id)){
                arr.push(executives[index]);
            }
        });
        return arr;
    }


    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]

    if(loading === true){
        return <Loader />
    }

    let sponsor_sizes = [{id:'Large',title:t('Large')},{id:'Big',title:t('Medium')},{id:'Medium',title:t('Small')},{id:'Small',title: t('Line')}]
    return (
        <div className="bottom_bar">
            {/*<Grid container spacing={3} style={{padding:'20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)} className={close_button.root} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Typography aligncenter variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Sponsor')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
                <React.Fragment key={'right'}>
                    <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                        <Grid item xs={12}>
                            <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                                {t('Sponsor Details')}
                            </Typography>
                            <Divider light />
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField  handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Editor handler={handleSettingsFunc} value="" name="description" label={t('Description')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor Category')} *
                            </Typography>
                            <FormControl variant="outlined" className={classes.select}>
                                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    IconComponent={ExpandMoreIcon}
                                    id="grouped-native-select"
                                    value={sponsorcategory}
                                    onChange={handleChange}
                                    name="category_id"
                                    required error={hasError('title')} helperText={errorMessage('title')}
                                >
                                    <MenuItem value="">
                                        <em>{t('None')}</em>
                                    </MenuItem>
                                    {sponsorcategories.map((row) => (
                                        <MenuItem value={row.id}>{row.title}</MenuItem>
                                    ))}
                                </Select>
                                {errorMessage("category_id")?<FormHelperText style={{color:"#f44336"}}>{formErrors["category_id"]}</FormHelperText>:""}
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                                <Typography  alignLeft variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                    {t('Sponsor_Type')}
                                </Typography>
                                <FormControl variant="outlined" className={classes.select}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        IconComponent={ExpandMoreIcon}
                                        id="grouped-native-select"
                                        value={formData.typos?formData.typos:'Large'}
                                        onChange={handleChange}
                                        style={{maxWidth:"342px"}}
                                        name="typos"
                                    >
                                        {sponsor_sizes.map((row) => (
                                            <MenuItem value={row.id}>{row.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        </Grid>

                        <Grid item xs={6} className="panel_sponsor logo_images">
                            <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor Logo')}
                                <span className="image_text_description">{t('upload_dimensions', {text: '300px * 180px'})}</span>
                            </Typography>
                            <FileUploadComponent id="1" notautoprocess={true} getImage={getImageLogo} extra={{action:constants.LocalUrl + '/data/addSponsorLogo'}} fullwidth
                                 accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                 maxWidth={300} maxHeight={180}
                            />

                        </Grid>

                        <Grid item xs={6} className="panel_sponsor logo_images">
                            <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor Banner')}
                                <span className="image_text_description">{t('upload_dimensions', {text: '720px * 392px'})}</span>
                            </Typography>
                            <FileUploadComponent id="2" notautoprocess={true} getImage={getImageWideBanner} extra={{action:constants.LocalUrl + '/data/addSponsorTopwidebanner '}} fullwidth
                                                 accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                 maxWidth={720} maxHeight={392}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}} fullwidth>
                                <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px",padding:"0"}} gutterBottom>
                                    {t('Video')}
                                </Typography>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    {/*<InputLabel id="demo-simple-select-outlined-label">Video Recording</InputLabel>*/}
                                    <Select
                                        style={{marginRight:"15px",width:"140px"}}
                                        labelId="demo-simple-select-outlined-label"
                                        IconComponent={ExpandMoreIcon}
                                        id="grouped-native-select"
                                        value={formData.type_video}
                                        onChange={handleChange}
                                        name="type_video"
                                    >
                                        {/*<MenuItem value="">*/}
                                        {/*    <em>None</em>*/}
                                        {/*</MenuItem>*/}
                                        {video_types.map((row) => (
                                            <MenuItem value={row.id}>{row.title}</MenuItem>
                                        ))}

                                    </Select>
                                    <TextField id="outlined-basic" label="" variant="outlined"  onChange={onInputchange} name='video' fullWidth/>
                                </div>
                            </FormControl>
                        </Grid>





                        <Grid item xs={6}>
                            <CTextField  handler={onInputchange} name='website' label={t('Website')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField  handler={onInputchange} name='email' label={t('Email')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                                {t('Executives')}
                            </Typography>
                            <Divider light />
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteField
                                placeholder={t("Choose_an_executive")}
                                model={executives}
                                data={formData.executives}
                                onChange={onExecutivesChange}
                                handleItemsOrder={handleExecutivesOrder}
                            />
                            {/*<Paper className="draggable_autocomplete_wrapper" elevation={0} style={{*/}
                            {/*    padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>*/}
                            {/*    <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 0 17px 0"}} gutterBottom>*/}
                            {/*        /!*{t('Company_representatives')}*!/*/}
                            {/*    </Typography>*/}
                            {/*    <div id="autocomplete_selector">*/}
                            {/*        <Autocomplete*/}
                            {/*            multiple*/}
                            {/*            popupIcon={<ExpandMoreIcon />}*/}
                            {/*            id="checkboxes-tags-demo"*/}
                            {/*            options={executives}*/}
                            {/*            disableCloseOnSelect*/}
                            {/*            onChange={onExecutivesChange}*/}
                            {/*            // defaultValue={return_options_executives(sponsor.executives)}*/}
                            {/*            getOptionLabel={(option) => option.title}*/}
                            {/*            renderOption={(option, { selected }) => (*/}
                            {/*                <React.Fragment>*/}
                            {/*                    <Checkbox*/}
                            {/*                        icon={icon}*/}
                            {/*                        checkedIcon={checkedIcon}*/}
                            {/*                        style={{ marginRight: 8 }}*/}
                            {/*                        checked={selected}*/}
                            {/*                        color="#000"*/}
                            {/*                    />*/}
                            {/*                    {option.title}*/}
                            {/*                </React.Fragment>*/}
                            {/*            )}*/}
                            {/*            style={{ }}*/}
                            {/*            renderInput={(params) => (*/}
                            {/*                <TextField {...params} variant="outlined" label=""  name="executivess" placeholder={t("Choose_an_executive")} fullwidth/>*/}
                            {/*            )}*/}
                            {/*        />*/}
                            {/*        {formData.addExecutives && Object.keys(formData.addExecutives).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}*/}
                            {/*        <DraggableSimplestList  data={formData.addExecutives && Object.keys(formData.addExecutives).length > 0?formData.addExecutives:''} save={saveSponsorsSpeakersOrder}/>*/}
                            {/*    </div>*/}
                            {/*</Paper>*/}
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                            <br/>
                            <Grid item xs={12}>
                                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                                    {t("Settings Social links")}:
                                </Typography>
                                <Divider />
                                <br/>
                            </Grid>
                            {/*<Typography variant="h5" style={{textAlign:'left'}} gutterBottom>*/}
                            {/*    {t("Settings Social links")}:*/}
                            {/*</Typography>*/}
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Facebook")} name="Facebook" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CTextField handler={onInputchangeSocials} label={t("Sponsor Social LinkedIn")} name="LinkedIn" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Youtube")} name="Youtube" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Twitter")} name="Twitter" />
                                </Grid>
                            </Grid>
                        {/*</Grid>*/}
                        {/*<Grid item xs={12}>*/}

                        {/*    <Paper style={{*/}
                        {/*        padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>*/}

                        {/*        <Autocomplete*/}
                        {/*            multiple*/}
                        {/*            id="checkboxes-tags-demo"*/}
                        {/*            options={executives}*/}
                        {/*            disableCloseOnSelect*/}
                        {/*            onChange={onExecutivesChange}*/}
                        {/*            // defaultValue={return_options_executives(sponsor.executives)}*/}
                        {/*            getOptionLabel={(option) => option.title}*/}
                        {/*            renderOption={(option, { selected }) => (*/}
                        {/*                <React.Fragment>*/}
                        {/*                    <Checkbox*/}
                        {/*                        icon={icon}*/}
                        {/*                        checkedIcon={checkedIcon}*/}
                        {/*                        style={{ marginRight: 8 }}*/}
                        {/*                        checked={selected}*/}
                        {/*                    />*/}
                        {/*                    {option.title}*/}
                        {/*                </React.Fragment>*/}
                        {/*            )}*/}
                        {/*            style={{ width: '100%' }}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField {...params} variant="outlined" label="Executives"  name="executivess" placeholder="Choose an executive..." fullwidth/>*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*    </Paper>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12}>*/}
                        {/*    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>*/}
                        {/*        Files*/}
                        {/*    </Typography>*/}
                        {/*    <Divider light />*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12}>*/}
                        {/*    <FileUploader filePath={"sponsors_files"} sponsor_id={sponsor.id}/>*/}
                        {/*</Grid>*/}
                        <PanelBottomBar close={props.triggerdrawer(false)} submit={onSubmitForm} />

                        {/*<Grid id="panel_utilities_element" className="panel_utilities_element" item xs={12}>*/}
                        {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                        {/*    <div className="right_part">*/}
                        {/*    <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>
                </React.Fragment>
            </div>
        </div>

    );

}

