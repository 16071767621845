import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {Button, Container} from "semantic-ui-react";
import PageProvider from "../PageContext";
import Loader from "../../common/Loader";
// import MainBannerWidget from "./MainBannerWidget/MainBannerWidget";
import DateTimer from "./MainBannerWidget/DateTimer";
import * as constants from "../../Panel/common/Constants";
import {useTranslation} from "react-i18next";
import {ThemeProvider, createTheme} from '@material-ui/core/styles';

export default function HomepageHeading(){

    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);

    if(Object.keys(settings).length === 0){
        return <Loader/>
    }

    return (

        <Container style={{backgroundImage:"url('" + settings.lobby_bg +"')",width:'unset',justifyContent:'center',alignItems:'flex-end',display:'flex',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundColor:"#e7e8ea"}}>
            <div className="container main_banner_widget" style={{maxWidth: '100%'}}>
                <div className="row">
                    <div className="col" style={{padding: 0}}>
                        <div className="main_banner_widget_wrapper" style={{height: '400px', minHeight: '40vh', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                            <div style={{display: 'flex', flexDirection: 'column', position: 'relative', minHeight: '40vh', textAlign: 'center', marginLeft: '10%', height: 'inherit', width: '30%', backgroundColor: 'rgba(81, 81, 81, 0.66)'}}>
                                <div style={{marginTop: '40px', color: 'white'}}>
                                    {settings.date_mainbanner == 1?<h2 style={{letterSpacing:"-0.05em",fontWeight:"bold"}}>{settings.EVENT_DATE_STRING}</h2>:""}
                                    {settings.event_name_mainbanner == 1?<h1 style={{letterSpacing:"-0.05em",fontSize:"45px",fontWeight:"bold",marginBottom:"44px"}}>{settings.EVENT_NAME}</h1>:""}
                                    <hr style={{marginRight: 'auto', marginLeft: 'auto', width: '80%', height: '2px', color: 'white',backgroundColor:'var(--divider-color)'}} />
                                    {settings.countdown_mainbanner == 1?<div><DateTimer/></div>:""}
                                </div>
                                <div style={{marginTop: 'auto', marginBottom: '20px'}}>

                                    {settings.watch_mainbanner == 1?<NavLink as="button" className="ui huge primary button" to={constants.LocalUrl + "/stage"}>
                                        {t('Watch')}
                                    </NavLink>:<></>}
                                    {/*</Button>*/}
                                    {settings.register_mainbanner == 1 && temp_user_id==0?<Button color="primary" variant="contained" component="span" href={constants.LocalUrl + "/app/register"} primary size='huge' style={{marginBottom: '30px'}}>
                                        {t('Register')}
                                    </Button>:<></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>

    )
}
