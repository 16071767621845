import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import * as constants from "../Panel/common/Constants";
import Button from "@material-ui/core/Button";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import VideoWindow from "./LiveonChat/VideoWindow";
import PageProvider from "../Page/PageContext";
import {useTranslation} from "react-i18next";


let videoCallInterval = null;
let videoRingInterval = null;

export default function VideoCallNotifier(props) {
    // const { children, value, index, ...other } = props;
    const {t, i18n} = useTranslation();
    const {putVideoCall, setPutVideoCall,setEventManagerState} = useContext(PageProvider);
    const [listens, setListens] = useState(false);
    const [notificationData, setNotificationData] = useState(false);


    // const interval = setInterval(() => {
    //     console.log('This will run every second!');
    // }, 1000);
    // return () => clearInterval(interval);

    useEffect(() => {
        if (!listens) {
            window.channel_user_notifications.listen('.app.user.notify-video-call', (data) => {
                // console.info('notify-video-call');
                // console.info(data);
                console.log('notificationData', data, notificationData, videoCallInterval)
                if (data.type == 'start' && data != notificationData) {
                    setNotificationData(data);
                    //  do stuff for the caller only
                    if (data.from_user.id == window.temp_user_id && !videoCallInterval) {
                        window.addEventListener("beforeunload",  (event) => {
                            unloadFunction(data);
                        })
                        videoCallInterval = setInterval(() => {
                            // console.log('sending notification every second!');
                            axios.post(constants.LocalUrl + '/data/video_call_notify', {from: data.from_user.id, to: data.to_user.id, type: 'start'})
                        }, 2000);
                        setTimeout(() => {
                            setNotificationData(false);
                            clearInterval(videoCallInterval); videoCallInterval=null;
                            clearInterval(videoRingInterval); videoRingInterval=null;
                            unloadFunction(data);
                        }, 21000);
                    }
                    //  do stuff for the receiver only
                    else if (data.to_user.id == window.temp_user_id && !videoRingInterval) {
                        videoRingInterval = setInterval(() => {
                            ring();
                        }, 2000);
                    }
                }
                else if (data.type == 'stop') {
                    setNotificationData(false);
                    clearInterval(videoCallInterval); videoCallInterval=null;
                    clearInterval(videoRingInterval); videoRingInterval=null;
                }
                else if (data.type == 'answer') {
                    setEventManagerState({activate: true})
                    clearInterval(videoCallInterval); videoCallInterval=null;
                    clearInterval(videoRingInterval); videoRingInterval=null;
                    //  do stuff for the caller only
                    if (data.from_user.id == window.temp_user_id) {
                        setPutVideoCall({activate: true, uid: data.to_user.id})
                    }
                    //  do stuff for the receiver only
                    else if (data.to_user.id == window.temp_user_id) {
                        // axios.post(constants.LocalUrl + '/data/track_video_call_notify', {from: data.from_user.id, to: data.to_user.id, type: 'answer'});
                    }
                    setNotificationData(data);
                }
            });
            setListens(true);
        }
    }, [])

    const unloadFunction = (notificationData) => {
        if (notificationData) {
            // axios.post(constants.LocalUrl + '/data/video_call_notify', {from: notificationData.from_user.id, to: notificationData.to_user.id, type: 'stop'})
            jQuery.ajax({
                method: 'GET',
                url: "/data/video_call_notify",
                data: {from: notificationData.from_user.id, to: notificationData.to_user.id, type: 'stop'},
                cache: false,
                async: false
            });
        }
        return null;
    }

    const denyCall = () => {
        unloadFunction(notificationData);
    }

    const acceptCall = () => {
        axios.post(constants.LocalUrl + '/data/video_call_notify', {from: notificationData.from_user.id, to: notificationData.to_user.id, type: 'answer'}).then(

        )
        setEventManagerState({activate: true})

    }

    const ring = () => {
        if (document.getElementById('VideoCallNotification') != null) {
            document.getElementById('VideoCallNotification').play()
        }
    }

    const notify = (content) => content;

    const notificationContent = () => {

        if (notificationData) {
            if (notificationData.type == 'start') {
                if (notificationData.from_user.id == window.temp_user_id) {
                    return notify(
                        <div className="callCompoent top-right">
                            <div className="caller">
                                <img style={{borderRadius: "50%", marginRight: "20px",width:"100px",height:"100px"}} src={notificationData.to_user.user_img}
                                     alt=""/> {t('You are calling')} {notificationData.to_user.name}
                            </div>
                            <div className="callee_buttons">
                                <Button onClick={()=>denyCall()} className="hangup_button"><CallEndIcon/></Button>
                            </div>
                        </div>
                    );
                }
                else if (notificationData.to_user.id == window.temp_user_id) {
                    return notify(
                        <div className="callCompoent top-right pulse">
                            <div className="caller">
                                <img style={{borderRadius: "50%", marginRight: "20px",width:"100px",height:"100px"}} src={notificationData.from_user.user_img}
                                     alt=""/>
                                <span>{notificationData.from_user.name} {t('is calling you')}</span>
                            </div>
                            <div className="callee_buttons">
                                <Button onClick={()=>acceptCall()} className="answer_button"><CallIcon/></Button>
                                <Button onClick={()=>denyCall()} className="hangup_button"><CallEndIcon/></Button>
                            </div>
                        </div>
                    )
                }
            }
            else if (notificationData.type == 'answer') {
                return <VideoWindow></VideoWindow>
            }
        }
        else return <>
        </>;
    }

    return notificationContent();
}