import React, {useContext, useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import {useTranslation} from "react-i18next";
import PageProvider from "../../Page/PageContext";
import {LangFlags} from "./LangFlags";

var menu_icons = {
    language: "/images/language.svg",
};

export default function LanguageChangerForPanel(props) {

    const {t, i18n} = useTranslation();
    const {settings, setReloadData} = useContext(PageProvider);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const load = async () => {
        }
        load();
        setSelected(settings.locale);
    }, [settings]);

    useEffect(() => {
        props.onChange(selected);
    }, [selected]);

    return <div className="language_change_component">
        {/*{console.log(settings,"edo")}*/}
        {settings.locale && settings.languages && Object.keys(settings.languages).length > 1 ?
            <>
            {props.description == "no"?null:t("choose_language") + ":" }&nbsp;
                {Object.keys(settings.languages).length > 1 ? <div className="submenu">
                    {Object.keys(settings.languages).map((lang) =>
                        <span className={selected == lang ? 'active' : ''} onClick={() => setSelected(lang)}>
                            <img src={LangFlags[lang]} alt=""/>
                        </span>
                    )}
                </div> : null}
            </> : null }
    </div>;

}

