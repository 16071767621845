import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../common/Constants";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from "react-i18next";
import Loader from "../../../common/Loader";


export default function LiveQuestionsAdmin() {

    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState(null);
    const [listensQuestions, setListensQuestions] = useState(null);
    const {t, i18n} = useTranslation();

    const useStyles = makeStyles((theme) => ({
        root: {
            minWidth: 275,
            boxShadow: "none"
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        paper: {
            padding: '6px 16px',
        },
        secondaryTail: {
            backgroundColor: theme.palette.secondary.main,
        },
    }));
    const classes = useStyles();

    const loadPost = async () => {
        const response = await axios.post(constants.LocalUrl + "/data/get_questions_for_panel");
        setQuestions(response.data);
    }

    useEffect(() => {
        setLoading(true);
        loadPost();
        if (!listensQuestions) {
            window.channel_stage.listen('.stage.reload_questions_for_panel', (data) => {
                console.log('listened .stage.reload_questions_for_panel');
                setQuestions(data.questions);
            });
            setListensQuestions(true);
        }
        setLoading(false);
    }, []);

    if (loading === true || !questions) {
        return <Loader/>
    }

    const handleChangeStatus = (event) => {
        // let active = (activeSchedule == event.target.name) ? null : event.target.name;
        // setActiveSchedule(active);
        let index = event.target.getAttribute('data-index');
        axios.post(constants.LocalUrl + "/data/toggleQuestionStatus", {question: event.target.name}).then((response) => {
            loadPost()
        });
    };


    return (
        <Card className={classes.root}>
            {/*<CardContent>*/}

                {
                    questions.length > 0
                        ? questions.map((question, index) => (<>
                            <Timeline align="alternate" style={{padding: 0}}>
                                {
                                    index % 2 == 0
                                        //  Left
                                        ? <TimelineItem>
                                            <TimelineOppositeContent style={{flex: 0}}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {question.sent_at}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                {/*<TimelineDot>*/}
                                                <FormControlLabel
                                                    style={{margin: '0'}}
                                                    control={<Switch checked={question.status == 1}
                                                                     onChange={handleChangeStatus}
                                                                     name={"question" + question.id}
                                                                     inputProps={{'data-index': index}}/>}
                                                    label=""
                                                />
                                                {/*</TimelineDot>*/}
                                                <TimelineConnector/>
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Typography variant="h6" component="h1">
                                                        {question.from_user.name}
                                                    </Typography>
                                                    <Typography>{question.message}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                        //  Right
                                        : <TimelineItem>
                                            <TimelineOppositeContent style={{flex: 0}}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {question.sent_at}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                {/*<TimelineDot color="primary">*/}
                                                <FormControlLabel
                                                    style={{margin: '0'}}
                                                    control={<Switch checked={question.status == 1}
                                                                     onChange={handleChangeStatus}
                                                                     name={"question" + question.id}
                                                                     inputProps={{'data-index': index}}/>}
                                                    label=""
                                                />
                                                {/*</TimelineDot>*/}
                                                <TimelineConnector/>
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Typography variant="h6" component="h1">
                                                        {question.from_user.name}
                                                    </Typography>
                                                    <Typography>{question.message}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                }
                            </Timeline>
                        </>))
                        : t('No questions yet')
                }

                {/*<Typography className={classes.title} color="textSecondary" gutterBottom>*/}
                {/*    LIVE QUESTIONS ADMINISTRATION*/}
                {/*</Typography>*/}

                {/*<List component="nav" style={{width: '100%', maxWidth: "unset", borderRadius: '23px'}} fullwidth>
                    {questions && questions.map((question, index) => (<>

                        <Grid container xs={12}>
                            <Grid item xs={10}>
                                <FormLabel style={{marginBottom:'0'}} component="legend">
                                    <p>{question.from_user.name}</p>
                                    <p>{question.message}</p>
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <p>{question.sent_at}</p>
                                <FormGroup>
                                    <FormControlLabel
                                        style={{margin:'0'}}
                                        control={<Switch checked={question.status == 1} onChange={handleChangeStatus} name={"question"+question.id} inputProps={{ 'data-index': index }} />}
                                        label=""
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </>))}
                </List>*/}

            {/*</CardContent>*/}
            {/*<CardActions>*/}
            {/*    <Button size="small">Learn More</Button>*/}
            {/*</CardActions>*/}
        </Card>
    );

}

