import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import ReactDataGrid from "@inovua/reactdatagrid-community";



//  props.batch
export default function ViewSentLog(props) {

    const {t, i18n} = useTranslation();

    const gridStyle = { minHeight: 283, marginTop: 10, height:"100%" };
    const defaultPagination = 5;

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    useEffect(() => {
    }, []);

    if (!props.batch) {
        return <></>
    }

    console.info('props.batch');
    console.info(props.batch);
    return (
        <div style={{width: '100%'}}>
            {/*<Grid container spacing={3} style={{padding: '0px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false, null)} className={close_button.root} style={{position: 'absolute', right: '9px', top: '9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Typography aligncenter variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('BATCH SEND LOG')}
            </Typography>
            <Typography aligncenter variant="h5" style={{textAlign: 'center'}} gutterBottom>
                {t('Using_campaign')}: {props.campaing}
                <br/>
                {t('Using_send_list')}: {props.batch.title}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>

            <div className="view_sent_log" style={{ justifyContent: 'center'}}>
                <Grid container direction="column" spacing={0} style={{padding: '0px'}} xs={12}>
                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"35px"}} className="main_settings_section_title" gutterBottom>
                            {t('Existing Users log')}
                        </Typography>
                        <Divider />
                    </Grid>
                    {/*<Typography alignLeft variant="h6" gutterBottom style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}}>*/}
                    {/*    {t('Existing Users')}*/}
                    {/*</Typography>*/}
                    <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                    {
                        props.batch.users && props.batch.users.length > 0
                            ? <ReactDataGrid
                                theme='green-light'
                                // height
                                idProperty="email"
                                style={gridStyle}
                                columns={[
                                    { name: 'email', defaultFlex: 3, header: 'Email' },
                                    { name: 'title', defaultFlex: 2, header: 'Name' },
                                ]}
                                pagination
                                dataSource={props.batch.users}
                                defaultLimit={defaultPagination}
                                enableKeyboardNavigation={false}
                            />
                            : <Grid item xs={12}>{t("list is empty")}</Grid>
                    }
                    </div>
                    </Grid>
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"35px"}} className="main_settings_section_title" gutterBottom>
                        {t('Manually inserted log')}
                    </Typography>
                    <Divider />
                </Grid>
                    {/*<Typography alignLeft variant="h6" gutterBottom style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}}>*/}
                    {/*    {t('Manually inserted')}*/}
                    {/*</Typography>*/}
                <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                    {
                        props.batch.item && props.batch.item.length > 0
                            ? <ReactDataGrid
                                theme='green-light'
                                idProperty="email"
                                style={gridStyle}
                                columns={[
                                    { name: 'email', defaultFlex: 3, header: 'Email' },
                                ]}
                                pagination
                                dataSource={props.batch.item.map((item)=> { return {email: item}; })}
                                defaultLimit={defaultPagination}
                                enableKeyboardNavigation={false}
                            />
                            : <Grid item xs={12}>{t("list is empty")}</Grid>
                    }
                    </div>
                </Grid>
                    {/*<Typography alignLeft variant="h6" gutterBottom style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}}>*/}
                    {/*    {t('Uploaded with excel')}*/}
                    {/*</Typography>*/}
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"35px"}} className="main_settings_section_title" gutterBottom>
                        {t('Uploaded with excel')}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                        {
                        props.batch.xlsxUsers && props.batch.xlsxUsers.length > 0
                            ? <ReactDataGrid
                                theme='green-light'
                                height
                                idProperty="email"
                                style={gridStyle}
                                columns={[
                                    { name: 'email', defaultFlex: 3, header: 'Email' },
                                    { name: 'name', defaultFlex: 2, header: 'Name' },
                                ]}
                                pagination
                                dataSource={props.batch.xlsxUsers}
                                defaultLimit={defaultPagination}
                                enableKeyboardNavigation={false}
                            />
                            : <Grid item xs={12}>{t("list is empty")}</Grid>
                        }
                    </div>
                </Grid>

                    {/*<Typography alignLeft variant="h6" gutterBottom style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}}>*/}
                    {/*    {t('Final list of unique emails')}*/}
                    {/*</Typography>*/}
                <Grid item xs={12}>
                    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"35px"}} className="main_settings_section_title" gutterBottom>
                        {t('Final list of unique emails')}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                        {
                            props.batch.unique_emails && Object.values(props.batch.unique_emails).length > 0
                                ? <ReactDataGrid
                                    theme='green-light'
                                    height
                                    idProperty="email"
                                    style={gridStyle}
                                    columns={[
                                        { name: 'email', defaultFlex: 3, header: 'Email' },
                                        { name: 'fullname', defaultFlex: 2, header: 'Name' },
                                    ]}
                                    pagination
                                    dataSource={Object.values(props.batch.unique_emails)}
                                    defaultLimit={defaultPagination}
                                    enableKeyboardNavigation={false}
                                />
                                : <Grid item xs={12}>{t("list is empty")}</Grid>
                        }
                    </div>
                </Grid>

                    <Grid className="panel_utilities_element" item xs={12}>
                        <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false, null)} className={close_button.root}>{t("go_back")}</Button>
                    </Grid>

                </Grid>
            </div>
        </div>
    )

}
